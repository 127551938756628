import { EXPO_PUBLIC_SALEOR_CHANNEL } from "@env";

export const DEFAULT_LOCALE = 'en-US'

export const LAYOUT_MAX_WIDTH = 1124

export const CHECKOUT_TOKEN = "checkoutToken"

export const REFRESH_TOKEN = "rereshToken"

export const CSRF_TOKEN = "csrfToken"

export const LOGIN_TOKEN = "loginToken"

export const DEFAULT_CHANNEL = EXPO_PUBLIC_SALEOR_CHANNEL || 'default-channel'

export const STRIPE_GATEWAY = "app.saleor.stripe";

export const SCREEN_MAPS = {
  gallery: "gallery",
  artist_list: "artist_list",
  artist: "artist",
  login_options: "login_options",
  sign_up: "sign_up",
  email_login: "email_login",
  profile: "profile",
  product_detail: "product_detail",
  checkout: "checkout",
  payment: "payment",
  order: "order",
  order_list: "order_list",
  account_confirm: 'account_confirm',
  address_edit: 'address_edit',
  personal_edit: 'personal_edit',
  artstory: 'artstory',
  reset_password: 'reset_password',
  contact_us: 'contact_us',
}