import { Dialog, } from "@rneui/themed"
import FlexLayout from "../Flex"
import { StyleSheet } from "react-native"
import OperaButton from "../Button"
import JostText from "../Text/JostText"

interface NotificationDialogProps {
  title?: string
  visible: boolean
  onBackdropPress: () => void
  onCancel: () => void
  content: string
  cancelText?: string
  cancelBtnBgColor?: string
}
const NotificationDialog: React.FC<NotificationDialogProps> = ({
  title = "Notification",
  visible,
  onBackdropPress,
  content,
  onCancel,
  cancelText = "I got it"
}) => {
  return (
    <Dialog
      isVisible={visible}
      onBackdropPress={onBackdropPress}
      overlayStyle={{ maxWidth: 320 }}
    >
      {title && <FlexLayout style={styles.titleBox}>
        <JostText style={{ fontSize: 20, fontWeight: '500' }}>{title}</JostText>
      </FlexLayout>}
      <FlexLayout style={{
        ...styles.contentBox,
      }}>
        <JostText>{content}</JostText>
      </FlexLayout>
      <FlexLayout style={styles.btnsBox} direction="row" justify="center" items="end">
        <OperaButton
          title={cancelText}
          containerStyle={{ flex: 1, width: 'auto' }}
          buttonStyle={{ margin: 0 }}
          onPress={onCancel}
        />
      </FlexLayout>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  titleBox: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginLeft: -20,
    marginRight: -20,
    paddingBottom: 10
  },
  contentBox: {
    minHeight: 130,
  },
  btnsBox: {
    height: 50,
  }
})

export default NotificationDialog
