import Title from "@/components/Title"
import ViewPadding from "@/components/ViewPadding"
import { DEFAULT_LOCALE } from "@/constants"
import useCheckoutToken from "@/hooks/store/useCheckoutToken"
import useCheckoutEmailUpdateMutation from "@/saleor/api/useCheckoutEmailUpdateMutation"
import { localeToEnum } from "@/utils"
import { useCallback, useEffect, useState } from "react"
import { View, Text, StyleSheet } from "react-native"
import { Input } from '@rneui/themed'
import OperaButton from "../../../components/Button"
import useUserInfo from "@/hooks/store/useUserInfo"
import JostText from "@/components/Text/JostText"

interface EmailViewProps {
}
const EmailView: React.FC<EmailViewProps> = ({
}) => {
  const { user } = useUserInfo()
  const { checkout } = useCheckoutToken()
  const [email, setEmail] = useState(checkout?.email || '');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [modifyEmail, setModifyEmail] = useState(!checkout?.email);
  const [checkoutEmailUpdate] = useCheckoutEmailUpdateMutation()
  const userAccountEmail = user?.email;

  const handleInputChange = useCallback((value: string) => {
    setEmail(value)
  }, [])

  useEffect(() => {
    setEmail(checkout?.email || '')
    setModifyEmail(!checkout?.email)
  }, [checkout?.email])

  useEffect(() => {
    userAccountEmail && setEmail(userAccountEmail)
  }, [userAccountEmail])

  const handleSubmit = useCallback(async () => {
    try {
      if (!/^\S+@\S+$/i.test(email)) {
        setErrorMessage('Invalid email format')
        return
      }
      setLoading(true)
      const result = await checkoutEmailUpdate({
        variables: {
          email,
          token: checkout?.token,
          locale: localeToEnum(DEFAULT_LOCALE)
        }
      })
      const mutationErrors = result.data?.checkoutEmailUpdate?.errors || [];
      if (mutationErrors.length > 0) {
        setErrorMessage(mutationErrors[0].message || '')
        return
      }
      setModifyEmail(false);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [email, checkout])

  if (!checkout) return null

  return (
    <ViewPadding style={styles.container}>
      <Title size={16} label="Email Address" />
      {!modifyEmail ? (
        <View>
          <JostText style={styles.emailString}>{checkout?.email}</JostText>
          <OperaButton
            title="Change"
            style={styles.operaBtn}
            onPress={() => setModifyEmail(true)}
            containerStyle={{ marginVertical: 10 }}
          />
        </View>
      ) : (
        <View>
          <Input
            placeholder="Please enter email"
            autoComplete="email"
            spellCheck={false}
            value={email}
            onChangeText={handleInputChange}
            errorStyle={{ color: 'red' }}
            errorMessage={errorMessage}
            inputContainerStyle={styles.emailInputContainerStyle}
            style={styles.email}
          />
          <OperaButton
            title="Save"
            onPress={handleSubmit}
            containerStyle={{ marginVertical: 10 }}
            loading={loading}
          />
        </View>
      )}
    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {},
  emailString: {
    marginVertical: 15,
    fontSize: 14
  },
  email: {
    fontSize: 14,
    paddingHorizontal: 10,
    backgroundColor: 'white'
  },
  emailInputContainerStyle: {
    marginVertical: 10,
    fontSize: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 6,
    overflow: 'hidden'
  },
  operaBtn: {
    marginBottom: 15
  }
})

export default EmailView