import { gql } from '@apollo/client';

const ADDRESS_FRAGMENT = gql`
  fragment Address on Address {
    id
    firstName
    lastName
    companyName
    streetAddress1
    streetAddress2
    city
    postalCode
    country {
      code
      country
    }
    countryArea
    phone
    isDefaultBillingAddress
    isDefaultShippingAddress
  }
`;

const USER_FRAGMENT = gql`
  fragment User on User {
    id
    email
    avatar {
      url
    }
    firstName
    lastName
    isStaff
    defaultShippingAddress {
      ...Address
    }
    defaultBillingAddress {
      ...Address
    }
    addresses {
      ...Address
    }
  }
  ${ADDRESS_FRAGMENT}
`;

const ACCOUNT_ERROR_FRAGMENT = gql`
  fragment AccountError on AccountError {
    code
    field
    message
    __typename
  }
`;

const PRODUCT_PRICING_FRAGMENT = gql`
  fragment ProductPricingInfo on ProductPricingInfo {
    priceRange{
      start {
        gross{
          currency
          amount
        }
      }
    }
    discount{
      currency
      gross{
        currency
        amount
      }
    }
  }
`

const ARTIST_FRAGMENT = gql`
  fragment Artist on Artist {
    id
    name
    gender
    address
    avatar {
      url
    }
    description
    story {
      introduce
    }
    nation
  }
`

const PAGEINFO_FRAGMENT = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`

const PRODUCT_ITEM_FRAGMENT = gql`
  fragment Product on Product {
    id
    name 
    thumbnail {
      url
    }
    description,
    metadata {
      key
      value
    }
    media{
      url
    }
    pricing {
      ...ProductPricingInfo
    }
    attributes {
      values {
        id
        name
        slug
        value
        plainText
      }
    }
    variants {
      id
      name
      sku
      quantityLimitPerCustomer
    }
    artist {
      name
    }
  }
  ${PRODUCT_PRICING_FRAGMENT}
`

export {
   ADDRESS_FRAGMENT,
   USER_FRAGMENT,
   ACCOUNT_ERROR_FRAGMENT,
   PRODUCT_ITEM_FRAGMENT,
   PRODUCT_PRICING_FRAGMENT,
   ARTIST_FRAGMENT,
   PAGEINFO_FRAGMENT
};