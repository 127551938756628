import { ICategoryItem } from '@/components/Categorys/CategoryItem';
import { create } from 'zustand'

interface IAppStore {
  categories: ICategoryItem[]
  updateCategories: (v?: ICategoryItem[]) => void

  currentCategory: string
  updateCurrentCategory: (v?: string) => void
}

const initialState: IAppStore = {
  categories: [],
  updateCategories: () => null,
  currentCategory: '',
  updateCurrentCategory: () => null
};


const useGallery = create<IAppStore>((set, get) => ({
  ...initialState,
  updateCategories: (v?: ICategoryItem[]) => set(() => ({
    categories: v ?? get().categories
  })),
  updateCurrentCategory: (v?: string) => set(() => ({
    currentCategory: v ?? get().currentCategory
  }))
}))

export default useGallery;