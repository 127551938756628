
import { SafeAreaView } from "react-native-safe-area-context";
import { View, ScrollView, StyleSheet } from "react-native";
import PageHeader from "@/components/PageHeader/indext";
import ArtistAvatars from './components/ArtistAvatars'
import ArtStorys from "./components/ArtStorys";
import { useTabletOrDesktop } from "@/hooks/useMedia";
import { LAYOUT_MAX_WIDTH } from "@/constants";
import useArtists from "@/saleor/api/useArtists";
import Loading from "@/components/Loading/Loading";
import useArtistPage from "@/hooks/store/useArtistPage";
import { useEffect } from "react";

const ArtistsScreen: React.FC<any> = () => {
  const { loading, data } = useArtists()
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  const { updateArtistAvatars } = useArtistPage()
  const artists = (data?.artists?.edges ?? [])


  useEffect(() => {
    if (artists.length) {
      const list = artists.map((item: any) => ({ ...(item?.node || {}) }))
      updateArtistAvatars(list)
    }
  }, [artists])

  if (!ready) return null

  if (loading) {
    return (
      <Loading />
    )
  }

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={{
        ...(isTabletOrDesktop ? {
          maxWidth: LAYOUT_MAX_WIDTH - 100,
          width: '100%',
          marginHorizontal: 'auto',
          paddingBottom: 100
        } : {})
      }}
    >
      <SafeAreaView>
        {/** Header */}
        <PageHeader title="Artist" />

        {/** content */}
        <View style={{
          ...styles.content,
        }}>
          <ArtistAvatars />
          <ArtStorys />
        </View>
      </SafeAreaView>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 10,
    backgroundColor: 'rgb(242, 240, 229)',
  },
  content: {
    marginTop: 20,
  }
})

export default ArtistsScreen
