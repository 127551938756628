import FlexLayout from '@/components/Flex'
import React from 'react'
import { AntDesign } from '@expo/vector-icons';
import { Image, StyleSheet, Text, TouchableOpacity, TouchableOpacityProps, View } from 'react-native'
import { useDesktop, useMobile, useTablet } from '@/hooks/useMedia';
import { Art } from '@/types';
import useImageSize from '@/hooks/useImageSize';
import { responsiveWidth } from 'react-native-responsive-dimensions';
import JostText from '@/components/Text/JostText';
import Space from '@/components/Space';


interface ArtStoryItemProps {
  data: Art
  style?: Record<string, any>
  onPress?: TouchableOpacityProps['onPress']
}

function ArtStoryItem({
  data,
  onPress,
  style
}: ArtStoryItemProps) {
  const isMobile = useMobile()
  const isTablet = useTablet()
  const isDesktop = useDesktop()
  const { rate } = useImageSize(data?.avatar?.url)

  const mobileImageWidth = responsiveWidth(100) - 40

  const storyText = data?.story?.length ? data?.story?.[0]?.introduce : ""

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        ...styles.container,
        ...(style || {}),
        ...((isTablet || isDesktop) ? {
          width: isDesktop ? '31%' : isTablet ? '46%' : 'auto',
          marginRight: 20,
          marginBottom: 10
        } : {
          marginBottom: 35
        })
      }}
    >
      <FlexLayout style={styles.content} justify='start' items="stretch">
        {data?.avatar?.url && <Image style={isMobile && rate ? {
          width: mobileImageWidth,
          height: mobileImageWidth / rate
        } : styles.pcPoster} source={{ uri: data.avatar.url }} resizeMode='cover' />}
        <FlexLayout style={styles.infoBox} justify='start' items="stretch">
          <JostText>{data.name}</JostText>
          <Space size={12} />
          <JostText style={styles.title} numberOfLines={2} ellipsizeMode="tail">{data.description}</JostText>
          <FlexLayout style={styles.bottomBox} direction='row' justify='between' items="center">
            <View style={styles.descBox}>
              <JostText numberOfLines={3} ellipsizeMode="tail">{storyText}</JostText>
            </View>
            {storyText && <AntDesign name="right" size={24} color="black" />}
          </FlexLayout>
        </FlexLayout>
      </FlexLayout>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    backgroundColor: 'white',
    borderBottomRightRadius: 20
  },
  content: {},
  pcPoster: {
    width: '100%',
    height: 266,
  },
  infoBox: {
    paddingTop: 40,
    paddingHorizontal: 12,
    paddingBottom: 20
  },
  title: {
    fontSize: 20,
    fontWeight: 600
  },
  bottomBox: {
    marginTop: 12
  },
  descBox: {
    maxWidth: 260
  }
})

export default ArtStoryItem