import { useFonts }  from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';

export default function useFont() {
  const [fontsLoaded] = useFonts({
    JostRegular: require('../../assets/fonts/JostRegular.ttf'),
    JomolhariRegular: require('../../assets/fonts/Jomolhari_Regular.ttf'),
    InriaSansRegular: require('../../assets/fonts/InriaSansRegular.ttf'),
  })

  React.useEffect(() => {
    if (fontsLoaded) {
      SplashScreen.hideAsync();
    }
  }, [fontsLoaded])

  return fontsLoaded;
}
