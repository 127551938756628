
import { useMutation, gql } from '@apollo/client';


export const GQL = gql`
  mutation TransactionInitialize($checkoutId: ID!, $data: JSON) {
    transactionInitialize(
      id: $checkoutId
      paymentGateway: {id: "app.saleor.stripe", data: $data}
    ) {
      transaction {
        id
      }
      transactionEvent {
        id
      }
      data
      errors {
        field
        message
        code
      }
    }
  }
`;

export default function useCheckoutTransactionInitialize() {
  return useMutation(GQL)
}