import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, Observable } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { EXPO_PUBLIC_SALEOR_API_URL } from '@env';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import refreshToken from '@/utils/refreshToken';
import useAuth from '@/hooks/store/useAuth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LOGIN_TOKEN } from '@/constants';
import { useNavigation } from '@react-navigation/native';

function backToLoginPage() {
  const navigation: any = useNavigation();
  navigation.navigate('email_login');
}

const authStore = useAuth.getState();

const httpLink = createHttpLink({
  uri: EXPO_PUBLIC_SALEOR_API_URL,
});

const uploadLink = createUploadLink({
  uri: EXPO_PUBLIC_SALEOR_API_URL,
});

const AUTH_API_LIST = [
  'User',
  'Me',
  'Orders',
  'UploadAvatar',
  'AccountUpdate',
  'UpdateUserAddress',
  'CreateUserAddress',
  'OrderDetailsByToken',
]

function isAuthorizationRequired(operationName: string) {
  return AUTH_API_LIST.includes(operationName);
}

const authLink = setContext(async (request: any, {headers}) => {
  const token = await AsyncStorage.getItem(LOGIN_TOKEN)
  const shouldIncludeAuthorization = isAuthorizationRequired(request?.operationName);

  return {
    headers: {
      ...headers,
      authorization: shouldIncludeAuthorization && token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
  const isTokenExpired = (graphQLErrors || [])?.some((err: any) => err.message.includes('Signature has expired'))
  if (isTokenExpired) {
    return new Observable((observer) => {
      authStore.updateToken('')
      refreshToken()
        .then((res: any) => {
          const { token: newToken } = res?.data?.tokenRefresh || {}
          authStore.updateToken(newToken)
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: `Bearer ${newToken}`,
            },
          }));
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          console.log('error from apollo client', error)
          authStore.updateToken('')
          authStore.updateRefreshToken('')
          authStore.updateCsrfToken('')
          backToLoginPage()
          observer.error(error);
        })
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
  if (graphQLErrors) {
    console.log(`[GraphQL error]: ${graphQLErrors}`);
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

export const uploadFileClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, uploadLink]),
  cache: new InMemoryCache(),
});