import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { Art } from "@/types";
import JomolhariText from "../Text/JomolhariText";
import JostText from "../Text/JostText";
import { useNavigation } from "@react-navigation/native";


interface ArtInfoProps {
  avatarBoxStyle?: any
  info?: Art
}

const ArtInfo: React.FC<ArtInfoProps> = ({
  avatarBoxStyle,
  info
}) => {
  const navigation: any = useNavigation()

  if (!info) return null

  const navigateToArtistPage = () => {
    navigation.push('artstory', {
      artId: info.id
    })
  }

  return (
    <View style={styles.artistInfoBox}>
      <TouchableOpacity onPress={navigateToArtistPage} style={{
        ...styles.avatarBox,
        ...(avatarBoxStyle || {})
      }}>
        <Image style={styles.avatar} resizeMode='cover' source={{ uri: info?.avatar?.url }} />
      </TouchableOpacity>
      <View style={styles.infoWrapper}>
        <View style={styles.infoTopBox}>
          <TouchableOpacity onPress={navigateToArtistPage}>
            <JomolhariText style={{ fontSize: 20, color: '#200051' }}>{info?.name}</JomolhariText>
          </TouchableOpacity>
          <JostText style={{ fontSize: 12, color: '#877A99', marginVertical: 5 }}>from <JostText style={{ color: '#200051' }}>{info.address}</JostText></JostText>
        </View>
        {/* <View style={styles.infoBottomBox}>
          {[info.gender === "F" ? "Female" : "Male", info.nation].map((item) => <Tag style={{ marginRight: 8 }} key={item}>{item}</Tag>)}
        </View> */}
      </View>
    </View>
  )
}


const styles = StyleSheet.create({
  artistInfoBox: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  infoWrapper: {
    justifyContent: 'space-between'
  },
  avatarBox: {
    width: 70,
    height: 70,
    marginRight: 22,
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 5
  },
  avatar: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#ccc'
  },
  infoTopBox: {},
  infoBottomBox: {
    flexDirection: 'row',
  },
})

export default ArtInfo;