import React from 'react'
import { Image, StyleSheet, TouchableOpacity } from 'react-native'
interface AvatarProps {
  uri: string
  size?: number
}
function Avatar({ size = 24, uri }: AvatarProps) {
  return (
    <TouchableOpacity style={{
      ...styles.container,
      width: size,
      height: size
    }}>
      <Image source={{ uri }} style={{ width: size, height: size }} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    overflow: 'hidden'
  }
})

export default Avatar