import Title from "@/components/Title"
import ViewPadding from "@/components/ViewPadding"
import useCheckoutToken from "@/hooks/store/useCheckoutToken"
import { useUser } from "@/hooks/useUser"
import { useEffect, useState } from "react"
import { StyleSheet, View, Text } from 'react-native';
import OperaButton from "../../../../components/Button"
import AddressDisplay from "../AddressDisplay"
import SavedAddressSelectionList from "./SavedAddressSelectionList"
import AddressForm from "../AddressForm"
import useCheckoutBillingAddressUpdateMutation from "@/saleor/api/useCheckoutBillingAddressUpdateMutation"
import { AddressFormData } from "@/types"
import { localeToEnum, notNullable } from "@/utils"
import { DEFAULT_LOCALE } from "@/constants"
import useUserInfo from "@/hooks/store/useUserInfo"

const BillingAddressView = () => {
  const { user } = useUserInfo();
  const { checkout } = useCheckoutToken()
  const [editing, setEditing] = useState(!checkout?.billingAddress);
  const [checkoutBillingAddressUpdate] = useCheckoutBillingAddressUpdateMutation()

  useEffect(() => {
    setEditing(!checkout?.billingAddress)
  }, [checkout?.billingAddress])

  const updateMutation = async (formData: AddressFormData) => {
    const { data } = await checkoutBillingAddressUpdate({
      variables: {
        address: {
          ...formData,
        },
        token: checkout?.token,
        locale: localeToEnum(DEFAULT_LOCALE),
      },
    });
    return data?.checkoutBillingAddressUpdate?.errors.filter(notNullable) || [];
  };

  return (
    <ViewPadding style={styles.container}>
      <Title size={16} label="Billing Address" />
      {
        editing ? (
          <>
            {user?.addresses && <SavedAddressSelectionList
              addresses={user?.addresses}
              onSuccessUpdated={() => setEditing(false)}
              updateAddressMutation={updateMutation}
            />}
            <Title size={14} label="Enter address" />
            <AddressForm
              existingAddressData={checkout?.billingAddress || undefined}
              updateAddressMutation={updateMutation}
              onSuccessUpdated={() => setEditing(false)}
            />
          </>
        ) : (
          <View>
            {!!checkout?.billingAddress && <AddressDisplay address={checkout.billingAddress} />}
            <OperaButton
              title="Change"
              onPress={() => setEditing(true)}
              containerStyle={{ marginVertical: 10 }}
            />
          </View>
        )
      }
    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {},
})

export default BillingAddressView