import { DEFAULT_LOCALE } from "@/constants";
import { isWeb } from "./platform";
import { Alert } from "react-native";


export const formatAsMoney = (amount = 0, currency = "USD", locale = DEFAULT_LOCALE) => {
  amount = Math.trunc(amount);
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 0, 
    maximumFractionDigits: 0
  }).format(amount);
}

export type Media = {
  id: string
  type: string
  url: string
}

export const filterImageFromProductMedia = (media: Media[]) => {
  return media.filter(o => o.type === 'IMAGE')
}

export function extractTextFromBlocks(jsonString: string = "{}") {
  var data = JSON.parse(jsonString);

  var texts = (data?.blocks || []).map((block: any) =>  {
    return (block?.data?.text || '').replace(/<\/?[^>]+(>|$)/g, "");
  });

  return texts.join('');
}

export function getPriceFromProduct(product: any) {
  return product?.pricing?.priceRange?.start?.gross || {}
}

export const LOCALES = [
  {
    slug: "en-US",
    code: "EN_US",
    name: "American English",
  }
];

export const localeToEnum = (localeSlug: string): string => {
  const chosenLocale = LOCALES.find(({ slug }) => slug === localeSlug)?.code;
  if (chosenLocale) {
    return chosenLocale;
  }
  return LOCALES.find(({ slug }) => slug === DEFAULT_LOCALE)?.code || "EN_US";
};

export function notNullable<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function formatDateTimeToUS(created: string) {
  const date = new Date(created);
  
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
    timeZone: 'America/New_York'
  };
  
  return date.toLocaleString('en-US', options as any);
}

export interface Edge<T> {
  node: T;
}

export interface Connection<T> {
  edges: Array<Edge<T>> | undefined;
}

export function mapEdgesToItems<T>(data: Connection<T> | undefined | null): T[] {
  return data?.edges?.map(({ node }) => node) || [];
}

export function getJoinedYear(str: string) {
  const date = new Date(str);
  const year = date.getFullYear();
  
  return `Joined ${year}`;
}

export function getSimpleFullName(firstName: string, lastName: string) {
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();
  
  return `${firstInitial}${lastInitial}`;
}


export function base64ToBlob(base64Data: string, contentType: string): Blob {
  const byteCharacters = atob(base64Data);
  const byteArrays = [];
   for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
     const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
     const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
   return new Blob(byteArrays, { type: contentType });
}

export const toast = (message: string) => {
  isWeb ? window.alert(message) : Alert.alert(message)
}
