import React, { useLayoutEffect, useState, } from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import { useNavigation } from '@react-navigation/native';
import { FontAwesome } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LOGIN_TOKEN } from '@/constants';
import { isWeb } from '@/utils/platform';
import JostText from '../Text/JostText';

interface TabProps {
  data: { label: string, path: string }[]
}

function Tab({ data }: TabProps) {
  const navigation: any = useNavigation()
  const [currentTab, setCurrentTab] = useState(data[0].path)

  useLayoutEffect(() => {
    if (isWeb) {
      if (window.location.pathname.includes('gallery')) {
        setCurrentTab(data[0].path)
      } else if (window.location.pathname.includes('artist_list')) {
        setCurrentTab(data[1].path)
      }
    }
  }, [])

  return (
    <View style={styles.container}>
      <View style={styles.tabBox}>
        {data.map((tab) => (
          <TouchableOpacity
            key={tab.label}
            style={{
              ...styles.tabItem,
            }}
            onPress={() => {
              setCurrentTab(tab.path)
              navigation.navigate(tab.path)
            }}
          >
            <JostText style={{
              ...styles.tabItemLabel,
              ...(tab.path === currentTab ? styles.tabItemLabelActive : {})
            }}>{tab.label}</JostText>
            <View style={{
              ...styles.tabItemLine,
              ...(tab.path === currentTab ? styles.tabItemLineActive : {})
            }} />
          </TouchableOpacity>
        ))}
      </View>
      <View style={styles.profileContainer}>
        <FontAwesome onPress={async () => {
          const token = await AsyncStorage.getItem(LOGIN_TOKEN);
          navigation.navigate(token ? 'profile' : 'login_options')
        }} name="user" size={24} color="black" />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  profileContainer: {
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tabBox: {
    marginTop: 20,
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tabItem: {
    marginRight: 15,
    position: 'relative'
  },
  tabItemLabel: {
    fontSize: 25,
    color: 'rgba(0,0,0,0.6)',
    fontWeight: '600'
  },
  tabItemLabelActive: {
    color: 'black'
  },
  tabItemLine: {
    width: '60%',
    height: 2,
    backgroundColor: 'black',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 0,
    opacity: 0
  },
  tabItemLineActive: {
    opacity: 1
  }
})

export default Tab