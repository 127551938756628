import { useMutation, gql } from '@apollo/client';

export const ResetPasswordDocument = gql`
  mutation SetPassword($email: String!, $password: String!, $token: String!) {
    setPassword(email: $email, password: $password, token: $token) {
      token
      refreshToken
      csrfToken
      user {
        email
      }
      errors {
        message
        field
        code
      }
    }
  }
`;

export function useResetPasswordMutation() {
  return useMutation(
    ResetPasswordDocument
  );
}