import React from 'react'
import { StyleSheet, View } from 'react-native'
import JostText from '../Text/JostText';

interface TitleProps {
  label: string
  style?: any
  size?: number
}

function Title({ label, style, size = 30 }: TitleProps) {
  return (
    <View style={{
      ...styles.titleBox,
      ...(style || {})
    }}>
      <JostText style={{
        ...styles.titleLabel,
        fontSize: size
      }}>{label}</JostText>
    </View>
  )
}

const styles = StyleSheet.create({
  titleBox: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  titleLabel: {
    fontWeight: '900'
  }
})

export default Title