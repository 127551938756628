import React from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'

import FlexLayout from '../Flex'

export default function Loading() {
  return (
    <FlexLayout style={styles.box}>
      <ActivityIndicator color="#000" />
    </FlexLayout>
  )
}

const styles = StyleSheet.create({
  box: {
    marginTop: 50
  },
  text: {
    fontSize: 15
  }
})
