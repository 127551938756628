import ArtInfo from "@/components/ArtInfo"
import FlexLayout from "@/components/Flex"
import Space from "@/components/Space"
import JomolhariText from "@/components/Text/JomolhariText"
import JostText from "@/components/Text/JostText"
import useProductDetail from "@/hooks/store/useProductDetail"
import { StyleSheet, Text } from "react-native"


const ArtstView = () => {
  const { info } = useProductDetail()
  const story = info?.artist?.story?.length ? info.artist.story[0].introduce : ''

  if (!info) return null

  return (
    <FlexLayout justify="start" items="start">
      <ArtInfo info={info?.artist || {} as any} />
      <Space size={40} />
      <JostText style={styles.artistTitle}>On this piece</JostText>
      <Space size={36} />
      <JostText style={styles.story}>
        “ {story}”
      </JostText>

      <Space size={40} />
      <JostText style={styles.artistTitle}>About {info?.artist?.name || 'Artist'}</JostText>
      <Space size={36} />
      <JomolhariText style={styles.desc}>
        {info?.artist?.description || ''}
      </JomolhariText>
    </FlexLayout>
  )
}

const styles = StyleSheet.create({
  artistTitle: {
    color: '#877A99',
    fontSize: 20
  },
  desc: {
    color: '#453B55',
    fontSize: 16
  },
  story: {
    fontSize: 20,
    color: '#453B55'
  }
})

export default ArtstView