
import { SafeAreaView } from "react-native-safe-area-context";
import { ScrollView, StyleSheet, Text, TouchableOpacity } from "react-native";
import PageNavLayout from "@/components/PageNavLayout";
import Back from "@/components/Back";
import useOrderListQuery from "@/saleor/api/useOrderListQuery";
import FlexLayout from "@/components/Flex";
import { useUser } from "@/hooks/useUser";
import { formatAsMoney, formatDateTimeToUS, mapEdgesToItems } from "@/utils";
import ViewPadding from "@/components/ViewPadding";
import TextItem from "../order/TextItem";
import Title from "@/components/Title";
import Empty from "@/components/Empty";
import FallbackDesktopMobileLayout from "@/components/FallbackDesktopMobileLayout";
import JostText from "@/components/Text/JostText";

const OrderListScreen: React.FC<any> = ({ navigation }) => {
  const { authenticated } = useUser();
  const { data, loading, error, fetchMore } = useOrderListQuery({
    skip: !authenticated
  })

  const orders = mapEdgesToItems(data?.me?.orders);

  const onLoadMore = () => {
    return fetchMore({
      variables: {
        after: data?.me?.orders?.pageInfo.endCursor,
      },
    });
  };

  console.log({ orders, error })

  return (
    <FallbackDesktopMobileLayout>
      <ScrollView style={styles.container}>
        <SafeAreaView>
          <PageNavLayout>
            <Back navigation={navigation} />
          </PageNavLayout>
          <ViewPadding>
            <Title label="Mine Orders" size={24} style={{ marginBottom: 10 }} />
          </ViewPadding>
          <ScrollView>

            {loading && <FlexLayout style={{ marginTop: 50 }}>
              <JostText>Loading</JostText>
            </FlexLayout>}

            {!data && error && <>
              <FlexLayout style={{ marginTop: 100 }}>
                <JostText>You don't have the permission to view order list.</JostText>
              </FlexLayout>
            </>}

            {!error && !orders.length && <Empty description="No orders found" />}

            {orders.map((order: any) => (
              <ViewPadding key={order.id}>
                <TouchableOpacity onPress={() => navigation.navigate('order', {
                  orderToken: order.token
                })}>
                  <FlexLayout justify="start" items="stretch" style={styles.itemContainer}>
                    <TextItem label="Number" value={order.number} />
                    <TextItem label="Created" value={formatDateTimeToUS(order.created)} />
                    <TextItem label="Status" value={order.status} />
                    <TextItem label="Total" value={formatAsMoney(order.total.gross.amount)} />
                  </FlexLayout>
                </TouchableOpacity>
              </ViewPadding>
            ))}

          </ScrollView>

        </SafeAreaView>
      </ScrollView>
    </FallbackDesktopMobileLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  itemContainer: {
    backgroundColor: 'white',
    padding: 10,
    borderRadius: 5,
    overflow: 'hidden',
    marginBottom: 20
  }
})

export default OrderListScreen
