import { StyleSheet, View } from "react-native"

interface SpaceProps {
  direction?: 'v' | 'h'
  style?: any
  size: number
}

const Space: React.FC<SpaceProps> = ({
  direction = 'v',
  style,
  size = 10
}) => {
  return (
    <View style={{
      ...styles.container,
      ...(style || {}),
      ...(direction === 'v' ? { height: size } : { width: size })
    }} />
  )
}

const styles = StyleSheet.create({
  container: {}
})

export default Space