import { useQuery, gql } from '@apollo/client';
import { ARTIST_FRAGMENT } from '../fragments';

export const QUERY = gql`
  query Artist($id: ID!) {
    artist(id: $id) {
      ...Artist
    }
  }
  ${ARTIST_FRAGMENT}
`;

export default function useArtist(id: string) {
  return useQuery(QUERY, {
    variables: {
      id
    }
  });
}