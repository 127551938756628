import { StyleSheet, View, Modal, TouchableOpacity } from 'react-native';
import Avatar from '@/components/Avatar'
import ViewPadding from '@/components/ViewPadding'
import React, { useMemo, useRef, useState } from 'react'
import Swiper from 'react-native-web-swiper'
import FeatureRow from './FeatureRow'
import Title from '@/components/Title'
import useProductDetail from '@/hooks/store/useProductDetail'
import ImageViewer from 'react-native-image-zoom-viewer';
import { extractTextFromBlocks, filterImageFromProductMedia } from '@/utils'
import { useTabletOrDesktop } from '@/hooks/useMedia'
import { MaterialIcons } from '@expo/vector-icons';
import PosterItem from './PosterItem'
import FlexLayout from '@/components/Flex'
import JostText from '@/components/Text/JostText'
import Loading from '@/components/Loading/Loading'

function ProductDetail() {
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  const { info } = useProductDetail()
  const swiperRef = useRef<Swiper | null>(null)
  const [previewIndex, setPreviewIndex] = useState(0)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const images = useMemo(() => {
    return filterImageFromProductMedia(info?.media ?? [])
  }, [info?.media])

  const artInfo = info?.artist

  const attributes = info?.attributes || []

  const PrevComponent = () => <FlexLayout style={{ position: 'absolute', top: -150 }}>
    <MaterialIcons name="keyboard-arrow-left" size={50} color="#877A99" onPress={() => swiperRef.current?.goToPrev?.()} />
  </FlexLayout>

  const NextComponent = () => <FlexLayout style={{ position: 'absolute', top: -150 }}>
    <MaterialIcons name="keyboard-arrow-right" size={50} color="#877A99" onPress={() => swiperRef.current?.goToNext?.()} />
  </FlexLayout>


  if (!info || !ready) return (
    <Loading />
  )

  return (
    <View style={styles.container}>
      <ViewPadding>
        <JostText style={styles.title}>
          {info?.name || '-'}
        </JostText>
      </ViewPadding>

      {/** Artist info */}
      {artInfo?.avatar?.url && <ViewPadding style={styles.artistBox}>
        <Avatar uri={artInfo.avatar.url} />
        <JostText style={styles.artistName}>{artInfo.name}</JostText>
      </ViewPadding>}

      {/** Swiper Images */}
      <ViewPadding style={styles.swiperBox}>
        <Swiper
          ref={swiperRef}
          containerStyle={styles.swiper}
          controlsProps={{
            PrevComponent: PrevComponent as any,
            NextComponent: NextComponent as any,
            dotActiveStyle: {
              backgroundColor: '#817A56'
            }
          }}
        >
          {images.map((item, index) => (
            <PosterItem height={300} onPress={() => {
              setPreviewIndex(index)
              setShowPreviewModal(true)
            }} info={item} key={item.id} />
          ))}
        </Swiper>
      </ViewPadding>

      {/* features */}
      <ViewPadding style={styles.featuresWrapper}>
        {
          attributes.map((attribute: any) => (
            <FeatureRow key={attribute.attribute.id} label={attribute.attribute.name} value={attribute.values.length ? attribute.values[0].name : ''} />
          ))
        }
      </ViewPadding>

      {/** About */}
      {!isTabletOrDesktop && <ViewPadding>
        <Title style={styles.aboutTitle} label="About" />
        <JostText style={styles.aboutContent}>{extractTextFromBlocks(info?.description)}</JostText>
      </ViewPadding>}

      <Modal visible={showPreviewModal} transparent={true} style={{ position: 'relative' }}>
        <ImageViewer
          enableImageZoom
          enablePreload
          index={previewIndex}
          imageUrls={images.map((image) => ({
            url: image.url,
          }))}
        />
        <FlexLayout direction='row' style={styles.cancelPreview}>
          <TouchableOpacity onPress={() => setShowPreviewModal(false)}>
            <JostText style={{ color: 'white' }}>Cancel Preview</JostText>
          </TouchableOpacity>
        </FlexLayout>
      </Modal>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
  },
  title: {
    fontSize: 28,
    fontWeight: '900'
  },
  artistBox: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  artistName: {
    marginLeft: 5,
    color: '#49454F'
  },
  swiperBox: {
    width: '100%',
    height: 300,
    position: 'relative',
    marginTop: 12
  },
  swiper: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  swiperItemImage: {
    flex: 1,
    height: '100%'
  },
  featuresWrapper: {
    marginVertical: 30
  },
  aboutTitle: {
    marginBottom: 20
  },
  aboutContent: {
    fontSize: 16
  },
  cancelPreview: {
    width: '100%',
    fontSize: 20,
    position: 'absolute',
    bottom: 50,
    fontStyle: 24
  }
})

export default ProductDetail