
import { useMutation, gql } from '@apollo/client';

export const GQL = gql`
  mutation PaymentGatewayInitialize($checkoutId: ID!) {
    paymentGatewayInitialize(
      id: $checkoutId
      paymentGateways: [{id: "app.saleor.stripe"}]
    ) {
      gatewayConfigs {
        id
        data
        errors {
          field
          message
          code
        }
      }
      errors {
        field
        message
        code
      }
    }
  }
`;

export default function usePaymentGatewayInitializeMutation() {
  return useMutation(GQL)
}