
import { useMutation, gql } from '@apollo/client';

export const GQL = gql`
  mutation CheckoutLinesAddMutation($id: ID!, $lines: [CheckoutLineInput!]!) {
    checkoutLinesAdd(
      id: $id
      lines: $lines
    ) {
      checkout {
        lines {
          id
          variant {
            id
          }
          quantity
        }
      }
      errors {
        field
        message
      }
    }
  }
`

export default function useCheckouLinesAddMutation() {
  return useMutation(GQL)
}