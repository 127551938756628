import { create } from 'zustand'
import { Art } from '@/types'

interface IAppStore {
  artistAvatars: Art[]
  updateArtistAvatars: (v?: any[]) => void

  artStorys: Art[]
  updateArtStorys: (v?: any[]) => void

  collections: any[]
  updateCollections: (v?: any[]) => void
}

const useArtistPage = create<IAppStore>((set, get) => ({
  artistAvatars: [],
  updateArtistAvatars: (v?: Art[]) => set(() => ({
    artistAvatars: v ?? get().artistAvatars
  })),

  artStorys: [],
  updateArtStorys: (v?: Art[]) => set(() => ({
    artistAvatars: v ?? get().artistAvatars
  })),

  collections:  Array.from({length: 6}, (_, index) => ({
    id: 'UHJvZHVjdDoxNjU=',
    url: 'https://placehold.co/600x400',
    name: 'Mauris nunc congue . ' + index,
    price: '$200.00',
    desc: 'Egestas maecenas pharetra convallis posuere morbi leo urna molestie at. Viverra suspendisse potenti nullam ac tortor vitae. Nibh tellus molestie nunc non '
  })),
  updateCollections: (v?: any[]) => set(() => ({
    collections: v ?? get().collections
  })),
}))

export default useArtistPage;