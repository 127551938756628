import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import CategoryItem, { ICategoryItem } from './CategoryItem'
import useGallery from '@/hooks/store/useGallery'

interface CategorysProps {
  style?: Record<string, any>
}
function Categorys(props: CategorysProps) {
  const { currentCategory, categories, updateCurrentCategory } = useGallery()

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{
        ...styles.container,
        ...(props.style || {})
      }}
    >
      {categories?.map((category, index) => (
        <CategoryItem
          data={category}
          active={currentCategory}
          key={category.label}
          onPress={() => updateCurrentCategory(category.value)}
          style={index === 0 ? { marginLeft: 20, marginRight: 10 } : { marginRight: 10 }}
        />
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 32
  }
})

export default Categorys