
import { Image, ScrollView, StyleSheet, Text, View } from "react-native";
import Back from "@/components/Back";
import { SafeAreaView } from "react-native-safe-area-context";
import PageNavLayout from "@/components/PageNavLayout";
import FixedGoBuyView from "@/screens/product_detail/components/FixedGoBuyView";
import { responsiveHeight } from "react-native-responsive-dimensions";
import ProductDetail from "./components/ProductDetail";
import ArtistView from "./components/ArtistView";
import MoreArtwork from './components/MoreArtwork';
import useProduct from "@/saleor/api/useProduct";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import useProductDetail from "@/hooks/store/useProductDetail";
// import { useRoute } from "@react-navigation/native";
import useCheckoutCreateMutation from '@/saleor/api/useCheckoutCreateMutation';
import { DEFAULT_CHANNEL, DEFAULT_LOCALE, LAYOUT_MAX_WIDTH } from "@/constants";
import useCheckoutToken from "@/hooks/store/useCheckoutToken";
import useCheckouLineDeleteMutation from "@/saleor/api/useCheckouLineDeleteMutation";
import useCheckouLinesAddMutation from "@/saleor/api/useCheckouLinesAddMutation";
import useCheckoutByTokenQuery from "@/saleor/api/useCheckoutByTokenQuery";
import FlexLayout from "@/components/Flex";
import { useTabletOrDesktop } from "@/hooks/useMedia";
import Loading from "@/components/Loading/Loading";
import Space from "@/components/Space";
import Medium from "./components/PC/Medium";
import Header from "./components/PC/Header";
import SwiperPoster from "./components/PC/SwiperPoster";
import Details from "./components/PC/Details";
import ArtstView from "./components/PC/ArtstView";
import { filterImageFromProductMedia } from "@/utils";
import { isWeb } from "@/utils/platform";
import { Button } from "@rneui/themed";
import InriaSansText from "@/components/Text/InriaSansText";
import { AntDesign } from "@expo/vector-icons";
import NotificationDialog from "@/components/Dialog/Notification";

const SCROLL_HEIGHT = 1200
const ProductDetailScreen: React.FC<any> = ({ navigation, route }) => {
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  const [createCheckout, resultOfCreateCheckout] = useCheckoutCreateMutation();
  const [scrollContentOffsetY, setScrollContentOffsetY] = useState(0)
  const [notificationVisible, setNotificationVisible] = useState(false)

  const { checkout, updateCheckout, checkoutToken, updateCheckoutToken } = useCheckoutToken()
  const [getCheckoutBytoken, { data: checkoutResult }] = useCheckoutByTokenQuery(checkoutToken)
  const [deleteCheckoutlines] = useCheckouLineDeleteMutation()
  const [addCheckoutlines] = useCheckouLinesAddMutation()
  const { updateInfo, buying, updateBuying, info } = useProductDetail()
  const { loading, data: detailInfo } = useProduct({
    productId: (route?.params as any)?.productId || '',
    channel: DEFAULT_CHANNEL
  })
  const variants = detailInfo?.product?.variants

  useLayoutEffect(() => {
    detailInfo?.product?.name && navigation.setOptions({
      title: `Artwork: ${detailInfo?.product?.name}`
    })
  }, [detailInfo])

  useEffect(() => {
    detailInfo && updateInfo(detailInfo?.product)
  }, [detailInfo])

  useEffect(() => {
    checkoutResult?.checkout && updateCheckout(checkoutResult.checkout)
  }, [checkoutResult])

  useEffect(() => {
    checkoutToken && getCheckoutBytoken()
  }, [checkoutToken])

  const images = useMemo(() => {
    return filterImageFromProductMedia(info?.media ?? [])
  }, [info?.media])

  const handleBuy = useCallback(async () => {
    try {
      if (!variants?.length) {
        console.log('please configure variant for product')
        return
      }
      updateBuying(true)
      if (checkout) {
        if (checkout?.lines?.length) {
          // remove all existing lines
          await deleteCheckoutlines({
            variables: {
              id: checkout.id,
              linesIds: checkout.lines.map(line => line.id)
            }
          })
        }
        // add current
        await addCheckoutlines({
          variables: {
            id: checkout.id,
            lines: [
              {
                quantity: 1,
                variantId: variants[0].id
              }
            ]
          }
        })
        await getCheckoutBytoken()
        navigation.push('checkout')
      } else {
        const { data: createCheckoutData } = await createCheckout({
          variables: {
            // email: user?.email,
            channel: DEFAULT_CHANNEL,
            lines: [
              {
                quantity: 1,
                // currently, by default, get 0
                variantId: variants[0].id,
              },
            ]
          },
        });
        createCheckoutData?.checkoutCreate?.errors.forEach((error: any) => {
          console.log('checkoutCreate error', error)
        });
        if (!!createCheckoutData?.checkoutCreate?.errors?.length) {
          setNotificationVisible(true)
          return
        }
        const token = createCheckoutData?.checkoutCreate?.checkout?.token
        if (token) {
          updateCheckoutToken(token);
        }
        navigation.push('checkout')
      }
    } catch (error) {
      console.log('buy error', error)
    } finally {
      updateBuying(false)
    }
  }, [variants, checkout])

  const { artistWorkBornYear, mediumValue } = useMemo(() => {
    let artistWorkBornYear = ''
    let mediumValue = ''
    const dateAttribute = (info?.attributes || []).find((item) => item?.attribute?.name === 'Date')
    const mediumAttribute = (info?.attributes || []).find((item) => item?.attribute?.name === 'Medium')
    if (dateAttribute) {
      artistWorkBornYear = dateAttribute?.values?.length ? dateAttribute?.values?.[0]?.name || '' : ''
    }
    if (mediumAttribute) {
      mediumValue = mediumAttribute?.values?.length ? mediumAttribute?.values?.[0]?.name || '' : ''
    }
    return {
      artistWorkBornYear,
      mediumValue
    }
  }, [info?.attributes])

  const NotificationView = (
    <NotificationDialog
      visible={notificationVisible}
      content={(resultOfCreateCheckout.data?.checkoutCreate?.errors || [])?.map((error: any) => error.message).join(';')}
      onBackdropPress={() => setNotificationVisible(false)}
      onCancel={() => setNotificationVisible(false)}
    />
  )

  if (loading) return (
    <Loading />
  )

  if (!ready) return null

  if (isTabletOrDesktop) {
    return (
      <View style={styles.container}>

        {loading && <Loading />}

        {/** fixed view */}
        {scrollContentOffsetY >= SCROLL_HEIGHT && isWeb && isTabletOrDesktop && <FlexLayout direction="row" justify="between" items="center" style={{
          ...styles.fixedLayoutBox,
          maxWidth: LAYOUT_MAX_WIDTH,
          marginHorizontal: 'auto'
        }}>
          <View>
            <Space size={10} />
            <Medium label={mediumValue} />
            <Space direction="v" size={10} />
            <Header
              title={info?.name || '-'}
              workBornYear={artistWorkBornYear}
              artistName={info?.artist?.name || '-'}
            />
          </View>
          <FlexLayout direction="row" justify="start" items="center">
            {!!images.length && <Image style={{ width: 100, height: 100, borderWidth: 1, borderColor: 'rgba(0,0,0,0)' }} source={{ uri: images[0].url }} resizeMode="cover" />}
            <Space direction="h" size={50} />
            <Button loading={buying} buttonStyle={styles.buyButton} onPress={handleBuy}>
              <InriaSansText style={styles.rightLabel}>Buy</InriaSansText>
              <AntDesign name="arrowright" size={24} color="white" />
            </Button>
          </FlexLayout>
        </FlexLayout>}

        <ScrollView
          onScroll={(event) => isWeb && isTabletOrDesktop && setScrollContentOffsetY(event.nativeEvent.contentOffset.y)}
          scrollEventThrottle={200}
        >
          <SafeAreaView style={{ maxWidth: LAYOUT_MAX_WIDTH, ...styles.safeArea }}>
            <FlexLayout direction="row" justify="start" items="start">
              <Back navigation={navigation} />
            </FlexLayout>
            <View>
              <Space size={10} />
              <Medium label={mediumValue} />
              <Space direction="v" size={10} />
              <Header
                title={info?.name || '-'}
                workBornYear={artistWorkBornYear}
                artistName={info?.artist?.name || '-'}
              />
            </View>
            {!!images.length && <SwiperPoster posters={images} />}
            <Space size={30} />
            <Details
              onPressBuyBtn={handleBuy}
              handleingBuy={buying}
            />
            <FlexLayout>
              <View style={styles.line} />
            </FlexLayout>
            <ArtstView />
            <Space size={50} />
          </SafeAreaView>
        </ScrollView>
        {NotificationView}
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <ScrollView>
        <SafeAreaView>
          {/** Back icon */}
          <PageNavLayout>
            <Back navigation={navigation} />
          </PageNavLayout>

          {loading && <Loading />}

          {/** Product Detail */}
          <ProductDetail />

          <Space size={48} />

          {/** The Artist */}
          <ArtistView />

          {/** More Artwork */}
          <Space size={36} />
          <MoreArtwork />

          <Space size={150} />
        </SafeAreaView>
      </ScrollView>
      {/** Fixed Buy Button  */}
      <FixedGoBuyView onPress={handleBuy} loading={buying} />
      {NotificationView}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    height: responsiveHeight(100),
    overflow: 'hidden',
    backgroundColor: 'white'
  },
  safeArea: {
    marginHorizontal: 'auto',
    width: '100%',
    marginTop: 30,
    minHeight: responsiveHeight(100),
    position: 'relative'
  },
  aboutTitle: {
    marginBottom: 20
  },
  aboutContent: {
    fontSize: 16
  },
  line: {
    width: '88%',
    height: 1,
    backgroundColor: '#CAC4D0',
    marginVertical: 40
  },
  fixedLayoutBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.1)',
    paddingBottom: 20
  },
  buyButton: {
    width: 115,
    height: 60,
    overflow: 'hidden',
    borderRadius: 60,
    backgroundColor: 'black',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rightLabel: {
    fontSize: 20,
    color: 'white',
    marginRight: 12
  },
})


export default ProductDetailScreen
