import { useMemo } from "react"
import { StyleSheet, View, ViewProps } from "react-native"

interface FlexLayoutProps extends ViewProps {
  children: React.ReactNode
  direction?: 'row' | 'column'
  justify?: 'start' | 'center' | 'end' | 'around' | 'between'
  items?: 'center' | 'start' | 'end' | 'stretch' | 'baseline'
}
const FlexLayout: React.FC<FlexLayoutProps> = ({
  children,
  direction = 'column',
  justify = 'center',
  items = 'center',
  style,
  ...rest
}) => {

  const alignItems = useMemo(() => {
    switch (items) {
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      default:
        return items
    }
  }, [items])

  const justifyContent = useMemo(() => {
    switch (justify) {
      case 'start':
        return 'flex-start';
      case 'end':
        return 'flex-end';
      case 'around':
        return 'space-around';
      case 'between':
        return 'space-between';
      default:
        return justify
    }
  }, [justify])

  return (
    <View {...rest} style={{
      ...styles.container,
      ...(style || {}) as any,
      flexDirection: direction,
      alignItems,
      justifyContent
    } as any}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {}
})

export default FlexLayout