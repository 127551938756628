import JostText from "@/components/Text/JostText"
import ViewPadding from "@/components/ViewPadding"
import { formatAsMoney } from "@/utils"
import { useMemo } from "react"
import { StyleSheet, View, Image } from "react-native"

interface ProductInfoProps {
  checkout: any
}
const ProductInfo: React.FC<ProductInfoProps> = ({
  checkout
}) => {

  const line = useMemo(() => {
    return checkout?.lines?.length ? checkout?.lines[0] : null
  }, [checkout])

  const thumbnailUrl = line?.variant?.product?.thumbnail?.url

  if (!line) return null

  return (
    <ViewPadding style={styles.container}>
      <View style={styles.leftBox}>
        {/** TODO: actually, we need to display variant name */}
        <JostText style={styles.variantName} numberOfLines={1} ellipsizeMode="tail">{line?.variant?.product?.name}</JostText>
      </View>

      <View style={styles.rightBox}>
        {thumbnailUrl && <View style={styles.thumbnailBox}>
          <Image style={styles.thumbnail} source={{ uri: thumbnailUrl }} resizeMode="cover" />
          <View style={styles.countBox}>
            <JostText style={styles.count}>{line.quantity}</JostText>
          </View>
        </View>}
        <JostText style={styles.money}>{formatAsMoney(line.variant?.pricing?.price?.gross?.amount)}</JostText>
      </View>
    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10
  },
  leftBox: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxWidth: 200,
    overflow: 'hidden'
  },
  variantName: {
    fontSize: 22,
    fontWeight: '600'
  },
  productName: {
    color: 'rgba(0,0,0,0.6)'
  },
  rightBox: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1
  },
  thumbnailBox: {
    width: 34,
    height: 34,
    position: 'relative',
    borderRadius: 5,
  },
  thumbnail: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.1)'
  },
  money: {
    marginLeft: 10,
    fontSize: 18,
    fontWeight: '600'
  },
  countBox: {
    width: 20,
    height: 20,
    borderRadius: 10,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.1)',
    alignItems: 'center',
    position: 'absolute',
    bottom: -10,
    right: -10,
    backgroundColor: 'white'
  },
  count: {
    fontSize: 10
  }
})

export default ProductInfo