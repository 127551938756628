import JostText from "@/components/Text/JostText"
import ViewPadding from "@/components/ViewPadding"
import { StyleSheet } from "react-native"

interface AddressDisplayProps {
  address: any
}

const AddressDisplay: React.FC<AddressDisplayProps> = ({
  address
}) => {
  return (
    <ViewPadding style={styles.container}>
      <JostText style={styles.text}>{address?.firstName} {address?.lastName}</JostText>
      <JostText style={styles.text}>{address?.streetAddress1}</JostText>
      <JostText style={styles.text}>{address?.postalCode} {address?.city}, {address?.country.country}</JostText>
      <JostText style={styles.text}>{address?.phone}</JostText>
    </ViewPadding >
  )
}

const styles = StyleSheet.create({
  container: {},
  text: {
    fontSize: 14,
    marginVertical: 10
  }
})

export default AddressDisplay