
import { SafeAreaView } from "react-native-safe-area-context";
import { Text, ScrollView, StyleSheet, View, Image } from "react-native";
import useOrderDetailQuery from "@/saleor/api/useOrderDetailQuery";
import ViewPadding from "@/components/ViewPadding";
import Title from "@/components/Title";
import PageNavLayout from "@/components/PageNavLayout";
import Back from "@/components/Back";
import TextItem from "./TextItem";
import { formatAsMoney, formatDateTimeToUS } from "@/utils";
import FlexLayout from "@/components/Flex";
import Loading from "@/components/Loading/Loading";
import FallbackDesktopMobileLayout from "@/components/FallbackDesktopMobileLayout";
import JostText from "@/components/Text/JostText";

const OrderScreen: React.FC<any> = ({ navigation, route }) => {
  const orderToken = (route?.params as any)?.orderToken
  const { loading, data, error, } = useOrderDetailQuery(orderToken);
  const order = data?.orderByToken
  const line = order?.lines?.[0] || null

  return (
    <FallbackDesktopMobileLayout>
      <ScrollView style={styles.container}>
        <SafeAreaView>
          <PageNavLayout>
            <Back navigation={navigation} />
          </PageNavLayout>

          <ViewPadding>
            <Title label="Order information" size={24} />
          </ViewPadding>

          <ViewPadding>
            {loading && <Loading />}
            {order ? (
              <>
                <TextItem label="Number" value={order.number} />
                <TextItem label="Status" value={order.status} />
                <TextItem label="Payment" value={order.paymentStatusDisplay} />
                <TextItem label="Summary">
                  <JostText style={styles.subText}>Taxes: {formatAsMoney(order.total.tax.amount)}</JostText>
                  <JostText style={styles.subText}>Total: {formatAsMoney(order.total.gross.amount)}</JostText>
                </TextItem>
                <TextItem label="Customer" value={order.userEmail} />
                <TextItem label="Created" value={formatDateTimeToUS(order.created)} />
                {order.billingAddress && <TextItem label="Shipping address">
                  <JostText style={styles.subText}>{order.billingAddress.firstName + ' ' + order.billingAddress.lastName}</JostText>
                  <JostText style={styles.subText}>{order.billingAddress.phone}</JostText>
                  <JostText style={styles.subText}>{order.billingAddress.streetAddress1}</JostText>
                  <JostText style={styles.subText}>{order.billingAddress.postalCode} {order.billingAddress.city}</JostText>
                  <JostText style={styles.subText}>{order.billingAddress.countryArea}, {order.billingAddress.country?.country || ''}</JostText>
                </TextItem>}
                <TextItem label="Product">
                  <Image
                    style={styles.orderThumbnail}
                    source={{ uri: line.thumbnail?.url || '' }}
                    resizeMode="cover"
                  />
                  <JostText style={styles.subText}>Product Name: {line?.productName}</JostText>
                  <JostText style={styles.subText}>Quantity: {line?.quantity}</JostText>
                  <JostText style={styles.subText}>Variant: {line?.variantName}</JostText>
                </TextItem>
              </>
            ) : (
              <>
                {!order && error && (
                  <FlexLayout style={{ marginTop: 100 }}>
                    <JostText>You don't have the permission to view order.</JostText>
                  </FlexLayout>
                )}
              </>
            )}
          </ViewPadding>
        </SafeAreaView>
      </ScrollView>
    </FallbackDesktopMobileLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subText: {
    fontSize: 16,
    marginBottom: 3
  },
  productPoster: {
    width: 60,
    height: 60,
    borderRadius: 5
  },
  orderThumbnail: {
    width: 50,
    height: 50,
    borderWidth: 2,
    borderColor: 'white'
  },
})

export default OrderScreen
