import { useQuery, gql } from '@apollo/client';
import { ARTIST_FRAGMENT } from '../fragments';

export const QUERY = gql`
  query {
    artists(first: 100) {
      edges {
        node {
          ...Artist
        }
      }
    }
  }
  ${ARTIST_FRAGMENT}
`;

export default function useArtists() {
  return useQuery(QUERY);
}