import React from 'react'
import { StyleSheet } from 'react-native'
import useArtistPage from '@/hooks/store/useArtistPage'
import ArtStoryItem from './Item'
import ViewPadding from '@/components/ViewPadding'
import { useNavigation } from '@react-navigation/native'
import { useTabletOrDesktop } from '@/hooks/useMedia'

interface ArtStorysProps {
  style?: Record<string, any>
}
function ArtStorys(props: ArtStorysProps) {
  const { artistAvatars } = useArtistPage()
  const navigation: any = useNavigation()
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  if (!ready) return null

  return (
    <ViewPadding
      style={{
        ...styles.container,
        ...(props.style || {}),
        ...(isTabletOrDesktop ? {
          flexDirection: 'row',
          flexWrap: 'wrap',
        } : {})
      }}
    >
      {artistAvatars?.map((art) => (
        <ArtStoryItem
          data={art}
          key={art.id}
          onPress={() => navigation.push('artstory', {
            artId: art.id
          })}
        />
      ))}
    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20
  }
})

export default ArtStorys