import { useEffect, useState } from "react"
import { Image } from "react-native"


function useImageSize(url: string) {
  const [imageRate, setImageRate] = useState(0)
  const [nativeWidth, setNativeWidth] = useState(0)
  const [nativeHeight, setNativeHeight] = useState(0)

  useEffect(() => {
    if (!url) return
    Image.getSize(url, (width, height) => {
      if (height !== 0) setImageRate(width / height)
      setNativeWidth(width)
      setNativeHeight(height)
    })
  }, [url])

  return {
    rate: imageRate,
    nativeWidth,
    nativeHeight
  }
}

export default useImageSize