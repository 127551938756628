import { Dialog } from "@rneui/themed"
import FlexLayout from "../Flex"
import { StyleSheet, TouchableOpacity, View, ScrollView } from "react-native"
import OperaButton from "../Button"
import JostText from "../Text/JostText"
import useCountries from "@/hooks/useCountries"
import { useState } from "react"
import { Country } from '@/hooks/useCountries';

interface PickerDialogProps {
  title?: string
  visible: boolean
  onBackdropPress: () => void
  onCancel: () => void
  onConfirm: (country: Country) => void
  defaultCountry?: Country
}
const PickerDialog: React.FC<PickerDialogProps> = ({
  title = 'Select Country',
  visible,
  onBackdropPress,
  onCancel,
  onConfirm,
  defaultCountry
}) => {
  const { getAll } = useCountries()
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(defaultCountry || null)

  return (
    <Dialog
      isVisible={visible}
      onBackdropPress={onBackdropPress}
      overlayStyle={{ maxWidth: 400 }}
    >
      {title && <FlexLayout style={styles.titleBox}>
        <JostText style={{ fontSize: 20, fontWeight: '500' }}>{title}</JostText>
      </FlexLayout>}

      <ScrollView style={styles.contentBox}>
        {getAll().map((country) => (
          <TouchableOpacity key={country.value} style={{ width: '100%' }} onPress={() => setSelectedCountry(country)}>
            <FlexLayout style={{
              ...styles.countryItem,
              ...selectedCountry?.value === country.value ? {
                backgroundColor: '#000'
              } : {}
            }} direction="row" justify="start" items="center">
              <JostText>{country.flag}</JostText>
              <JostText style={{ fontWeight: '600', marginHorizontal: 15, color: selectedCountry?.value === country.value ? 'white' : '#000' }}>{country.label}</JostText>
              <JostText style={{ color: selectedCountry?.value === country.value ? 'white' : '#858585' }}>({country.region})</JostText>
            </FlexLayout>
          </TouchableOpacity>
        ))}
      </ScrollView>

      <FlexLayout style={styles.btnsBox} direction="row" justify="between" items="end">
        <OperaButton
          title="CANCEL"
          containerStyle={{ flex: 1, width: 'auto' }}
          buttonStyle={{ backgroundColor: '#858585', margin: 0 }}
          onPress={() => {
            setSelectedCountry(null)
            onCancel?.()
          }}
        />
        <View style={{ width: 10 }} />
        <OperaButton
          title="CONFIRM"
          disabled={!selectedCountry}
          onPress={() => onConfirm(selectedCountry!)}
          containerStyle={{ flex: 1, width: 'auto', margin: 0 }}
        />
      </FlexLayout>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  titleBox: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginLeft: -20,
    marginRight: -20,
    paddingBottom: 10
  },
  contentBox: {
    paddingVertical: 20,
    minHeight: 250,
    maxHeight: 450,
    overflowY: 'scroll'
  },
  countryItem: {
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 10,
    marginVertical: 10,
    borderRadius: 5
  },
  btnsBox: {
    height: 50,
  }
})

export default PickerDialog
