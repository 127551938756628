import FlexLayout from "@/components/Flex";
import { useState } from "react"
import Swiper from 'react-native-web-swiper'
import { StyleSheet, Modal, TouchableOpacity } from 'react-native';
import PosterItem from '../PosterItem';
import { MaterialIcons } from '@expo/vector-icons';
import ImageViewer from 'react-native-image-zoom-viewer';
import JostText from "@/components/Text/JostText";

interface SwiperPosterProps {
  posters: any[]
}

const SwiperPoster: React.FC<SwiperPosterProps> = ({
  posters
}) => {
  const [previewIndex, setPreviewIndex] = useState(0)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [swiper, attachSwiper] = useState<Swiper | null>(null)
  const PrevComponent = () => <FlexLayout style={{ position: 'absolute', top: -250 }}>
    <MaterialIcons name="keyboard-arrow-left" size={50} color="#877A99" onPress={() => swiper?.goToPrev?.()} />
  </FlexLayout>

  const NextComponent = () => <FlexLayout style={{ position: 'absolute', top: -250 }}>
    <MaterialIcons name="keyboard-arrow-right" size={50} color="#877A99" onPress={() => swiper?.goToNext?.()} />
  </FlexLayout>

  return (
    <>
      <Modal visible={showPreviewModal} transparent={true} style={{ position: 'absolute' }}>
        <ImageViewer
          enableImageZoom
          enablePreload
          index={previewIndex}
          imageUrls={posters.map((image) => ({
            url: image.url,
          }))}
        />
        <FlexLayout direction='row' style={styles.cancelPreview}>
          <TouchableOpacity onPress={() => setShowPreviewModal(false)}>
            <JostText style={{ color: 'white' }}>Cancel Preview</JostText>
          </TouchableOpacity>
        </FlexLayout>
      </Modal>
      <FlexLayout style={styles.swiperBox}>
        <Swiper
          ref={attachSwiper}
          containerStyle={styles.swiper}
          controlsProps={{
            PrevComponent: PrevComponent as any,
            NextComponent: NextComponent as any,
            dotActiveStyle: {
              backgroundColor: '#817A56',
            }
          }}
        >
          {posters.map((item, index) => (
            <PosterItem height={500} onPress={() => {
              setPreviewIndex(index)
              setShowPreviewModal(true)
            }} key={item.id} info={item} />
          ))}
        </Swiper>
      </FlexLayout>
    </>
  )
}

const styles = StyleSheet.create({
  swiperBox: {
    width: '100%',
    height: 500,
    position: 'relative',
  },
  swiper: {
    // overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  cancelPreview: {
    width: '100%',
    fontSize: 20,
    position: 'absolute',
    bottom: 50,
    fontStyle: 24
  }
})

export default SwiperPoster;