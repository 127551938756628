import { Dialog, } from "@rneui/themed"
import FlexLayout from "../Flex"
import { StyleSheet, View } from "react-native"
import OperaButton from "../Button"
import JostText from "../Text/JostText"

interface ConfirmDialogProps {
  title?: string
  visible: boolean
  onBackdropPress: () => void
  onCancel: () => void
  onConfirm: () => void
  content: string
}
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title,
  visible,
  onBackdropPress,
  content,
  onCancel,
  onConfirm
}) => {
  return (
    <Dialog
      isVisible={visible}
      onBackdropPress={onBackdropPress}
      overlayStyle={{ maxWidth: 320 }}
    >
      {title && <FlexLayout style={styles.titleBox}>
        <JostText style={{ fontSize: 20, fontWeight: '500' }}>{title}</JostText>
      </FlexLayout>}
      <FlexLayout style={styles.contentBox}>
        <JostText>{content}</JostText>
      </FlexLayout>
      <FlexLayout style={styles.btnsBox} direction="row" justify="between" items="end">
        <OperaButton
          title="CANCEL"
          containerStyle={{ flex: 1, width: 'auto' }}
          buttonStyle={{ backgroundColor: '#858585', margin: 0 }}
          onPress={onCancel}
        />
        <View style={{ width: 10 }} />
        <OperaButton
          title="YES"
          onPress={onConfirm}
          containerStyle={{ flex: 1, width: 'auto', margin: 0 }}
        />
      </FlexLayout>
    </Dialog>
  )
}

const styles = StyleSheet.create({
  titleBox: {
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    marginLeft: -20,
    marginRight: -20,
    paddingBottom: 10
  },
  contentBox: {
    minHeight: 130
  },
  btnsBox: {
    height: 50,
  }
})

export default ConfirmDialog
