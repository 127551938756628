import { ButtonGroup } from "@rneui/themed"
import { StyleSheet, Text, View } from "react-native"
import AddressDisplay from "../AddressDisplay";
import Title from "@/components/Title";
import { useState } from "react";
import { AddressFormData } from "@/types";
import JostText from "@/components/Text/JostText";

interface SavedAddressSelectionListProps {
  addresses: any[]
  onSuccessUpdated: () => void
  updateAddressMutation: (address: AddressFormData) => Promise<any[]>;
}
const SavedAddressSelectionList = ({
  addresses,
  onSuccessUpdated,
  updateAddressMutation
}: SavedAddressSelectionListProps) => {
  const [selectedIndexes, setSelectedIndexes] = useState(-1)
  const [error, setError] = useState('')
  const [submiting, setSubmiting] = useState(false)

  const onSelected = async (address: AddressFormData) => {
    try {
      setSubmiting(true)
      const errors = await updateAddressMutation({
        firstName: address.firstName || "",
        lastName: address.lastName || "",
        phone: address.phone || "",
        country: (address.country.code) || "US",
        streetAddress1: address.streetAddress1 || "",
        city: address.city || "",
        postalCode: address.postalCode || "",
        countryArea: address.city || ""
      });
      if (errors.length) {
        console.log(errors)
        const { field, message } = errors[0]
        setError(`${field}: ${message}`)
        return;
      }
      onSuccessUpdated?.()
    } catch (error) {
      console.log(error)
    } finally {
      setSubmiting(false)
    }
  };

  return (
    <View style={styles.container}>
      <Title size={14} label="Use existing address" />
      {error && <JostText style={{ color: 'red' }}>{error}</JostText>}
      <ButtonGroup
        buttons={addresses.map((address => <AddressDisplay key={JSON.stringify(address)} address={address} />))}
        buttonContainerStyle={{ height: 200 }}
        selectMultiple={false}
        vertical
        selectedIndexes={selectedIndexes === -1 ? undefined : [selectedIndexes]}
        selectedButtonStyle={{ backgroundColor: 'black' }}
        onPress={(value) => {
          setSelectedIndexes(value);
          onSelected(addresses[value])
        }}
        disabled={submiting}
        containerStyle={{ marginBottom: 20 }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {}
})

export default SavedAddressSelectionList