
import { useMediaQuery } from "react-responsive";
import { useEffect, useRef, useState } from 'react'

export const useDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false)
  const bool = useMediaQuery({ 
    minDeviceWidth: 992,
    query: '(min-device-width: 992px)'
  }, undefined, (match) => setIsDesktop(match))

  useEffect(() => {
    setIsDesktop(bool)
  }, [bool])
  return isDesktop
}

export const useTablet = () => {
  const [isTablet, setIsTablet] = useState(false)
  const bool = useMediaQuery({ 
    minDeviceWidth: 768,
    query: '(min-device-width: 768px)'
  }, undefined, (match) => setIsTablet(match))

  useEffect(() => {
    setIsTablet(bool)
  }, [bool])
  return isTablet
}

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false)
  const bool = useMediaQuery({ 
    maxDeviceWidth: 768, 
    query: '(max-device-width: 768px)' 
  }, undefined, (match) => setIsMobile(match))

  useEffect(() => {
    setIsMobile(bool)
  }, [bool])
  return isMobile
}

export const useTabletOrDesktop = () => {
  const isTablet = useTablet()
  const isDesktop = useDesktop()
  const bool = isDesktop || isTablet
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setReady(true)
    }, 1000)
  }, [])  

  return {
    ready,
    isTabletOrDesktop: bool
  }
}