import FlexLayout from "@/components/Flex"
import Space from "@/components/Space"
import ViewPadding from "@/components/ViewPadding"
import { StyleSheet } from "react-native"
import CollectList from "./List"
import { useMemo } from "react"
import { useTabletOrDesktop } from "@/hooks/useMedia"
import { ProductItem } from "@/types"
import JostText from "@/components/Text/JostText"

interface ArtInfoProps {
  products: ProductItem[]
}

const CollectionView: React.FC<ArtInfoProps> = ({
  products
}) => {
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()

  const { leftCollections, rightCollections } = useMemo(() => {
    const leftCollections: ProductItem[] = []
    const rightCollections: ProductItem[] = []
    for (let i = 0; i < products.length; i++) {
      if (i % 2 === 0) {
        leftCollections.push(products[i])
      } else {
        rightCollections.push(products[i])
      }
    }
    return {
      leftCollections,
      rightCollections
    }
  }, [products])

  if (!ready) return null

  return (
    <ViewPadding style={{
      ...styles.container,
      ...(isTabletOrDesktop ? {
        backgroundColor: 'transparent',
        borderTopRightRadius: 0,
        paddingTop: 0
      } : {})
    }}>
      <JostText style={styles.title}>Collection</JostText>
      <Space size={isTabletOrDesktop ? 20 : 36} />
      <FlexLayout style={styles.content} direction="row" justify="between" items="start">
        <CollectList dataSource={leftCollections} />
        <Space direction="h" size={12} />
        <CollectList dataSource={rightCollections} />
      </FlexLayout>
    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F2F0E5',
    borderTopRightRadius: 160,
    overflow: 'hidden',
    paddingTop: 70,
    paddingBottom: 20,
  },
  title: {
    fontSize: 32,
    fontWeight: '600'
  },
  content: {}
})

export default CollectionView