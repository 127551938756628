import { CSRF_TOKEN, REFRESH_TOKEN } from "@/constants";
import { client } from "@/libs/ApolloClient";
import { RefreshToken_GQL } from "@/saleor/api/useRefreshToken";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default async function refreshToken() {
  try {
    const refreshToken = await AsyncStorage.getItem(REFRESH_TOKEN)
    const csrfToken = await AsyncStorage.getItem(CSRF_TOKEN)
    const mutationResult = await client.mutate({
      mutation: RefreshToken_GQL,
      variables: {
        csrfToken,
        refreshToken
      },
    });
    return mutationResult
  } catch (error) {
    console.log(error)
  }
}