

import { SafeAreaView } from "react-native-safe-area-context";
import { ScrollView, StyleSheet, Text } from "react-native";
import PageNavLayout from "@/components/PageNavLayout";
import Back from "@/components/Back";
import ViewPadding from "@/components/ViewPadding";
import Title from "@/components/Title";
import FlexLayout from "@/components/Flex";
import { MaterialIcons } from '@expo/vector-icons';
import FallbackDesktopMobileLayout from "@/components/FallbackDesktopMobileLayout";

const ContactUsScreen: React.FC<any> = ({ navigation }) => {
  return (
    <FallbackDesktopMobileLayout>
      <ScrollView style={styles.container}>
        <SafeAreaView>
          <PageNavLayout>
            <Back navigation={navigation} />
          </PageNavLayout>
          <ViewPadding>
            <Title label="Contact Us" size={20} />
            <FlexLayout style={{ marginTop: 20 }} direction="row" justify="start" items="center">
              <MaterialIcons name="email" size={16} color="black" />
              <Text style={{ fontSize: 14, fontWeight: '600', marginLeft: 10 }}>admin@example.com</Text>
            </FlexLayout>

          </ViewPadding>
        </SafeAreaView>
      </ScrollView>
    </FallbackDesktopMobileLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
})

export default ContactUsScreen
