import { ProductItem } from '@/types'
import { create } from 'zustand'

interface IAppStore {
  info: ProductItem | null
  updateInfo: (v?: ProductItem) => void

  buying: boolean
  updateBuying: (v?: boolean) => void
}

const useProductDetail = create<IAppStore>((set, get) => ({
  info: null,
  updateInfo: (v?: ProductItem) => set(() => ({
    info: v ?? get().info
  })),

  buying: false,
  updateBuying: (v?: boolean) => set(() => ({
    buying: v ?? get().buying
  })),
}))

export default useProductDetail;