import { DEFAULT_CHANNEL } from "@/constants";
import useGallery from "@/hooks/store/useGallery";
import { useTabletOrDesktop, useMobile } from "@/hooks/useMedia";
import useCategories from "@/saleor/api/useCategories";
import { useProductByCategory } from "@/saleor/api/useProducts";
import { ProductItem } from "@/types";
import { useEffect, useMemo, useState } from "react";

const PAGE_SIZE = 10

export default function useGalleryPage () {
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  const { currentCategory, updateCategories, updateCurrentCategory } = useGallery()
  const [getProductsByCategory, { loading: loadingProducts, called, data: productsData }] = useProductByCategory()
  const { data: categoriesData } = useCategories()
  const [products, setProducts] = useState<ProductItem[]>([])

  const categories = useMemo(() => {
    if (!categoriesData?.categories?.edges?.length) return []
    return categoriesData.categories.edges.map((item: any) => ({
      label: item.node?.name,
      value: item.node?.id
    }))
  }, [categoriesData])

  useEffect(() => {
    updateCategories(categories)
    categories.length && updateCurrentCategory(categories[0].value)
  }, [categories])

  useEffect(() => {
    if (currentCategory) {
      getProductsByCategory({
        variables: {
          categoryIds: [currentCategory],
          channel: DEFAULT_CHANNEL,
          first: PAGE_SIZE
        }
      })
    }
  }, [currentCategory])

  useEffect(() => {
    if (productsData?.products?.edges?.length) {
      setProducts((prev) => [...prev, ...productsData.products.edges.map((item: any) => ({
        ...item.node
      }))])
    }
  }, [productsData])

  return {
    called,
    isTabletOrDesktop,
    ready,
    loadingProducts,
    products,
    getProductsByCategory,
    currentCategory,
    productsData,
    categories
  }
}