import { useState } from 'react'
import Back from "@/components/Back";
import OperaButton from '@/components/Button';
import useAccount from "@/saleor/api/useAccount";
import { EXPO_PUBLIC_SALEOR_CHANNEL } from "@env";
import { withSafeArea } from "@/utils/withSafeArea";
import { responsiveHeight } from "react-native-responsive-dimensions";
import { View, TextInput, Text, StyleSheet, ImageBackground } from "react-native";
import FlexLayout from '@/components/Flex';

const SignUpScreen: React.FC<any> = ({ navigation }) => {
  const [email, onChangeEmail] = useState('');
  const [submiting, setSubmiting] = useState(false);
  const [password, onChangePassword] = useState('');
  const [firstName, onChangeFirstName] = useState('');
  const [lastName, onChangeLastName] = useState('');
  const [errMsg, onChangeErrMsg] = useState('');

  const [RegisterAccount] = useAccount();

  const handleSubmit = () => {
    if (!validateEmail(email)) {
      onChangeErrMsg('Invalid email address');
      return;
    }

    if (password.length < 6) {
      onChangeErrMsg('Password must be at least 6 characters');
      return;
    }
    setSubmiting(true)
    RegisterAccount({
      variables: {
        email, password, firstName, lastName,
        redirectUrl: `${window.location.origin}/account_confirm`,
        channel: EXPO_PUBLIC_SALEOR_CHANNEL
      }
    })
      .then(res => {
        const successful = res.data.accountRegister.errors.length === 0;

        if (successful) {
          onChangeErrMsg('Please verify your email first');
        } else {
          onChangeErrMsg(res.data.accountRegister.errors[0].message);
        }
      })
      .catch(error => {
        onChangeErrMsg(error.message);
      })
      .finally(() => setSubmiting(false))
  };

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <View>
      <ImageBackground
        source={require("./signup-background.jpg")}
        style={styles.background}
      />

      <View style={styles.content}>
        <View style={styles.contentBg} />
        <View style={styles.buttonGroup}>
          <View style={styles.title}>Global Art</View>

          <Text style={{ color: 'red' }}>{errMsg}</Text>

          <FlexLayout direction='row' justify='start' items='start' style={styles.back}>
            <Back size={32} navigation={navigation} />
            <Text style={styles.selectorMode}>SIGN UP</Text>
          </FlexLayout>

          <View style={styles.fixToText}>
            <TextInput
              style={{ ...styles.textInput, maxWidth: 160 }}
              value={firstName}
              placeholder="First Name"
              placeholderTextColor="#79747E"
              onChangeText={text => {
                onChangeFirstName(text)
              }}
            />
            <TextInput
              style={{ ...styles.textInput, maxWidth: 160 }}
              value={lastName}
              placeholder="Last Name"
              placeholderTextColor="#79747E"
              onChangeText={text => {
                onChangeLastName(text)
              }}
            />
          </View>

          <TextInput
            style={styles.textInput}
            value={email}
            placeholder="Email"
            placeholderTextColor="#79747E"
            onChangeText={text => {
              onChangeEmail(text)
            }}
          />

          <TextInput
            style={styles.textInput}
            value={password}
            secureTextEntry={true}
            placeholder="Password"
            placeholderTextColor="#79747E"
            onChangeText={text => {
              onChangePassword(text)
            }}
          />

          <OperaButton containerStyle={{ marginVertical: 10 }} title="SIGN UP" loading={submiting} onPress={handleSubmit} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    width: '100%',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    bottom: 0,
    zIndex: 100
  },
  contentBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5,
    opacity: 0.9,
    backgroundColor: '#fff',
  },
  background: {
    height: responsiveHeight(100),
    opacity: 0.5
  },
  back: {
    marginLeft: -10,
  },
  title: {
    color: '#000',
    fontFamily: 'Inria Serif',
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 29.5,
    paddingBottom: 100,
  },
  selectorMode: {
    color: '#000',
    fontSize: 24,
    fontStyle: 'normal',
    top: 4
  },
  fixToText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textInput: {
    borderColor: 'gray',
    borderWidth: 1,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 10,
    padding: 10,
  },
  buttonGroup: {
    margin: 'auto',
    width: '100%',
    maxWidth: 420,
    padding: 20,
    position: 'relative',
    zIndex: 200
  },
  button: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderRadius: 10,
    overflow: "hidden",
    marginTop: 15,
    backgroundColor: "#000000",
    padding: 10,
    color: "#fff",
    fontFamily: 'Inria Sans',
    fontSize: 14,
    fontStyle: 'normal',
    textAlign: "center",
  }
})

export default withSafeArea(SignUpScreen)
