import { useMutation, gql } from '@apollo/client';

export const TOKEN_CREATE = gql`
  mutation tokenCreate($email: String!, $password: String!) {
    tokenCreate(email: $email, password: $password) {
      token
      refreshToken
      csrfToken
      errors {
        field
        message
      }
    }
  }
`;

export default function useTokenCreate() {
  return useMutation(TOKEN_CREATE);
}