import { useMutation, gql } from '@apollo/client';
import { USER_FRAGMENT, ACCOUNT_ERROR_FRAGMENT } from '../fragments';

const UPDATE_USER_ADDRESS_MUTATION = gql`
  mutation UpdateUserAddress($input: AddressInput!, $id: ID!) {
    accountAddressUpdate(input: $input, id: $id) {
      errors: accountErrors {
        ...AccountError
        __typename
      }
      user {
        ...User
        __typename
      }
      __typename
    }
  }
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
`;

const CREATE_USER_ADDRESS_MUTATION = gql`
  mutation CreateUserAddress($input: AddressInput!) {
    accountAddressCreate(input: $input) {
      errors: accountErrors {
        ...AccountError
        __typename
      }
      user {
        ...User
        __typename
      }
      __typename
    }
  }
  ${ACCOUNT_ERROR_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const useUserAddressUpdate = () => useMutation(UPDATE_USER_ADDRESS_MUTATION);
export const useUserAddressCreate = () => useMutation(CREATE_USER_ADDRESS_MUTATION);