import { useTabletOrDesktop } from "@/hooks/useMedia"
import { StyleSheet, View } from "react-native"
import Loading from "../Loading/Loading"

interface FallbackDesktopMobileLayoutProps {
  children: React.ReactNode
}
const FallbackDesktopMobileLayout: React.FC<FallbackDesktopMobileLayoutProps> = ({ children }) => {
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  if (!ready) return <Loading />
  if (!isTabletOrDesktop) return <>{children}</>
  return (
    <View style={styles.container}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 390,
    marginHorizontal: 'auto'
  }
})

export default FallbackDesktopMobileLayout