import React from 'react'
import { Ionicons } from '@expo/vector-icons';
import { StyleSheet, View } from 'react-native'

interface BackProps {
  navigation: any,
  size?: number
}
function Back({ navigation, size = 40 }: BackProps) {
  // if (!navigation.canGoBack()) return null
  return (
    <View style={styles.box}>
      <Ionicons name="chevron-back" size={size} color="black" onPress={() => {
        navigation.canGoBack() ? navigation.goBack() : navigation.replace('home')
      }} />
    </View>
  )
}

const styles = StyleSheet.create({
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start'
  }
})

export default Back