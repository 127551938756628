
import { useMutation, gql } from '@apollo/client';


export const RefreshToken_GQL = gql`
  mutation RefreshToken($csrfToken: String!, $refreshToken: String!) {
    tokenRefresh(
      csrfToken: $csrfToken
      refreshToken: $refreshToken
    ) {
      token
    }
  }
`;

export default function useRefreshToken() {
  return useMutation(RefreshToken_GQL)
}