import { fontFamilyJostRegular } from "@/utils/font"
import { Button, ButtonProps } from "@rneui/themed"
import { StyleSheet } from "react-native"

const OperaButton: React.FC<ButtonProps> = ({ titleStyle, buttonStyle, containerStyle, ...rest }) => {
  return (
    <Button
      titleStyle={{
        ...styles.titleStyle,
        ...(titleStyle || {} as any)
      }}
      buttonStyle={{
        ...styles.buttonStyle,
        ...(buttonStyle || {} as any)
      }}
      containerStyle={{
        ...styles.containerStyle,
        ...(containerStyle || {} as any)
      }}
      {...rest}
    />
  )
}

const styles = StyleSheet.create({
  buttonStyle: {
    backgroundColor: 'black',
    borderRadius: 8,
    paddingVertical: 10
  },
  containerStyle: {
    width: '100%',
  },
  titleStyle: {
    fontSize: 15,
    fontFamily: fontFamilyJostRegular
  }
})

export default OperaButton