import Title from "@/components/Title"
import ViewPadding from "@/components/ViewPadding"
import useCheckoutToken from "@/hooks/store/useCheckoutToken"
import { useUser } from "@/hooks/useUser"
import { useEffect, useState } from "react"
import { StyleSheet, View, Text, Alert } from 'react-native';
import OperaButton from "../../../../components/Button"
import AddressDisplay from "../AddressDisplay"
// import SavedAddressSelectionList from "./SavedAddressSelectionList"
import AddressForm from "../AddressForm"
import useCheckoutShippingAddressUpdateMutation from "@/saleor/api/useCheckoutShippingAddressUpdateMutation"
import { AddressFormData } from "@/types"
import { localeToEnum, notNullable } from "@/utils"
import { DEFAULT_LOCALE } from "@/constants"
import JostText from "@/components/Text/JostText"

const ShippingAddressView = () => {
  //  const { authenticated } = useUser();
  const { checkout } = useCheckoutToken()
  const [editing, setEditing] = useState(!checkout?.shippingAddress);
  const [syncing, setSyncing] = useState(false);
  const [error, setError] = useState('')
  const [shippingAddressUpdateMutation] = useCheckoutShippingAddressUpdateMutation()

  useEffect(() => {
    setEditing(!checkout?.shippingAddress)
  }, [checkout?.shippingAddress])

  const updateMutation = async (formData: AddressFormData) => {
    const { data } = await shippingAddressUpdateMutation({
      variables: {
        address: {
          ...formData,
        },
        token: checkout?.token,
        locale: localeToEnum(DEFAULT_LOCALE),
      },
    });
    return data?.checkoutShippingAddressUpdate?.errors.filter(notNullable) || [];
  };

  const onSameAsBilling = async () => {
    try {
      setSyncing(true)
      const billingAddress = checkout?.billingAddress
      if (!billingAddress) {
        return;
      }
      const { data } = await shippingAddressUpdateMutation({
        variables: {
          address: {
            firstName: billingAddress.firstName || "",
            lastName: billingAddress.lastName || "",
            phone: billingAddress.phone || "",
            country: (billingAddress.country.code) || "US",
            streetAddress1: billingAddress.streetAddress1 || "",
            city: billingAddress.city || "",
            postalCode: billingAddress.postalCode || "",
            countryArea: billingAddress.countryArea || ""
          },
          token: checkout.token,
          locale: localeToEnum(DEFAULT_LOCALE),
        },
      });
      const errors = data?.checkoutShippingAddressUpdate?.errors
      if (errors.length) {
        const { field, message } = errors[0]
        setError(`${field}: ${message}`)
        return;
      }
      // Successfully updated the shipping address
      setEditing(false);
    } catch (error) {
      console.log(error)
    } finally {
      setSyncing(false)
    }
  };

  return (
    <ViewPadding style={styles.container}>
      <Title size={16} label="Shipping Address" />
      {error && <JostText style={{ color: 'red' }}>{error}</JostText>}
      {
        editing ? (
          <>
            {/* authenticated && <SavedAddressSelectionList />*/}
            <OperaButton
              title="Use the same address as billing"
              onPress={onSameAsBilling}
              containerStyle={{ marginVertical: 10 }}
              loading={syncing}
            />
            <AddressForm
              existingAddressData={checkout?.shippingAddress || undefined}
              updateAddressMutation={updateMutation}
              onSuccessUpdated={() => setEditing(false)}
            />
          </>
        ) : (
          <View>
            {!!checkout?.shippingAddress && <AddressDisplay address={checkout.shippingAddress} />}
            <OperaButton
              style={styles.operaBtn}
              containerStyle={{ marginVertical: 10 }}
              onPress={() => setEditing(true)}
              title="Change"
            />
          </View>
        )
      }
    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {},
  operaBtn: {
    marginBottom: 15
  }
})

export default ShippingAddressView