import FlexLayout from "@/components/Flex";
import Space from "@/components/Space";
import JostText from "@/components/Text/JostText";
import ViewPadding from "@/components/ViewPadding";
import { StyleSheet, Text } from "react-native";

interface StoryInfoProps {
  content: string
}

const StoryInfo: React.FC<StoryInfoProps> = ({
  content
}) => {
  return (
    <ViewPadding>
      <FlexLayout justify="start" items="stretch" style={styles.container}>
        <JostText key="title" style={styles.title}>Story</JostText>
        <Space size={32} />
        <JostText key="content" style={styles.content}>
          {content}
        </JostText>
      </FlexLayout>
    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {},
  title: {
    fontSize: 32,
    fontWeight: '600'
  },
  content: {
    lineHeight: 30
  }
})

export default StoryInfo;