
import { useMutation, gql } from '@apollo/client';

export const GQL = gql`
  mutation CheckouLineDeleteMutation($id: ID!, $linesIds: [ID!]!) {
    checkoutLinesDelete(
      id: $id
      linesIds: $linesIds
    ) {
      checkout {
        lines {
          id
          variant {
            id
          }
          quantity
        }
      }
      errors {
        field
        message
      }
    }
  }
`

export default function useCheckouLineDeleteMutation() {
  return useMutation(GQL)
}