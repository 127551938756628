

import { StyleSheet, View, ActivityIndicator, FlatList, RefreshControl, Text, ScrollView } from "react-native";
import { responsiveHeight } from "react-native-responsive-dimensions";
import { SafeAreaView } from "react-native-safe-area-context";
import ProductListItem from "@/components/Product/ProductListItem";
import Categorys from "@/components/Categorys";
import HeaderLayout from "@/components/Desktop/HeaderLayout";
import Empty from "@/components/Empty";
import { DEFAULT_CHANNEL, LAYOUT_MAX_WIDTH } from "@/constants";
import PageHeader from "@/components/PageHeader/indext";
import FlexLayout from "@/components/Flex";
import useGalleryPage from "./useGalleryPage";
import { ProductItem } from "@/types";
import Space from "@/components/Space";

const PAGE_SIZE = 10

const Gallery: React.FC<any> = ({ navigation }) => {
  const {
    called,
    isTabletOrDesktop,
    ready,
    loadingProducts,
    products,
    getProductsByCategory,
    currentCategory,
    productsData,
    categories
  } = useGalleryPage()

  const renderFooter = () => {
    return <>
      {loadingProducts && <ActivityIndicator color="#000" style={{ marginTop: 20 }} />}
      <FlexLayout direction="row" items="start" style={{ height: isTabletOrDesktop ? 350 : 150, padding: 20 }}>
        {!loadingProducts && productsData?.products?.pageInfo?.hasNextPage === false && <Text style={{ color: '#666' }}>No more products</Text>}
      </FlexLayout>
    </>
  }

  const handleLoadMore = () => {
    if (!loadingProducts) {
      const { hasNextPage, endCursor } = productsData?.products?.pageInfo || {}
      hasNextPage && endCursor && getProductsByCategory({
        variables: {
          categoryIds: [currentCategory],
          channel: DEFAULT_CHANNEL,
          first: PAGE_SIZE,
          after: endCursor
        }
      })
    }
  }

  const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  const HeaderView = <View style={{ paddingTop: isTabletOrDesktop ? 0 : 20 }}>
    {/** Profile icon */}
    <PageHeader title="Gallery" />
    {!isTabletOrDesktop && <Space size={10} />}
    {/** Header */}
    {!!categories?.length ? <HeaderLayout>
      <Categorys
        style={{
          marginTop: 0,
          marginLeft: isTabletOrDesktop ? -20 : 0,
          marginBottom: 15
        }}
      />
    </HeaderLayout> : null}
  </View>

  const renderContent = () => {
    if (isTabletOrDesktop) {
      return (
        <>
          <ScrollView
            contentContainerStyle={{
              flexDirection: 'row',
              maxWidth: LAYOUT_MAX_WIDTH - 100,
              width: '100%',
              marginHorizontal: 'auto',
              flexWrap: 'wrap',
              paddingBottom: 80,
            }}
            style={{
              ...styles.content,
              paddingBottom: 80,
              paddingTop: 20,
              marginTop: 0
            }}
            scrollEventThrottle={200}
            onScroll={({ nativeEvent }) => {
              if (isCloseToBottom(nativeEvent)) {
                handleLoadMore()
              }
            }}
          >
            {!products.length && called && !loadingProducts && <Empty description="No product found" />}
            {products.map((product: ProductItem) => (
              <ProductListItem onPress={() => {
                navigation.push("product_detail", {
                  productId: product.id
                })
              }} key={product.id} data={product} />
            ))}
          </ScrollView>
        </>
      )
    }

    return (
      <>
        {!products.length && called && !loadingProducts && <Empty description="No product found" />}
        {!!products.length && called && <FlatList
          style={{
            ...styles.content,
          }}
          data={products}
          keyExtractor={(item, index) => item.id + index.toString()}
          onEndReachedThreshold={0.4}
          onEndReached={handleLoadMore}
          ListFooterComponent={renderFooter}
          ListHeaderComponent={!isTabletOrDesktop && HeaderView}
          refreshControl={
            <RefreshControl
              refreshing={loadingProducts}
              onRefresh={() => {
                if (currentCategory) {
                  getProductsByCategory({
                    variables: {
                      categoryIds: [currentCategory],
                      channel: DEFAULT_CHANNEL,
                      first: PAGE_SIZE
                    }
                  })
                }
              }}
            />
          }
          ItemSeparatorComponent={() => (
            <View
              style={{
                height: 15,
                width: '100%',
              }}
            />
          )}
          renderItem={({ item }) => {
            return (
              <ProductListItem onPress={() => {
                navigation.push("product_detail", {
                  productId: item.id
                })
              }} key={item.id} data={item} />
            )
          }}
        />}
      </>
    )
  }

  if (!ready) return null

  return (
    <SafeAreaView style={{
      ...styles.container,
      paddingVertical: isTabletOrDesktop ? 10 : 0,
      paddingHorizontal: 0,
    }}>
      {isTabletOrDesktop && HeaderView}
      {renderContent()}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    minHeight: '100%'
  },
  content: {
    height: responsiveHeight(100),
    backgroundColor: "#F2F0E5",
  },
  profileContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingHorizontal: 20
  },
});

export default Gallery