import { SafeAreaView } from "react-native-safe-area-context";

export const withSafeArea = (Component: React.FunctionComponent<any>) => {
  return (props: Record<string, any>) => {
    return (
      <SafeAreaView>
        <Component {...props} />
      </SafeAreaView>
    )
  }
} 