
import { SafeAreaView } from "react-native-safe-area-context";
// import Stripe from "stripe";
import { Text, View, ScrollView, StyleSheet } from "react-native";
import { useEffect, useState } from "react";
import useCheckoutToken from "@/hooks/store/useCheckoutToken";
import { AntDesign } from '@expo/vector-icons';
import usePaymentGatewayInitializeMutation from "@/saleor/api/usePaymentGatewayInitializeMutation";
import { STRIPE_GATEWAY } from "@/constants";
import useCheckoutCompleteMutation from "@/saleor/api/useCheckoutCompleteMutation";
import PageNavLayout from "@/components/PageNavLayout";
import Back from "@/components/Back";
import FlexLayout from "@/components/Flex";
import OperaButton from "@/components/Button";
import { isWeb } from "@/utils/platform";
import Loading from "@/components/Loading/Loading";
import FallbackDesktopMobileLayout from "@/components/FallbackDesktopMobileLayout";
import { Entypo, FontAwesome } from '@expo/vector-icons';
import JostText from "@/components/Text/JostText";

const PaymentScreen: React.FC<any> = ({ navigation, route }) => {
  const [loading, setLoading] = useState(false)
  const [orderToken, setOrderToken] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [processingMsg, setProcessingMsg] = useState('')
  const { payment_intent, payment_intent_client_secret, redirect_status } = route.params
  const { checkout, updateCheckout, updateCheckoutToken } = useCheckoutToken()
  const [paymentGatewayInitializeMutation] = usePaymentGatewayInitializeMutation()
  const [checkoutCompleteMutation] = useCheckoutCompleteMutation()
  const checkoutId = checkout?.id

  useEffect(() => {
    if (!payment_intent || !payment_intent_client_secret) {
      navigation.replace('gallery')
      return
    }

    async function init() {
      try {
        setLoading(true)
        const paymentGateway = await paymentGatewayInitializeMutation({
          variables: {
            checkoutId: checkout?.id,
          }
        })
        const { gatewayConfigs, errors } = paymentGateway.data.paymentGatewayInitialize
        const stripeData = gatewayConfigs?.find(
          (gateway: any) => gateway.id === STRIPE_GATEWAY,
        )?.data as undefined | { publishableKey: string };
        // console.log('paymentGateway', paymentGateway, 'stripeData', stripeData)
        if (
          !stripeData?.publishableKey ||
          errors.length ||
          gatewayConfigs?.some((gateway: any) => gateway.errors?.length)
        ) {
          setErrorMsg('Failed to initialize Stripe transaction')
          return
        }
        if (!isWeb) return
        const Stripe = await (await import('stripe')).default
        console.log('Stripe======', Stripe)
        const stripe = new Stripe(stripeData.publishableKey, { apiVersion: "2023-08-16" });
        // console.log('payment_intent', payment_intent, 'payment_intent_client_secret', payment_intent_client_secret)
        const paymentIntent = await stripe.paymentIntents.retrieve(payment_intent, {
          client_secret: payment_intent_client_secret,
        });
        // console.log('paymentIntent', paymentIntent)
        if (paymentIntent.status === "processing") {
          setProcessingMsg('Payment processing. We will update you when payment is received')
          return
        }

        if (paymentIntent.status === "requires_payment_method") {
          navigation.replace('gallery')
        }

        if (paymentIntent.status === "succeeded") {
          const orderResult: any = await checkoutCompleteMutation({
            variables: {
              checkoutId,
            }
          })
          // console.log('orderResult', orderResult)
          const { errors, order } = orderResult.data.checkoutComplete
          if (
            errors.length ||
            order?.errors.length
          ) {
            if (errors.some((o: any) => o.code === "SHIPPING_METHOD_NOT_SET")) {
              setErrorMsg('Shipping method not set')
            } else {
              if (errors.length) {
                setErrorMsg(errors[0].message)
              } else {
                setErrorMsg('Failed to finalize order')
              }
            }
            return
          }
          updateCheckoutToken('')
          updateCheckout(null)
          setOrderToken(order.token)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    checkoutId && init()
  }, [checkoutId])

  return (
    <FallbackDesktopMobileLayout>
      <ScrollView style={styles.container}>
        <SafeAreaView>
          {/** Back icon */}
          <PageNavLayout>
            <Back navigation={navigation} />
          </PageNavLayout>

          {orderToken && <FlexLayout style={{ marginTop: 60 }}>
            <AntDesign name="checkcircle" size={100} color="black" />
            <JostText>The payment was successful.</JostText>

            <FlexLayout style={{ paddingHorizontal: 100, marginTop: 30 }}>
              <OperaButton title="Check Order" icon={<FontAwesome name="reorder" style={{ marginRight: 5 }} size={20} color="white" />} containerStyle={{ marginVertical: 10 }} onPress={() => {
                navigation.push('order', {
                  orderToken: orderToken
                })
              }} />
              <OperaButton title="Back Home" icon={<Entypo name="home" style={{ marginRight: 5 }} size={20} color="white" />} containerStyle={{ marginVertical: 10 }} onPress={() => {
                navigation.replace('home')
              }} />
            </FlexLayout>

          </FlexLayout>}

          {errorMsg && <View style={styles.msgBox}>
            <JostText style={styles.msg}>{errorMsg}</JostText>
          </View>}

          {loading && <Loading />}

          {processingMsg && <View style={styles.msgBox}><JostText style={styles.msg}>{processingMsg}</JostText></View>}
        </SafeAreaView>
      </ScrollView>
    </FallbackDesktopMobileLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative'
  },
  msgBox: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    fontSize: 18,
  },
  msg: {}
})

export default PaymentScreen
