import FlexLayout from '@/components/Flex'
import JostText from '@/components/Text/JostText'
import { Art } from '@/types'
import { Avatar } from '@rneui/themed'
import React from 'react'
import { Text, StyleSheet, TouchableOpacity, TouchableOpacityProps } from 'react-native'

interface ArtistAvatarItemProps {
  data: Art
  style?: Record<string, any>
  onPress?: TouchableOpacityProps['onPress']
}

function ArtistAvatarItem({
  data,
  onPress,
  style
}: ArtistAvatarItemProps) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        ...styles.container,
        ...(style || {}),
      }}
    >
      <FlexLayout style={styles.content}>
        <FlexLayout style={styles.avatarOuterBox}>
          <FlexLayout style={styles.avatarInnerBox}>
            <Avatar
              rounded
              size={58}
              source={{ uri: data.avatar.url }}
              containerStyle={styles.avatarContainer}
            />
          </FlexLayout>
        </FlexLayout>
        <JostText numberOfLines={1} ellipsizeMode="tail" style={styles.name}>{data.name}</JostText>
      </FlexLayout>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {},
  content: {},
  label: {},
  avatarOuterBox: {
    width: 72,
    height: 72,
    borderWidth: 2.5,
    borderColor: '#EEE29F',
    borderRadius: 36,
    overflow: 'hidden',
    marginBottom: 5
  },
  avatarInnerBox: {
    backgroundColor: 'white',
    padding: 5,
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  avatarContainer: {},
  name: {
    color: 'black',
    width: 70
  }
})

export default ArtistAvatarItem