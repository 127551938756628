import { create } from 'zustand'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CHECKOUT_TOKEN } from '@/constants';
import { Checkout } from '@/types';

interface IAppStore {
  checkoutToken: string
  updateCheckoutToken: (v?: any) => void

  checkout: Checkout | null
  updateCheckout: (v?: any) => void
}

const useCheckoutToken = create<IAppStore>((set, get) => ({
  checkoutToken: '',
  updateCheckoutToken: (v?: any) => set(() => {
    AsyncStorage.setItem(CHECKOUT_TOKEN, v)
    return {
      checkoutToken: v ?? get().checkoutToken
    }
  }),

  checkout: null,
  updateCheckout: (v?: any) => set(() => {
    return {
      checkout: v ?? get().checkout
    }
  }),
}))

export default useCheckoutToken;