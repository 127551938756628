import useImageSize from "@/hooks/useImageSize"
import { Image, StyleSheet, TouchableOpacity } from 'react-native';
interface PosterItemProps {
  info: any
  height: number
  onPress: () => void
}

const PosterItem: React.FC<PosterItemProps> = ({
  info,
  height,
  onPress
}) => {
  const { rate, nativeHeight } = useImageSize(info.url)
  const realHeight = Math.min(nativeHeight, height)

  return (
    <TouchableOpacity onPress={onPress} style={{ ...styles.swiperItemImage }}>
      <Image style={{
        width: realHeight * rate,
        height: realHeight,
        marginHorizontal: 'auto'
      }} source={{ uri: info.url }} resizeMode='cover' />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  swiperItemImage: {
    flex: 1,
    height: '100%'
  },
})

export default PosterItem