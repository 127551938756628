import { DEFAULT_CHANNEL } from "@/constants";
import { useRequestPasswordResetMutation } from "@/saleor/api/useRequestPasswordResetMutation";
import { useEffect, useState } from "react";

const ERROR_MSG = 'please enter your valid email'

interface PasswordResetFormData { 
  email: string;
  shouldAbort: () => boolean;
  setErrorMsg: React.Dispatch<string>
}

export const usePasswordResetRequest = ({ email, shouldAbort, setErrorMsg }: PasswordResetFormData) => {

  const [requestPasswordResetMutation] = useRequestPasswordResetMutation();
  const [processing, setProcessing] = useState(false)

  const [passwordResetSent, setPasswordResetSent] = useState(false);

  useEffect(() => {
    setPasswordResetSent(false);
  }, [email]);

  return {
    onPasswordResetRequest: async () => {
      if (shouldAbort()) {
        setErrorMsg(ERROR_MSG)
        return
      }
      try {
        if (processing) return
        setProcessing(true)
        const requestResetResult = await requestPasswordResetMutation({
          variables: {
            email,
            channel: DEFAULT_CHANNEL,
            redirectUrl: `${window.location.origin}/reset_password`,
          }
        })
        const errors = requestResetResult.data?.requestPasswordReset?.errors
        if (errors?.length) {
          setErrorMsg(errors[0]?.message)
        }
        setPasswordResetSent(true)
      } catch (error) {
        console.log(error)
      } finally {
        setProcessing(false)
      }
    },
    passwordResetSent,
  };
};
