import React, { useMemo } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { AntDesign } from '@expo/vector-icons';
import useProductDetail from '@/hooks/store/useProductDetail';
import { formatAsMoney, getPriceFromProduct } from '@/utils';
import { Button } from '@rneui/themed'
import { useTabletOrDesktop } from '@/hooks/useMedia';
import InriaSansText from '@/components/Text/InriaSansText';

interface FixedGoBuyViewProps {
  onPress: () => void,
  loading: boolean
}

function FixedGoBuyView({ onPress, loading }: FixedGoBuyViewProps) {
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  const { info } = useProductDetail()
  const displayPrice = useMemo(() => {
    const { amount, currency } = getPriceFromProduct(info)
    return formatAsMoney(amount, currency)
  }, [info])

  if (!ready) return null

  return (
    <View style={{
      ...styles.container,
      ...(!isTabletOrDesktop ? styles.mobileContainer : styles.desktopContainer)
    }}>
      <View style={styles.leftBox}>
        <InriaSansText style={styles.leftTopLabel}>{displayPrice}</InriaSansText>
        <InriaSansText style={styles.leftBottomLabel}>Tax & Shipping Calculated at check out</InriaSansText>
      </View>
      <Button loading={loading} buttonStyle={styles.buyButton} containerStyle={styles.buyButtonContent} onPress={onPress}>
        <InriaSansText style={styles.rightLabel}>Buy</InriaSansText>
        <AntDesign name="arrowright" size={24} color="white" />
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F2F0E5',
    overflow: 'hidden',
    borderRadius: 90,
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.01)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingVertical: 16,
    height: 92,
  },
  desktopContainer: {
    width: 358,
  },
  mobileContainer: {
    position: 'absolute',
    bottom: 30,
    left: 15,
    right: 15,
  },
  leftBox: {
    flex: 1,
    marginRight: 10
  },
  leftTopLabel: {
    fontSize: 32,
  },
  leftBottomLabel: {
    fontSize: 12,
    color: '#453B55',
    position: 'relative',
    left: 4,
    top: -2
  },
  buyButton: {
    width: 115,
    height: 60,
    overflow: 'hidden',
    borderRadius: 60,
    backgroundColor: 'black',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buyButtonContent: {},
  rightLabel: {
    fontSize: 20,
    color: 'white',
    marginRight: 12
  },
})

export default FixedGoBuyView