import FlexLayout from "@/components/Flex"
import useProductDetail from "@/hooks/store/useProductDetail"
import { StyleSheet, Text, View } from "react-native"
import FeatureRow from "../FeatureRow"
import Space from "@/components/Space"
import { Button } from '@rneui/themed'
import { AntDesign } from '@expo/vector-icons';
import { useMemo } from "react"
import { formatAsMoney, getPriceFromProduct } from "@/utils"
import MoreArtwork from "../MoreArtwork"
import JostText from "@/components/Text/JostText"
import InriaSansText from "@/components/Text/InriaSansText"

interface DetailsProps {
  onPressBuyBtn: () => void,
  handleingBuy: boolean
}

const Details: React.FC<DetailsProps> = ({
  onPressBuyBtn,
  handleingBuy
}) => {
  const { info } = useProductDetail()
  const attributes = info?.attributes || []
  const displayPrice = useMemo(() => {
    const { amount, currency } = getPriceFromProduct(info)
    return formatAsMoney(amount, currency)
  }, [info])

  return (
    <FlexLayout style={styles.box} direction="row" justify="between" items="start">
      <View style={styles.leftBox}>
        <JostText style={styles.detailTitile}>Details</JostText>
        <Space size={20} />
        {
          attributes.map((attribute: any) => (
            <FeatureRow key={attribute.attribute.id} label={attribute.attribute.name} value={attribute.values.length ? attribute.values[0].name : ''} />
          ))
        }
      </View>
      <FlexLayout style={styles.rightBox}>
        <Space size={20} />

        {/** buy */}
        <FlexLayout style={styles.butBtnBox} direction="row" justify="between" items="center">
          <View>
            <InriaSansText style={styles.priceLabel}>{displayPrice}</InriaSansText>
          </View>
          <Button loading={handleingBuy} buttonStyle={styles.buyButton} onPress={onPressBuyBtn}>
            <InriaSansText style={styles.rightLabel}>Buy</InriaSansText>
            <AntDesign name="arrowright" size={24} color="white" />
          </Button>
        </FlexLayout>

        <Space size={15} />
        <FlexLayout>
          <InriaSansText>Tax & Shipping Calculated at check out</InriaSansText>
        </FlexLayout>

        <Space size={70} />
        {/** More art work */}
        <MoreArtwork />

      </FlexLayout>
    </FlexLayout>
  )
}

const styles = StyleSheet.create({
  box: {},
  detailTitile: {
    fontSize: 22,
    color: '#877A99'
  },
  leftBox: {
    flexBasis: 450
  },
  rightBox: {
    flex: 1
  },
  butBtnBox: {
    width: 390,
    height: 120,
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.5)',
    backgroundColor: '#F2F0E5',
    borderRadius: 195,
    paddingHorizontal: 30
  },
  buyButton: {
    width: 115,
    height: 60,
    overflow: 'hidden',
    borderRadius: 60,
    backgroundColor: 'black',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rightLabel: {
    fontSize: 20,
    color: 'white',
    marginRight: 12
  },
  priceLabel: {
    fontSize: 32
  }
})

export default Details