import { create } from 'zustand'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CSRF_TOKEN, LOGIN_TOKEN, REFRESH_TOKEN } from '@/constants';
import { Checkout } from '@/types';

interface IAppStore {
  token: string
  updateToken: (v?: any) => void

  refreshToken: Checkout | null
  updateRefreshToken: (v?: any) => void

  csrfToken: Checkout | null
  updateCsrfToken: (v?: any) => void
}

const useAuth = create<IAppStore>((set, get) => ({
  token: '',
  updateToken: (v?: any) => set(() => {
    v ? AsyncStorage.setItem(LOGIN_TOKEN, v) : AsyncStorage.removeItem(LOGIN_TOKEN)
    return {
      token: v ?? get().token
    }
  }),

  refreshToken: null,
  updateRefreshToken: (v?: any) => set(() => {
    v ? AsyncStorage.setItem(REFRESH_TOKEN, v) : AsyncStorage.removeItem(REFRESH_TOKEN)
    return {
      refreshToken: v ?? get().refreshToken
    }
  }),

  csrfToken: null,
  updateCsrfToken: (v?: any) => set(() => {
    v ? AsyncStorage.setItem(CSRF_TOKEN, v) : AsyncStorage.removeItem(CSRF_TOKEN)
    return {
      csrfToken: v ?? get().csrfToken
    }
  }),
}))

export default useAuth;