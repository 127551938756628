import { useQuery, gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '../fragments';

export const QUERY = gql`
  query User {
    user:me {
      id
      firstName
      lastName
      email
      avatar {
        url
      }
      dateJoined
      addresses {
        ...Address
      }
      defaultBillingAddress {
        ...Address
      } 
      defaultShippingAddress {
        ...Address
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export default function useUserQuery() {
  return useQuery(QUERY, { fetchPolicy: "cache-and-network" });
}