
import Back from "@/components/Back";
import { Avatar } from '@rneui/themed';
import { useUser } from "@/hooks/useUser";
import { useEffect, useState } from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import { useAccountUpdate } from "@/saleor/api/useAccountUpdate";
import uploadAvatar from "@/saleor/api/useAvatarUpdate";
import { Text, ScrollView, TextInput, StyleSheet, View } from "react-native";
import { getSimpleFullName, base64ToBlob } from "@/utils";
import OperaButton from "@/components/Button";
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';

import { responsiveHeight } from "react-native-responsive-dimensions";
import FallbackDesktopMobileLayout from "@/components/FallbackDesktopMobileLayout";
import JostText from "@/components/Text/JostText";
import NotificationDialog from "@/components/Dialog/Notification";

const ProfileScreen: React.FC<any> = ({ navigation }) => {
  const [processing, setProcessing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMsgOfPhotoPermisstion, setErrorMsgOfPhotoPermisstion] = useState('');
  const [avatar, setAvatar] = useState<string | null>(null);

  const { user } = useUser();
  const [AccountUpdate] = useAccountUpdate();

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setAvatar(user.avatar?.url || "");
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      if (Constants?.platform?.ios) {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          setErrorMsgOfPhotoPermisstion('Sorry, we need access to your album permissions to select photos!')
        }
      }
    })();
  }, []);

  const updateAvatar = (base64Data: any) => {
    const fileData = base64Data.split(',')[1]; // 去除数据URI中的前缀部分
    const fileType = base64Data.split(';')[0].split(':')[1]; // 获取文件类型
    const blob = base64ToBlob(fileData, fileType);
    try {
      const transformedFileObj = new File([blob], "userAvatar.jpg", { type: fileType });
      uploadAvatar(transformedFileObj)
    } catch (error) {
      console.log(error);
    }
  }

  const pickImage = async (): Promise<void> => {
    const result: ImagePicker.ImagePickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    });
    if (!result.canceled) {
      setAvatar(result.assets[0].uri);
      updateAvatar(result.assets[0].uri);
    }
  };

  const submit = async () => {
    try {
      setProcessing(true)
      await AccountUpdate({
        variables: {
          input: {
            firstName, lastName
          },
        },
      })
      navigation.goBack();
    } catch (error) {
      console.log(error)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <FallbackDesktopMobileLayout>
      <View style={styles.container}>
        <View style={{
          flexDirection: 'row', alignItems: "center",
          borderBottomWidth: 1, borderBottomColor: 'rgba(0,0,0,0.1)', paddingVertical: 10
        }}>
          <Back navigation={navigation} />
          <JostText style={{ fontSize: 20, fontWeight: "bold", marginLeft: '26%' }}>Personal Info</JostText>
        </View>
        <ScrollView style={styles.content}>
          <View style={styles.headers}>
            <FontAwesome5 style={styles.icons} name="edit" size={14} color="black" onPress={pickImage} />
            <Avatar
              size={120}
              rounded
              containerStyle={{ backgroundColor: '#BDBDBD' }}
              title={getSimpleFullName(user?.firstName || '', user?.lastName || '')}
              titleStyle={{ color: 'black' }}
              source={{ uri: avatar || '' }}
            />
          </View>

          <JostText style={styles.title}>FIRST NAME</JostText>
          <TextInput
            style={styles.textInput}
            onChangeText={text => setFirstName(text)}
            value={firstName}
            placeholder="Edit first name"
            placeholderTextColor="rgba(0,0,0,0.1)"
          />

          <JostText style={styles.title}>LAST NAME</JostText>
          <TextInput
            style={styles.textInput}
            onChangeText={text => setLastName(text)}
            value={lastName}
            placeholder="Edit first name"
            placeholderTextColor="rgba(0,0,0,0.1)"
          />

          <JostText style={styles.title}>EMAIL</JostText>
          <TextInput
            style={styles.textInput}
            onChangeText={text => setEmail(text)}
            value={email}
            placeholder="Edit Email"
            placeholderTextColor="rgba(0,0,0,0.1)"
          />

        </ScrollView>
        <OperaButton containerStyle={styles.saveBtn} loading={processing} title="SAVE" onPress={submit} />
      </View>
      <NotificationDialog
        visible={!!errorMsgOfPhotoPermisstion}
        content={errorMsgOfPhotoPermisstion}
        onBackdropPress={() => setErrorMsgOfPhotoPermisstion('')}
        onCancel={() => setErrorMsgOfPhotoPermisstion('')}
      />
    </FallbackDesktopMobileLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    height: responsiveHeight(100),
  },
  content: {
    marginTop: 20,
    padding: 20
  },
  headers: {
    marginBottom: 22
  },
  icons: {
    position: "absolute",
    left: 120,
  },
  saveBtn: {
    position: 'absolute',
    bottom: 40,
    left: 20,
    right: 20,
    width: 'auto'
  },
  userName: {
    color: '#000',
    fontSize: 32,
    margin: 20,
    fontStyle: 'normal',
    fontFamily: 'Inria Serif'
  },
  eamil: {
    position: 'relative',
    paddingLeft: 20,
    marginTop: -10,
  },
  title: {
    fontSize: 14,
    fontWeight: '700',
    marginBottom: 10,
  },
  textInput: {
    padding: 10,
    borderWidth: 0.5,
    borderRadius: 10,
    borderColor: 'gray',
    marginBottom: 20
  }
})

export default ProfileScreen
