import FlexLayout from "@/components/Flex"
import Space from "@/components/Space"
import InriaSansText from "@/components/Text/InriaSansText"
import JostText from "@/components/Text/JostText"
import { ProductItem } from "@/types"
import { extractTextFromBlocks, formatAsMoney, getPriceFromProduct } from "@/utils"
import { useNavigation } from "@react-navigation/native"
import React, { useMemo } from "react"
import { Image, StyleSheet, TouchableOpacity, View } from "react-native"

interface CollectItemProps {
  info: ProductItem
}
const CollectItem: React.FC<CollectItemProps> = ({
  info
}) => {
  const navigation: any = useNavigation()
  const displayPrice = useMemo(() => {
    const { amount, currency } = getPriceFromProduct(info)
    return formatAsMoney(amount, currency)
  }, [info])

  return (
    <TouchableOpacity onPress={() => {
      navigation.push("product_detail", {
        productId: info.id
      })
    }}>
      <FlexLayout items="stretch" style={styles.itemBox}>
        <View style={styles.itemPoster}>
          <Image
            source={{ uri: info?.thumbnail?.url }}
            resizeMode="cover"
            style={styles.poster}
          />
        </View>
        <FlexLayout style={styles.itemInfoBox} justify="start" items="stretch">
          <JostText style={styles.itemName}>{info.name}</JostText>
          <InriaSansText style={styles.itemPrice}>{displayPrice}</InriaSansText>
          <JostText style={styles.itemDesc} numberOfLines={3} ellipsizeMode="tail">{extractTextFromBlocks(info.description)}</JostText>
        </FlexLayout>
      </FlexLayout>
    </TouchableOpacity>
  )
}


interface CollectListProps {
  dataSource: any[]
}

const CollectList: React.FC<CollectListProps> = ({
  dataSource
}) => {
  return (
    <View style={styles.container}>
      {dataSource.map((item, index) => (
        <React.Fragment key={item.name}>
          <CollectItem info={item} />
          <Space size={15} />
        </React.Fragment>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  itemBox: {
    backgroundColor: 'white',
    borderRadius: 10,
    overflow: 'hidden'
  },
  itemPoster: {
    position: 'relative',
    backgroundColor: 'rgba(0,0,0,0.06)',
    minHeight: 100,
    width: '100%'
  },
  poster: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  itemInfoBox: {
    padding: 14,
  },
  itemName: {
    fontSize: 18,
    fontWeight: '500'
  },
  itemPrice: {
    fontSize: 14,
    color: '#79747E',
    marginVertical: 5
  },
  itemDesc: {
    fontSize: 14,
    color: '#453B55'
  },
})

export default CollectList