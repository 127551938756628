import JostText from '@/components/Text/JostText'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

interface FeatureRowProps {
  label: string
  value: string
}
function FeatureRow({ label, value }: FeatureRowProps) {
  return (
    <View style={{
      ...styles.container
    }}>
      <JostText style={styles.label}>{label}</JostText>
      <View style={styles.valueBox}>
        <JostText style={styles.value}>{value}</JostText>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: 15
  },
  label: {
    color: '#877A99',
    fontSize: 16
  },
  valueBox: {
    flexDirection: 'row',
    flexBasis: 200
  },
  value: {
    fontSize: 18,
    color: '#453B55'
  }
})

export default FeatureRow