
import { ScrollView, StyleSheet, Text } from "react-native";
import Back from "@/components/Back";
import { SafeAreaView } from "react-native-safe-area-context";
import EmailView from "./components/EmailView";
import PageNavLayout from "@/components/PageNavLayout";
import { Divider } from '@rneui/themed'
import BillingAddressView from "./components/BillingAddressView";
import useCheckoutToken from "@/hooks/store/useCheckoutToken";
import ShippingAddressView from "./components/ShippingAddressView";
import ShippingMethodView from "./components/ShippingMethodView";
import PaymentView from "./components/PaymentView";
import ProductInfo from "./components/ProductInfo";
import { useUser } from "@/hooks/useUser";
import Loading from "@/components/Loading/Loading";
import FallbackDesktopMobileLayout from "@/components/FallbackDesktopMobileLayout";

const DIVIDER_COLOR = 'rgba(0,0,0,0.1)'

const ChecoutScreen: React.FC<any> = ({ navigation }) => {
  useUser(false)
  const { checkout } = useCheckoutToken()

  return (
    <FallbackDesktopMobileLayout>
      <ScrollView>
        <SafeAreaView>
          <PageNavLayout>
            <Back navigation={navigation} />
          </PageNavLayout>

          {checkout ? <>
            <ProductInfo checkout={checkout} />
            <Divider width={1} color={DIVIDER_COLOR} style={styles.divider} />
            <EmailView />
            <Divider width={1} color={DIVIDER_COLOR} style={styles.divider} />

            <BillingAddressView />
            <Divider width={1} color={DIVIDER_COLOR} style={styles.divider} />

            {checkout?.isShippingRequired && !!checkout?.billingAddress && (
              <>
                <ShippingAddressView />
                <Divider width={1} color={DIVIDER_COLOR} style={styles.divider} />
              </>
            )}

            {checkout?.isShippingRequired && checkout?.shippingAddress && <>
              <ShippingMethodView />
              <Divider width={1} color={DIVIDER_COLOR} style={styles.divider} />
            </>}

            {checkout?.isShippingRequired
              ? checkout?.shippingAddress && checkout?.shippingMethod
                ? <PaymentView />
                : null
              : <PaymentView />}
          </> : <Loading />}

        </SafeAreaView>
      </ScrollView>
    </FallbackDesktopMobileLayout>
  );
};

const styles = StyleSheet.create({
  divider: {
    marginVertical: 10
  }
})

export default ChecoutScreen
