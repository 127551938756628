
import { useMutation, gql } from '@apollo/client';


export const GQL = gql`
  mutation CreateCheckout($email: String, $lines: [CheckoutLineInput!]!, $channel: String!) {
    checkoutCreate(input: { channel: $channel, email: $email, lines: $lines }) {
      checkout {
        id
        token
      }
      errors {
        field
        message
        code
      }
    }
  }
`;

export default function useCheckoutCreateMutation() {
  return useMutation(GQL)
}