import Title from "@/components/Title"
import ViewPadding from "@/components/ViewPadding"
import { STRIPE_GATEWAY } from "@/constants"
import useCheckoutToken from "@/hooks/store/useCheckoutToken"
import { AvailablePaymentGateway } from "@/types"
import { useEffect, useState } from "react"
import { Text, View } from "react-native"
import { StyleSheet } from "react-native"
import { ButtonGroup } from "react-native-elements"
import StripeCreditCardView from "./StripeCreditCardView"

interface PaymentViewProps {

}
const PaymentView: React.FC<PaymentViewProps> = () => {
  const { checkout } = useCheckoutToken()
  const existingGateways = [STRIPE_GATEWAY];
  const [availableGateways, setAvailableGateways] = useState<AvailablePaymentGateway[]>([]);
  const [chosenGateway, setChosenGateway] = useState("");
  // const selectedIndex = typeof chosenGateway !== 'undefined' ? availableGateways.findIndex(item => item.id === chosenGateway) : null

  /*
  const handleSelect = (index: number) => {
    const gateway = availableGateways[index]
    setChosenGateway(gateway.id)
  }
  */

  useEffect(() => {
    setAvailableGateways(
      (checkout?.availablePaymentGateways || []).filter((g) =>
        existingGateways.includes(g.id)
      )
    )
  }, [checkout?.availablePaymentGateways])

  useEffect(() => {
    availableGateways.length && setChosenGateway(availableGateways[0].id)
  }, [availableGateways])

  return (
    <ViewPadding style={styles.container}>
      <Title size={16} label="Payment" />
      {/**
      <View>
        <Text style={styles.label}>Choose payment method</Text>
        <ButtonGroup
          buttons={availableGateways.map(o => o.name)}
          containerStyle={{
            width: '100%',
            marginVertical: 20,
            marginHorizontal: 0
          }}
          selectedButtonStyle={{ backgroundColor: 'black' }}
          buttonStyle={styles.buttonStyle}
          selectedIndex={selectedIndex}
          onPress={handleSelect}
        />
      </View>
       */}
      <View style={styles.cardBox}>
        {chosenGateway === STRIPE_GATEWAY && <StripeCreditCardView />}
      </View>
    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {},
  cardBox: { marginTop: 20 },
  buttonStyle: {
    overflow: 'hidden'
  }
})

export default PaymentView