import { User } from '@/types'
import { create } from 'zustand'

interface IAppStore {
  user: User | null
  updateUser: (v?: User) => void
}

const useUserInfo = create<IAppStore>((set, get) => ({
    user: null,
    updateUser: (v?: User) => set(() => {
      return {
        user: v
      }
    }),
}))

export default useUserInfo;