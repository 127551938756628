
import { STRIPE_GATEWAY } from "@/constants";
import useCheckoutToken from "@/hooks/store/useCheckoutToken";
import useCheckoutTransactionInitialize from "@/saleor/api/useCheckoutTransactionInitialize";
import { isWeb } from "@/utils/platform";
import { useEffect, useState } from "react";
import { StyleSheet, Text } from "react-native"
import StripeWeb from "./StripeWeb";
import { formatAsMoney } from "@/utils";

const StripeCreditCardView: React.FC<{}> = () => {
  const { checkout } = useCheckoutToken()
  const [transactionInitialize] = useCheckoutTransactionInitialize()
  const [clientSecret, setClientSecret] = useState('')
  const [publishableKey, setPublishableKey] = useState('')
  // const totalPrice = checkout?.totalPrice?.gross.amount;
  const payLabel = 'Pay'

  useEffect(() => {
    async function start() {
      try {
        const { data: transaction }: any = await transactionInitialize({
          variables: {
            checkoutId: checkout?.id,
            data: {
              automatic_payment_methods: {
                enabled: true,
              },
            },
          },
        })
        if (transaction?.transactionInitialize?.errors.length) {
          console.log('transaction error', transaction?.transactionInitialize?.errors)
          return
        }
        setClientSecret(transaction?.transactionInitialize?.data?.paymentIntent?.client_secret)
        setPublishableKey(transaction?.transactionInitialize?.data?.publishableKey)
      } catch (error) {
        console.log(error)
      }
    }
    checkout?.id && start()
  }, [checkout])

  return (
    <>
      {(clientSecret && publishableKey)
        ? isWeb
          ? <StripeWeb
            clientSecret={clientSecret}
            publishableKey={publishableKey}
            payLabel={payLabel}
            returnUrl={`${window.location.origin}/payment`}
          />
          : <Text>IOS/Android</Text> : null}
    </>
  )
}

const styles = StyleSheet.create({
  container: {}
})

export default StripeCreditCardView