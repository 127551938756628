import { Input } from "@rneui/themed"
import { Dispatch, useState } from "react"
import { StyleSheet, View, Text } from "react-native"
import OperaButton from "../../../components/Button"
import { AddressFormData } from "@/types"
import CountryPicker from "@/components/CountryPicker"
import JostText from "@/components/Text/JostText"

interface AddressFormProps {
  existingAddressData: AddressFormData
  updateAddressMutation: (address: AddressFormData) => Promise<any[]>;
  onSuccessUpdated?: () => void
}

const AddressForm: React.FC<AddressFormProps> = ({
  existingAddressData,
  updateAddressMutation,
  onSuccessUpdated
}) => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState({
    value: existingAddressData?.phone || '',
    error: ''
  });
  const [firstName, setFirstName] = useState({
    value: existingAddressData?.firstName || '',
    error: ''
  });
  const [lastName, setLastName] = useState({
    value: existingAddressData?.lastName || '',
    error: ''
  });
  const [streetAddress1, setStreetAddress1] = useState({
    value: existingAddressData?.streetAddress1 || '',
    error: ''
  });
  const [city, setCity] = useState({
    value: existingAddressData?.city || '',
    error: ''
  });
  const [countryArea, setCountryArea] = useState({
    value: existingAddressData?.countryArea || '',
    error: ''
  });
  const [country, setCountry] = useState({
    value: existingAddressData?.country || '',
    error: ''
  });
  const [postalCode, setPostalCode] = useState({
    value: existingAddressData?.postalCode || '',
    error: ''
  });

  const handleRequired = (object: { value: string, error: string }, set: Dispatch<any>, field: string) => {
    if (!object?.value) {
      set({ ...object, error: `${field} is required` })
      return false
    } else {
      set({ ...object, error: '' })
      return true
    }
  }

  /*
  const handlePhoneFormat = (value: string) => {
    if (/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(value)) {
      console.log('A')
      setPhone({ ...phone, error: 'phone format is invalid' })
      return false
    } else {
      setPhone({ ...phone, error: '' })
      console.log('B')
      return true
    }
  }
  */

  const handleValidate = () => {
    if (
      !handleRequired(phone, setPhone, 'phone') ||
      !handleRequired(firstName, setFirstName, 'firstName') ||
      !handleRequired(lastName, setLastName, 'lastName') ||
      !handleRequired(streetAddress1, setStreetAddress1, 'streetAddress1') ||
      !handleRequired(city, setCity, 'city') ||
      !handleRequired(country, setCountry, 'country') ||
      !handleRequired(countryArea, setCountryArea, 'countryArea') ||
      !handleRequired(postalCode, setPostalCode, 'postalCode')
    ) {
      return false
    } else {
      return true
    }
  }

  const handleSubmit = async () => {
    try {
      const validateResult = handleValidate()
      if (!validateResult) return
      setLoading(true)
      const errors = await updateAddressMutation({
        phone: phone.value,
        firstName: firstName.value,
        lastName: lastName.value,
        city: city.value,
        streetAddress1: streetAddress1.value,
        postalCode: postalCode.value,
        countryArea: countryArea.value,
        country: country.value || 'US'
      })

      if (errors.length > 0) {
        const { field, message } = errors[0]
        switch (field) {
          case 'phone':
            setPhone({ ...phone, error: message })
            break;
          case 'firstName':
            setFirstName({ ...firstName, error: message })
            break;
          case 'lastName':
            setLastName({ ...lastName, error: message })
            break;
          case 'streetAddress1':
            setStreetAddress1({ ...streetAddress1, error: message })
            break;
          case 'countryArea':
            setCountryArea({ ...countryArea, error: message })
            break;
          case 'postalCode':
            setPostalCode({ ...postalCode, error: message })
            break;
          case 'city':
            setCity({ ...city, error: message })
            break;
        }
        return;
      }
      onSuccessUpdated?.()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <View style={styles.container}>

      <View style={styles.formItem}>
        {/* <JostText style={styles.formItemLabel}>Phone</JostText> */}
        <Input
          placeholder="Phone"
          spellCheck={false}
          value={phone.value}
          onChangeText={(value) => {
            setPhone({ ...phone, value })
          }}
          errorStyle={{ color: 'red' }}
          errorMessage={phone.error}
          style={[styles.input]}
          inputContainerStyle={styles.inputContainerStyle}
        />
      </View>

      <View style={styles.formItem}>
        {/* <JostText style={styles.formItemLabel}>First Name</JostText> */}
        <Input
          placeholder="First name"
          spellCheck={false}
          value={firstName.value}
          onChangeText={(value) => {
            setFirstName({ ...firstName, value })
          }}
          errorStyle={{ color: 'red' }}
          errorMessage={firstName.error}
          style={[styles.input]}
          inputContainerStyle={styles.inputContainerStyle}
        />
      </View>

      <View style={styles.formItem}>
        {/*  <JostText style={styles.formItemLabel}>Last Name</JostText> */}
        <Input
          placeholder="Last name"
          spellCheck={false}
          value={lastName.value}
          onChangeText={(value) => {
            setLastName({ ...lastName, value })
          }}
          errorStyle={{ color: 'red' }}
          errorMessage={lastName.error}
          style={[styles.input]}
          inputContainerStyle={styles.inputContainerStyle}
        />
      </View>

      <View style={styles.formItem}>
        {/* <JostText style={styles.formItemLabel}>StreetAddress1</JostText> */}
        <Input
          placeholder="StreetAddress1"
          spellCheck={false}
          value={streetAddress1.value}
          onChangeText={(value) => {
            setStreetAddress1({ ...streetAddress1, value })
          }}
          errorStyle={{ color: 'red' }}
          errorMessage={streetAddress1.error}
          style={[styles.input]}
          inputContainerStyle={styles.inputContainerStyle}
        />
      </View>

      <View style={styles.formItem}>
        {/* <JostText style={styles.formItemLabel}>City</JostText> */}
        <Input
          placeholder="City"
          spellCheck={false}
          value={city.value}
          onChangeText={(value) => {
            setCity({ ...city, value })
          }}
          errorStyle={{ color: 'red' }}
          errorMessage={city.error}
          style={[styles.input]}
          inputContainerStyle={styles.inputContainerStyle}
        />
      </View>

      <View style={styles.formItem}>
        <Input
          placeholder="State/Province"
          spellCheck={false}
          value={countryArea.value}
          onChangeText={(value) => {
            setCountryArea({ ...countryArea, value })
          }}
          errorStyle={{ color: 'red' }}
          errorMessage={countryArea.error}
          style={[styles.input]}
          inputContainerStyle={styles.inputContainerStyle}
        />
      </View>

      <View style={{
        ...styles.formItem,
        paddingHorizontal: 10
      }}>
        <CountryPicker
          pickerButtonStyle={{
            ...styles.inputContainerStyle,
            backgroundColor: 'white'
          }}
          pickerButtonTextStyle={{
            fontSize: 10,
            color: country.value ? '#333' : '#86939e'
          }}
          onSelect={(country) => {
            setCountry({ ...countryArea, value: country.value })
          }}
        />
        {country.error && <JostText style={{ color: 'red' }}>{country.error}</JostText>}
      </View>

      <View style={styles.formItem}>
        {/* <JostText style={styles.formItemLabel}>PostalCode</JostText> */}
        <Input
          placeholder="PostalCode"
          spellCheck={false}
          value={postalCode.value}
          onChangeText={(value) => {
            setPostalCode({ ...postalCode, value })
          }}
          errorStyle={{ color: 'red' }}
          errorMessage={postalCode.error}
          style={[styles.input]}
          inputContainerStyle={styles.inputContainerStyle}
        />
      </View>
      <OperaButton
        title="Save"
        onPress={handleSubmit}
        loading={loading}
        containerStyle={{ marginVertical: 10 }}
      />

    </View >
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  formItem: {
    width: '100%'
  },
  formItemLabel: {
    fontSize: 18,
    marginLeft: 10,
    fontWeight: '600'
  },
  input: {
    fontSize: 14,
    paddingHorizontal: 10,
    backgroundColor: 'white'
  },
  inputContainerStyle: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 6,
    marginTop: 10,
    overflow: 'hidden'
  }
})

export default AddressForm