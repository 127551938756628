import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useMemo } from "react";
import CheckoutForm from "./checkoutFormWeb";


interface StripeWebProps {
  clientSecret: string
  publishableKey: string
  returnUrl: string
  payLabel: string
}
const StripeWeb: React.FC<StripeWebProps> = ({
  clientSecret,
  publishableKey,
  returnUrl,
  payLabel
}) => {
  const stripePromise = useMemo(() => loadStripe(publishableKey), [publishableKey]);

  return (
    <Elements options={{ clientSecret, appearance: { theme: "stripe" } }} stripe={stripePromise}>
      <CheckoutForm returnUrl={returnUrl} payLabel={payLabel} />
    </Elements>
  );
}

export default StripeWeb