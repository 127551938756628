import FlexLayout from "@/components/Flex"
import Space from "@/components/Space"
import JostText from "@/components/Text/JostText"
import React from "react"
import { StyleSheet, Text } from "react-native"

interface HeaderProps {
  title: string
  workBornYear: string
  artistName: string
}
const Header: React.FC<HeaderProps> = ({
  title,
  workBornYear,
  artistName,
}) => {
  return <FlexLayout justify="start" items="start" style={{
    ...styles.box
  }}>
    <FlexLayout direction="row" justify="start" items="center">
      <JostText style={styles.topText}>{title}</JostText> {workBornYear && <JostText style={styles.topText}>,{workBornYear}</JostText>}
    </FlexLayout>
    <Space direction="v" size={3} />
    <FlexLayout direction="row" justify="start" items="center">
      <JostText style={styles.bottomText}>{artistName} </JostText>
    </FlexLayout>
  </FlexLayout>
}

const styles = StyleSheet.create({
  box: {
    paddingHorizontal: 15
  },
  topText: {
    fontSize: 24,
    color: '#000'
  },
  bottomText: {
    fontSize: 18,
    color: '#877A99'
  },
})

export default Header