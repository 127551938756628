
import Back from "@/components/Back";
import { useUser } from "@/hooks/useUser";
import { useEffect, useState } from 'react';
import PageNavLayout from "@/components/PageNavLayout";
import CountryPicker from '@/components/CountryPicker';
import { Text, TextInput, ScrollView, StyleSheet, View, TouchableOpacity } from "react-native";
import { useUserAddressUpdate, useUserAddressCreate } from "@/saleor/api/useUserAddress";
import OperaButton from "@/components/Button";
import { responsiveHeight } from "react-native-responsive-dimensions";
import FallbackDesktopMobileLayout from "@/components/FallbackDesktopMobileLayout";
import JostText from "@/components/Text/JostText";
import { Country } from "@/hooks/useCountries";

const AddressEdit: React.FC<any> = ({ navigation }) => {
  const [id, setId] = useState('');
  const [processing, setProcessing] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [streetAddress1, setStreetAddress1] = useState('United States of America');
  const [streetAddress2, setStreetAddress2] = useState('');
  const [city, setCity] = useState('RIVERABURGH');
  const [postalCode, setPostalCode] = useState('96942');
  const [country, setCountry] = useState('US');
  const [countryArea, setCountryArea] = useState('FM');

  const { user } = useUser();
  const [UpdateUserAddress] = useUserAddressUpdate();
  const [CreateUserAddress] = useUserAddressCreate();

  useEffect(() => {
    if (user && user.addresses && user.addresses[0]) {
      const address = user.addresses[0];
      setHasAddress(true);
      setId(address.id);
      setStreetAddress1(address.streetAddress1);
      setStreetAddress2(address.streetAddress2);
      setCity(address.city);
      setCountry(address.country.code);
    }
  }, [user]);

  const handleCountrySelect = (country: Country) => {
    console.log('country', country)
    setCountry(country.value);
  };

  const submit = async () => {
    try {
      const commonParams = {
        streetAddress1, streetAddress2, city, country, countryArea, postalCode
      };
      setProcessing(true)
      hasAddress ? await UpdateUserAddress({
        variables: {
          input: commonParams,
          id
        }
      }) : await CreateUserAddress({
        variables: {
          input: commonParams
        }
      })
      navigation.goBack()
    } catch (error) {
      console.log(error)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <FallbackDesktopMobileLayout>
      <View style={styles.container}>
        <PageNavLayout>
          <View style={{
            flexDirection: 'row', alignItems: "center",
            borderBottomWidth: 1, borderBottomColor: 'rgba(0,0,0,0.1)', paddingVertical: 10
          }}>
            <Back navigation={navigation} />
            <JostText style={{ fontSize: 20, fontWeight: "bold", marginLeft: '28%' }}>Address</JostText>
          </View>
        </PageNavLayout>

        <ScrollView style={styles.content}>

          <JostText style={styles.title}>ADDRESS LINE 1</JostText>
          <TextInput
            style={styles.textInput}
            onChangeText={text => setStreetAddress1(text)}
            value={streetAddress1}
            placeholder="Add street address"
            placeholderTextColor="rgba(0,0,0,0.1)"
          />

          <JostText style={styles.title}>ADDRESS LINE 2</JostText>
          <TextInput
            style={styles.textInput}
            onChangeText={text => setStreetAddress2(text)}
            value={streetAddress2}
            placeholder="Add apt, floor, suite, etc."
            placeholderTextColor="rgba(0,0,0,0.1)"
          />

          <JostText style={styles.title}>CITY</JostText>
          <TextInput
            style={styles.textInput}
            onChangeText={text => setCity(text)}
            value={city}
            placeholder="Add city"
            placeholderTextColor="rgba(0,0,0,0.1)"
          />

          <JostText style={styles.title}>POSTAL CODE</JostText>
          <TextInput
            style={styles.textInput}
            onChangeText={text => setPostalCode(text)}
            value={postalCode}
            placeholder="Add postal code"
            placeholderTextColor="rgba(0,0,0,0.1)"
          />

          <JostText style={styles.title}>STATE</JostText>
          <TextInput
            style={styles.textInput}
            onChangeText={text => setCountryArea(text)}
            value={countryArea}
            placeholder="Add state, province, or region"
            placeholderTextColor="rgba(0,0,0,0.1)"
          />

          <JostText style={styles.title}>COUNTRY</JostText>
          <CountryPicker onSelect={handleCountrySelect} defaultCountryCode={country} />
        </ScrollView>
        <OperaButton containerStyle={styles.saveBtn} loading={processing} title="SAVE" onPress={submit} />
      </View>
    </FallbackDesktopMobileLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    height: responsiveHeight(100),
  },
  content: {
    padding: 20,
    paddingBottom: 100
  },
  title: {
    fontSize: 14,
    fontWeight: '700',
    marginBottom: 10,
  },
  textInput: {
    padding: 10,
    borderWidth: 0.5,
    borderRadius: 10,
    borderColor: 'gray',
    marginBottom: 20
  },
  saveBtn: {
    position: 'absolute',
    bottom: 40,
    left: 20,
    right: 20,
    width: 'auto'
  },
  text: {
    color: "#fff",
    textAlign: "center",
    fontSize: 14,
  }
})

export default AddressEdit
