import JostText from "@/components/Text/JostText"
import { ShippingMethod } from "@/types"
import { formatAsMoney } from "@/utils"
import { StyleSheet, Text, View } from "react-native"


interface ShippingMethodDisplayProps {
  method: ShippingMethod
}
const ShippingMethodDisplay: React.FC<ShippingMethodDisplayProps> = ({
  method
}) => {
  return (
    <View style={styles.container}>
      <JostText style={styles.text}>{method.name}</JostText>
      <JostText style={styles.text}>
        {method.minimumDeliveryDays || 2}-{method.maximumDeliveryDays || 14} business days
      </JostText>
      <JostText style={styles.text}>{formatAsMoney(method.price.amount)}</JostText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    paddingHorizontal: 20
  },
  text: {
    fontSize: 18,
    marginVertical: 10
  }
})

export default ShippingMethodDisplay