import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import JostText from '../Text/JostText'

interface EmptyProps {
  description: string
}
function Empty(props: EmptyProps) {
  return (
    <View style={styles.container}>
      <JostText style={{ fontSize: 20, color: '#666' }}>{props.description}</JostText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 100,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default Empty