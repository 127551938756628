import { LAYOUT_MAX_WIDTH } from '@/constants'
import { useTabletOrDesktop } from '@/hooks/useMedia'
import React from 'react'
import { StyleSheet, View } from 'react-native'

interface HeaderLayoutProps {
  children: React.ReactNode
}
export default function HeaderLayout({ children }: HeaderLayoutProps) {
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  if (!ready) return null
  return (
    <View style={isTabletOrDesktop && styles.container}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: LAYOUT_MAX_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
})