
import { useCallback } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import ArtistListScreen from "@/screens/artist_list";
import GalleryScreen from '@/screens/gallery'
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons';
import { StyleSheet, View } from 'react-native';
import { isWeb } from '@/utils/platform';
import { useTabletOrDesktop } from '@/hooks/useMedia';
import Tab from '@/components/Desktop/Tab';
import HeaderLayout from '@/components/Desktop/HeaderLayout';
import JostText from '@/components/Text/JostText';
import { SCREEN_MAPS } from '@/constants';
import { Image } from 'react-native';

const Tabs = createBottomTabNavigator();
const TAB_DATA = [
  {
    label: 'Gallery',
    path: 'gallery',
    title: 'Artworks',
    component: GalleryScreen,
  },
  {
    label: 'Artist',
    path: 'artist_list',
    title: 'Artists',
    component: ArtistListScreen
  }
]

const HomeStack: React.FC<any> = () => {
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()

  const screenOptions: any = useCallback(({ route }: any) => {
    return {
      headerShown: false,
      tabBarActiveTintColor: 'black',
      tabBarInactiveTintColor: 'rgba(0,0,0,0.8)',
      tabBarStyle: {
        height: isWeb ? 70 : 86,
        paddingTop: isWeb ? 5 : 10,
        paddingBottom: isWeb ? 8 : 20,
        display: isTabletOrDesktop ? 'none' : 'flex',
      },
      tabBarLabel: ({ focused }: any) => {
        let label = route.name === SCREEN_MAPS.artist_list ? 'Aritst' : 'Gallery'
        const style: any = {
          color: 'black',
          fontSize: 13,
          fontWeight: focused ? 'bold' : 'normal',
          marginTop: isWeb ? 5 : 0,
        }
        return <JostText style={style}>{label}</JostText>
      },
      tabBarIcon: ({ focused, color, size }: any) => {
        const iconBoxStyles = {
          ...styles.iconBox,
          ...(focused ? styles.focusIconBox : {})
        }
        if (route.name === 'gallery') {
          return (
            <View style={iconBoxStyles}>
              <MaterialCommunityIcons name="palette" size={size} color={color} />
            </View>
          )
        } else {
          return (
            <View style={iconBoxStyles}>
              <Image source={{ uri: require('./pen.png') }} style={{ width: size + 3, height: size + 3 }} resizeMode='cover' />
            </View>
          )
        }
      }
    }
  }, [isTabletOrDesktop])

  return (
    <>
      {ready && isTabletOrDesktop && <HeaderLayout>
        <Tab data={TAB_DATA} />
      </HeaderLayout>}
      <Tabs.Navigator screenOptions={screenOptions}>
        {TAB_DATA.map((tab) => (
          <Tabs.Screen key={tab.label} name={tab.path} options={{ title: tab.title }} component={tab.component} />
        ))}
      </Tabs.Navigator>
    </>
  );
};

const styles = StyleSheet.create({
  iconBox: {
    width: 56,
    height: 30,
    borderRadius: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  focusIconBox: {
    backgroundColor: '#E8DEF8'
  },
})

export default HomeStack