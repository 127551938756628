import { useState } from "react";
import Back from "@/components/Back";
import Space from "@/components/Space";
import { useUser } from "@/hooks/useUser";
import { Avatar, Image } from '@rneui/themed';
import PageNavLayout from "@/components/PageNavLayout";
import { SafeAreaView } from "react-native-safe-area-context";
import useOrderListQuery from "@/saleor/api/useOrderListQuery";
import { Text, ScrollView, StyleSheet, View, TouchableOpacity } from "react-native";
import useAuth from "@/hooks/store/useAuth";
import { getJoinedYear, getSimpleFullName } from "@/utils";
import OperaButton from "@/components/Button";
import { responsiveHeight } from "react-native-responsive-dimensions";
import ConfirmDialog from "@/components/Dialog/Confirm";
import Loading from "@/components/Loading/Loading";
import FallbackDesktopMobileLayout from "@/components/FallbackDesktopMobileLayout";
import ViewPadding from "@/components/ViewPadding";
import JostText from "@/components/Text/JostText";
import InriaSansText from "@/components/Text/InriaSansText";

const ProfileScreen: React.FC<any> = ({ navigation }) => {
  const { user, loading: loadingProfile } = useUser();
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const { updateToken, updateRefreshToken, updateCsrfToken } = useAuth()
  const { data: orderData, loading: loadingOrders } = useOrderListQuery({
    skip: false
  })
  const orders = orderData?.me?.orders?.edges || []

  const userFullName = `${user?.firstName || ''} ${user?.lastName || ''}`

  if (loadingProfile || loadingOrders) {
    return (
      <Loading />
    )
  }

  return (
    <FallbackDesktopMobileLayout>
      <View style={styles.container}>
        <ScrollView>
          <SafeAreaView style={styles.content}>
            <PageNavLayout>
              <Back navigation={navigation} />
            </PageNavLayout>
            <View style={styles.headers}>
              <Avatar
                size={120}
                rounded
                containerStyle={{ backgroundColor: '#BDBDBD' }}
                source={{ uri: user?.avatar?.url || '' }}
                title={getSimpleFullName(user?.firstName || '', user?.lastName || '')}
                titleStyle={{ color: 'black' }}
              />
              <InriaSansText style={styles.leftTag}>{user?.dateJoined ? getJoinedYear(user?.dateJoined) : ''}</InriaSansText>
            </View>

            {user && <>
              <JostText style={styles.userName}>{userFullName}</JostText>
              <JostText style={styles.email}>{user?.email}</JostText>
              <View style={styles.editInformation}>
                <View style={styles.editTag} onPointerDown={() => {
                  navigation.navigate("personal_edit");
                }}><JostText style={styles.tagText}>Personal Info</JostText></View>

                <View style={styles.editTag} onPointerDown={() => {
                  navigation.navigate("address_edit");
                }}><JostText style={styles.tagText}>Address</JostText></View>
              </View>
            </>}

            <JostText style={styles.title}>My Orders</JostText>
            {!!orders.length && (
              <>
                <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                  {orders.map((order: any, index: number) => (
                    <TouchableOpacity
                      style={{
                        ...styles.item,
                        ...(index === 0 ? { marginLeft: 20 } : {})
                      }}
                      key={order.node?.id}
                      onPress={() => navigation.push('order', {
                        orderToken: order.node.token
                      })}
                    >
                      <Image
                        style={styles.orderThumbnail}
                        source={{ uri: order?.node?.lines?.[0]?.thumbnail?.url || '' }}
                        resizeMode="cover"
                      />
                      <JostText style={styles.orderNoDate}>{order.node.status}</JostText>
                      <JostText style={styles.orderNo}>#{order.node.number}</JostText>
                    </TouchableOpacity>
                  ))}
                </ScrollView>
                <TouchableOpacity onPress={() => navigation.push('contact_us')}>
                  <JostText style={styles.orderHelp}>Need help with orders?</JostText>
                </TouchableOpacity>
              </>
            )}

            {/** 
            <JostText style={styles.title}>My Pins</JostText>
            <ScrollView horizontal style={{ marginLeft: 10 }}>
              <Image style={styles.pinImg} source={{ uri: 'https://placehold.co/600x400' }} />
              <Image style={styles.pinImg} source={{ uri: 'https://placehold.co/600x400' }} />
              <Image style={styles.pinImg} source={{ uri: 'https://placehold.co/600x400' }} />
              <Image style={styles.pinImg} source={{ uri: 'https://placehold.co/600x400' }} />
              <Image style={styles.pinImg} source={{ uri: 'https://placehold.co/600x400' }} />
            </ScrollView>
            

            <JostText style={styles.title}>My Flowing</JostText>
            <View style={{ flexDirection: 'row', marginLeft: 10 }}>
              <Text style={styles.folloTag}>Artists</Text>
              <Text style={styles.folloTag}>Organizations</Text>
              <Text style={styles.folloTag}>Collection</Text>
            </View>
             */}

            <Space size={30} />
            <ViewPadding>
              <OperaButton
                buttonStyle={{ backgroundColor: '#858585' }}
                containerStyle={styles.logoutBtn}
                title="LOG OUT"
                onPress={() => setConfirmDialogVisible(true)}
              />
            </ViewPadding>
            <Space size={30} />
          </SafeAreaView>
        </ScrollView>


        <ConfirmDialog
          title="Log Out"
          content={`Ready to log out ${userFullName}?`}
          visible={confirmDialogVisible}
          onBackdropPress={() => setConfirmDialogVisible(false)}
          onCancel={() => setConfirmDialogVisible(false)}
          onConfirm={() => {
            updateToken('')
            updateRefreshToken('')
            updateCsrfToken('')
            setConfirmDialogVisible(false)
            navigation.popToTop()
          }}
        />
      </View>
    </FallbackDesktopMobileLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    height: responsiveHeight(100)
  },
  content: {
    width: '100%',
    overflow: 'hidden',
  },
  headers: {
    paddingLeft: 20,
    position: 'relative'
  },
  leftTag: {
    position: 'absolute',
    right: -20,
    padding: 12,
    paddingLeft: 20,
    paddingRight: 30,
    borderRadius: 20,
    fontStyle: 'normal',
    color: "#fff",
    backgroundColor: "#000",
    fontWeight: '600'
  },
  userName: {
    color: '#000',
    fontSize: 32,
    margin: 20,
    fontStyle: 'normal',
    fontFamily: 'Inria Serif'
  },
  email: {
    position: 'relative',
    paddingLeft: 20,
    marginTop: -10,
  },
  editInformation: {
    paddingLeft: 20,
    marginTop: 30,
    flexDirection: 'row',
  },
  editTag: {
    paddingHorizontal: 15,
    paddingVertical: 8,
    borderRadius: 8,
    marginRight: 10,
    textAlign: 'center',
    backgroundColor: '#1D1B20'
  },
  tagText: {
    color: 'white',
    fontWeight: '500'
  },
  item: {
    padding: 16,
    width: 197,
    height: 258,
    overflow: 'hidden',
    marginRight: 15,
    borderRadius: 24,
    backgroundColor: '#F2F0E5',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.05)'
  },
  orderThumbnail: {
    width: 140,
    height: 165,
    margin: "auto",
    borderWidth: 2,
    borderColor: 'white'
  },
  title: {
    fontSize: 14,
    marginLeft: 20,
    marginTop: 20,
    marginBottom: 15,
    fontWeight: '700'
  },
  orderNoDate: {
    color: '#79747E',
    marginTop: 17
  },
  orderHelp: {
    marginLeft: 20,
    color: '#7A659A',
    fontWeight: '700',
    letterSpacing: 0.25,
    textDecorationLine: 'underline',
    marginTop: 10
  },
  orderNo: {
    color: '#79747E',
    marginTop: 5
  },
  pinImg: {
    width: 35,
    height: 45,
    margin: 10
  },
  folloTag: {
    padding: 15,
    borderRadius: 25,
    margin: 10,
    borderWidth: 1,
    borderColor: 'black'
  },
  selectorMode: {
    color: '#000',
    fontSize: 24,
    fontStyle: 'normal',
    lineHeight: 36,
  },
  buttonGroup: {
    margin: 'auto',
    width: '100%',
    maxWidth: 400,
    padding: '5%',
  },
  logoutBtn: {
  },
  separator: {
    marginTop: 0,
    marginBottom: 0,
    margin: 'auto',
    width: '40%',
    borderBottomColor: '#737373',
  },
})

export default ProfileScreen
