import { useMutation, gql } from '@apollo/client';

export const SET_ACCOUNT = gql`
  mutation RegisterAccount($email: String!, $password: String!, $firstName: String, 
      $lastName: String, $redirectUrl: String, $channel: String) {
    accountRegister(input: 
      { email: $email, password: $password, firstName: $firstName, 
        lastName: $lastName, redirectUrl: $redirectUrl, channel: $channel }
    ) {
      errors {
        field
        message
      }
      user {
        email
        isActive
      }
    }
  }
`;

export default function useAccount() {
  return useMutation(SET_ACCOUNT);
}