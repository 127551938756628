import { useTablet, useDesktop, useMobile, useTabletOrDesktop } from '@/hooks/useMedia'
import React, { useMemo } from 'react'
import { View, TouchableOpacity, TouchableOpacityProps, StyleSheet, Image, Text } from 'react-native'
import { fontFamilyBodoniModa } from '@/utils/font';
import { extractTextFromBlocks, formatAsMoney, getPriceFromProduct } from '@/utils';
import { responsiveWidth } from "react-native-responsive-dimensions";
import useImageSize from '@/hooks/useImageSize';
import FlexLayout from '../Flex';
import JostText from '../Text/JostText';
import InriaSansText from '../Text/InriaSansText';

interface ProductListItemProps {
  data: Record<string, any>
  onPress: TouchableOpacityProps['onPress']
}

const PC_POSTER_HEIGHT = 250
const PC_ITEM_HEIGHT = 390

function ProductListItem({ data, onPress }: ProductListItemProps) {
  const isTablet = useTablet()
  const isDesktop = useDesktop()
  const isMobile = useMobile()
  const isTabletOrDesktop = isTablet || isDesktop

  const displayPrice = useMemo(() => {
    const { amount, currency } = getPriceFromProduct(data)
    return formatAsMoney(amount, currency)
  }, [data])

  const mobileImageWidth = responsiveWidth(100) - 40

  const posterUrl = useMemo(() => {
    const media = data?.media
    const thumbnailUrl = data?.thumbnail?.url
    return media?.length ? media[0].url : thumbnailUrl
  }, [data])

  const { rate } = useImageSize(posterUrl)

  // const height = Math.min(nativeHeight, PC_POSTER_HEIGHT - 10)

  if (!rate) return null

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        ...styles.container,
        ...(isTabletOrDesktop ? {
          width: isDesktop ? '31%' : isTablet ? '46%' : 'auto',
          marginRight: 15,
          marginBottom: 15,
          height: PC_ITEM_HEIGHT
        } : {
          paddingHorizontal: 20
        })
      }}
    >
      <View style={{
        ...styles.posterBox,
        ...isTabletOrDesktop ? {
          paddingVertical: 5
        } : {}
      }}>
        {posterUrl
          ? isMobile && rate
            ? <Image
              style={{
                width: mobileImageWidth,
                height: mobileImageWidth / rate
              }}
              source={{ uri: posterUrl }}
              resizeMode='cover'
            />
            : <FlexLayout
              style={{
                width: '100%',
                height: PC_POSTER_HEIGHT,
                backgroundColor: 'white'
              }}
            >
              <Image style={{
                // width: height * rate,
                width: '100%',
                height: '100%',
                // marginHorizontal: 'auto'
              }} source={{ uri: posterUrl }} resizeMode='cover' />
            </FlexLayout>
          : null
        }
      </View>
      <View style={{
        ...styles.infoBox,
        ...isTabletOrDesktop ? {
          flexBasis: PC_ITEM_HEIGHT - PC_POSTER_HEIGHT
        } : {}
      }}>
        <View style={styles.infoTopBox}>
          <View style={styles.infoLeft}>
            <JostText numberOfLines={1} ellipsizeMode="tail" style={styles.infoTitle}>{data.name}</JostText>
            {data?.artist?.name && <JostText style={styles.infoArtist}>{data.artist.name}</JostText>}
          </View>
          <View style={styles.infoRight}>
            <JostText style={styles.infoPrice}>{displayPrice}</JostText>
          </View>
        </View>
        <View style={styles.infoDesc}>
          <InriaSansText numberOfLines={3} ellipsizeMode="tail">{extractTextFromBlocks(data?.description)}</InriaSansText>
        </View>
      </View>
    </TouchableOpacity >
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  posterBox: {
    flex: 1,
    position: 'relative',
  },
  pcPoster: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: 100
  },
  infoBox: {
    padding: 12,
    backgroundColor: '#fff',
  },
  infoTopBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  infoLeft: {
    flexBasis: '70%',
  },
  infoRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  infoPrice: {
    color: 'rgba(0,0,0,0.8)',
    fontSize: 16,
  },
  infoTitle: {
    fontSize: 18,
    fontWeight: '600'
  },
  infoArtist: {
    fontFamily: fontFamilyBodoniModa,
    color: 'rgba(0,0,0,0.6)',
  },
  infoDesc: {
    fontFamily: fontFamilyBodoniModa,
    marginTop: 10,
    marginBottom: 12
  }
})

export default ProductListItem