import Back from "@/components/Back";
import { useEffect, useState } from "react";
import { withSafeArea } from "@/utils/withSafeArea";
import { useAccountConfirmMutation } from "@/saleor/api/useAccountConfirm";
import { View, TextInput, TouchableOpacity, StyleSheet } from "react-native";
import JostText from "@/components/Text/JostText";

const AccountConfirmScreen: React.FC<any> = ({ navigation, route }) => {
  const [email, onChangeEmail] = useState('');
  const [errMsg, onChangeErrMsg] = useState('');

  // http://localhost:19006/account-confirm?email=hongshancao372%40gmail.com&token=bubgz1-e9be6779d22929e1b378802513443315

  const displayConfirmationAlert = (errors: { message: string }[]) => {
    const hasErrors = errors.length > 0;
    const errMsgStr = hasErrors ? errors.map(({ message }) => message).join(" ") : "You can now log in"
    onChangeErrMsg(errMsgStr);
  };

  useEffect(() => {
    onChangeEmail(route.params.email);
  }, []);

  const [mutation] = useAccountConfirmMutation(route.params);

  useEffect(() => {
    (async () => {
      const { data, errors } = await mutation();
      if (errors?.length) {
        displayConfirmationAlert([
          {
            message: "Something went wrong while activating your account.",
          },
        ]);
      }

      if (data?.confirmAccount?.errors?.length) {
        displayConfirmationAlert(data.confirmAccount.errors as any[]);
      }
    })();
  }, []);


  return (
    <>
      <View style={styles.content}>
        <View style={styles.buttonGroup}>
          <JostText style={styles.title}>Global Art</JostText>

          <JostText style={{ color: 'red' }}>{errMsg}</JostText>

          <View style={styles.back}>
            <Back navigation={navigation} />
            <JostText style={styles.selectorMode}>SIGN UP</JostText>
          </View>

          <TextInput
            style={styles.textInput}
            value={email}
            placeholder="Email"
          />

          <TouchableOpacity onPress={() => {
            navigation.navigate('email_login');
          }}>
            <JostText style={styles.button}>LOG IN</JostText>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    opacity: 0.7,
    bottom: 0
  },
  back: {
    marginLeft: -10,
    top: 30
  },
  title: {
    color: '#000',
    fontFamily: 'Inria Serif',
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 29.5,
    paddingBottom: 100,
  },
  selectorMode: {
    color: '#000',
    fontSize: 24,
    fontStyle: 'normal',
    top: -37,
    left: 35
  },
  fixToText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textInput: {
    borderColor: 'gray',
    borderWidth: 1,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 10,
    padding: 10,
  },
  buttonGroup: {
    margin: 'auto',
    width: '100%',
    maxWidth: 420,
    padding: 20,
  },
  button: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderRadius: 10,
    overflow: "hidden",
    marginTop: 15,
    backgroundColor: "#000000",
    padding: 10,
    color: "#fff",
    fontFamily: 'Inria Sans',
    fontSize: 14,
    fontStyle: 'normal',
    textAlign: "center",
  }
})

export default withSafeArea(AccountConfirmScreen)