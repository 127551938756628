import FlexLayout from "@/components/Flex"
import JostText from "@/components/Text/JostText"
import React from "react"
import { StyleSheet, Text } from "react-native"

interface MediumProps {
  label: string
}
const Medium: React.FC<MediumProps> = ({
  label
}) => {
  return <FlexLayout direction="row" justify="start" items="center">
    <JostText style={styles.label}>{label}</JostText>
  </FlexLayout>
}


const styles = StyleSheet.create({
  label: {
    fontSize: 20,
    fontWeight: "400",
    color: "#877A99"
  }
})

export default Medium