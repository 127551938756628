import React, { useState } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

import JostText from '../Text/JostText';
import useCountries, { Country } from '@/hooks/useCountries';
import PickerDialog from './PickerDialog';

interface CountryPickerProps {
  onSelect: (country: Country) => void;
  defaultCountryCode?: string;
  pickerButtonStyle?: any
  pickerButtonTextStyle?: any
}

const CountryPicker: React.FC<CountryPickerProps> = ({ onSelect, defaultCountryCode, pickerButtonStyle, pickerButtonTextStyle }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { getByValue } = useCountries()
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    defaultCountryCode ? getByValue(defaultCountryCode) || null : null
  );

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };
  const handleCountrySelect = (country: any) => {
    setSelectedCountry(country);
    onSelect(country);
    toggleModal();
  };
  return (
    <View style={styles.container}>
      <TouchableOpacity style={{
        ...styles.pickerButton,
        ...(pickerButtonStyle || {}),
      }} onPress={toggleModal}>
        <JostText style={{
          ...styles.pickerButtonText,
          ...(pickerButtonTextStyle || {}),
        }}>{selectedCountry ? selectedCountry.label : 'Select Country'}</JostText>
      </TouchableOpacity>

      <PickerDialog
        visible={isVisible}
        onConfirm={handleCountrySelect}
        onCancel={() => setIsVisible(false)}
        onBackdropPress={() => setIsVisible(false)}
        defaultCountry={selectedCountry || undefined}
      />
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pickerButton: {
    padding: 10,
    width: '100%',
    borderRadius: 10,
    borderWidth: 0.2,
    borderBlockColor: '#79747E'
  },
  pickerButtonText: {
    fontSize: 16,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 10,
  },
  countryItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  countryItemText: {
    fontSize: 16,
  },
});

export default CountryPicker;