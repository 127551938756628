import { DEFAULT_CHANNEL } from '@/constants';
import { useQuery, gql } from '@apollo/client';
import { PRODUCT_ITEM_FRAGMENT } from '../fragments';

export const GET_PRODUCTS = gql`
  query GetProductsByArtId($artistId: ID, $channel: String) {
    products(first: 10, filter: { artistId: $artistId }, channel: $channel) {
      edges {
        node {
          ...Product
        }
      }
    }
  }
  ${PRODUCT_ITEM_FRAGMENT}
`;

export function useProductsByArt(artistId?: string) {
  return useQuery(GET_PRODUCTS, {
    variables: {
      artistId,
      channel: DEFAULT_CHANNEL
    },
    skip: !artistId
  });
}