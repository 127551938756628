import useUserQuery from "@/saleor/api/useUserQuery";
import { useEffect } from "react";
import useUserInfo from "./store/useUserInfo";
import useAuth from "./store/useAuth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CSRF_TOKEN, REFRESH_TOKEN } from "@/constants";
import refreshToken from '@/utils/refreshToken';
import { isWeb } from "@/utils/platform";
import { useNavigation } from "@react-navigation/native";

export const useUser = (needRedirectToLoginPage = true) => {
  const { data, loading, error, called } = useUserQuery();
  const { updateUser } = useUserInfo()
  const { updateToken } = useAuth()
  const navigation: any = useNavigation()

  const user = data?.user;

  const authenticated = !!user?.id;
  
  useEffect(() => {
    updateUser(user || null)
  }, [user])

  useEffect(() => {
    const check = async () => {
      try {
        const tokenOfRefresh  = await AsyncStorage.getItem(REFRESH_TOKEN)
        const csrfToken = await AsyncStorage.getItem(CSRF_TOKEN)
        if (called && user === null) {
          if (tokenOfRefresh && csrfToken) {
            const res = await refreshToken()
            const { token: newToken } = res?.data?.tokenRefresh || {}
            updateToken(newToken)
            setTimeout(() => {
              if (isWeb) {
                window.location.reload()
              }
            }, 100)
          } else {
            needRedirectToLoginPage && navigation.replace('email_login')
          }
        }
      } catch (error) {
       console.log(error) 
      }
    }
    check()
  }, [called, user])

  return { user, loading, authenticated, called, error };
};
