import Flex from "@/components/Flex"
import JostText from "@/components/Text/JostText"
import { StyleSheet, Text, View } from "react-native"

interface TextItemProps {
  label: string
  value?: String
  children?: React.ReactNode
}
const TextItem: React.FC<TextItemProps> = ({
  label,
  value,
  children = null
}) => {
  return (
    <Flex justify="start" items="start" style={styles.container}>
      <JostText style={styles.label}>{label}</JostText>
      {value && <JostText style={styles.value}>{value}</JostText>}
      {children}
    </Flex>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0,0,0,0.08)'
  },
  label: {
    fontWeight: '600',
    fontSize: 18,
    marginBottom: 5
  },
  value: {
    fontSize: 16,
    color: '#333'
  }
})

export default TextItem