import { useLazyQuery, gql } from '@apollo/client';
import { PRODUCT_ITEM_FRAGMENT, PAGEINFO_FRAGMENT } from '../fragments';

export const GET_PRODUCTS_CATEGORY = gql`
  query GetProductsByCategory($categoryIds: [ID!], $channel: String, $first: Int $after: String, $before: String, $last: Int) {
    products(
      first: $first, after: $after, before: $before, last: $last, 
      filter: { categories: $categoryIds }, channel: $channel, 
      sortBy: {field: RATING, direction: DESC}
    ) {
      edges {
        node {
          ...Product
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PRODUCT_ITEM_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;

export function useProductByCategory() {
  return useLazyQuery(GET_PRODUCTS_CATEGORY);
}
