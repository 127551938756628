
import { useMutation, gql } from '@apollo/client';


export const GQL = gql`
  mutation CheckoutShippingAddressUpdate(
    $token: UUID!
    $address: AddressInput!
    $locale: LanguageCodeEnum!
  ) {
    checkoutShippingAddressUpdate(shippingAddress: $address, token: $token) {
      checkout {
        id
        token
        email
        isShippingRequired
        discountName
        billingAddress {
          id
          phone
          firstName
          lastName
          streetAddress1
          city
          postalCode
          isDefaultBillingAddress
          isDefaultShippingAddress
          country {
            code
            country
          }
        }
        shippingAddress {
          id
          phone
          firstName
          lastName
          streetAddress1
          city
          postalCode
          isDefaultBillingAddress
          isDefaultShippingAddress
          country {
            code
            country
          }
        }
        shippingMethod {
          id
          name
          minimumDeliveryDays
          maximumDeliveryDays
          translation(languageCode: $locale) {
            id
            name
          }
          price {
            currency
            amount
          }
        }
        shippingMethods{
          id
          name
          minimumDeliveryDays
          maximumDeliveryDays
          translation(languageCode: $locale) {
            id
            name
          }
          price {
            currency
            amount
          }
        }
        availablePaymentGateways{
          id
          name
          config {
            field
            value
          }
        }
        lines{
          id
          quantity
          totalPrice {
            gross {
              currency
              amount
            }
          }
          variant{
            id
            name
            product {
              id
              name
              slug
              translation(languageCode: $locale) {
                id
                name
              }
              thumbnail {
                __typename
                url
                alt
              }
            }
            pricing{
              price{
                __typename
                gross {
                  currency
                  amount
                }
              }
            }
            translation(languageCode: $locale) {
              __typename
              id
              name
            }
          }
        }
        discount{
          currency
          amount
        }
        subtotalPrice{
          __typename
          net {
            __typename
            currency
            amount
          }
          tax{
            __typename
            currency
            amount
          }
        }
        shippingPrice{
          gross{
            currency
            amount
          }
        }
        totalPrice {
          gross{
            currency
            amount
          }
        }
      }
      errors {
        field
        message
        code
      }
    }
  }
`;

export default function useCheckoutShippingAddressUpdateMutation() {
  return useMutation(GQL)
}