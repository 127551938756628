
import Back from "@/components/Back";
import { useState } from 'react';
import { ScrollView, TextInput, StyleSheet, View } from "react-native";
import OperaButton from "@/components/Button";

import { responsiveHeight } from "react-native-responsive-dimensions";
import FallbackDesktopMobileLayout from "@/components/FallbackDesktopMobileLayout";
import JostText from "@/components/Text/JostText";
import { useResetPasswordMutation } from "@/saleor/api/useResetPasswordMutation";
import useAuth from "@/hooks/store/useAuth";
import NotificationDialog from "@/components/Dialog/Notification";

const ResetPasswordScreen: React.FC<any> = ({ navigation, route }) => {
  const [processing, setProcessing] = useState(false);
  const [password, setPassword] = useState('');
  const [notificationMsg, setNotificationMsg] = useState('');
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [resetPasswordMutation] = useResetPasswordMutation()
  const { updateToken, updateRefreshToken, updateCsrfToken } = useAuth()
  const { email, token: passwordResetToken } = route.params as any

  const submit = async () => {
    try {
      setProcessing(true)
      const result = await resetPasswordMutation({
        variables: {
          email,
          password,
          token: passwordResetToken,
        },
      })
      const errors = result?.data?.setPassword?.errors
      if (errors?.length) {
        setNotificationMsg(errors[0]?.message)
        setNotificationVisible(true)
        return
      }
      const { token, refreshToken, csrfToken } = result.data.setPassword
      updateToken(token)
      updateCsrfToken(csrfToken)
      updateRefreshToken(refreshToken)
      setNotificationMsg('Reset Password Successfully')
      setNotificationVisible(true)
    } catch (error) {
      console.log(error)
    } finally {
      setProcessing(false)
    }
  }

  return (
    <FallbackDesktopMobileLayout>
      <View style={styles.container}>
        <View style={{
          flexDirection: 'row', alignItems: "center",
          borderBottomWidth: 1, borderBottomColor: 'rgba(0,0,0,0.1)', paddingVertical: 10
        }}>
          <Back navigation={navigation} />
          <JostText style={{ fontSize: 20, fontWeight: "bold", marginLeft: '26%' }}>Reset Password</JostText>
        </View>
        <ScrollView style={styles.content}>
          <JostText style={styles.title}>PASSWORD</JostText>
          <TextInput
            style={styles.textInput}
            onChangeText={text => setPassword(text)}
            secureTextEntry={true}
            value={password}
            placeholder="Enter new password"
            placeholderTextColor="rgba(0,0,0,0.1)"
          />
        </ScrollView>
        <OperaButton containerStyle={styles.saveBtn} loading={processing} title="SAVE" onPress={submit} />
      </View>
      <NotificationDialog
        visible={notificationVisible}
        content={notificationMsg}
        onBackdropPress={() => setNotificationVisible(false)}
        onCancel={() => {
          setNotificationVisible(false);
          navigation.navigate('home');
        }}
      />
    </FallbackDesktopMobileLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    height: responsiveHeight(100),
  },
  content: {
    marginTop: 20,
    padding: 20
  },
  headers: {
    marginBottom: 22
  },
  icons: {
    position: "absolute",
    left: 120,
  },
  saveBtn: {
    position: 'absolute',
    bottom: 40,
    left: 20,
    right: 20,
    width: 'auto'
  },
  userName: {
    color: '#000',
    fontSize: 32,
    margin: 20,
    fontStyle: 'normal',
    fontFamily: 'Inria Serif'
  },
  eamil: {
    position: 'relative',
    paddingLeft: 20,
    marginTop: -10,
  },
  title: {
    fontSize: 14,
    fontWeight: '700',
    marginBottom: 10,
  },
  textInput: {
    padding: 10,
    borderWidth: 0.5,
    borderRadius: 10,
    borderColor: 'gray',
    marginBottom: 20
  }
})

export default ResetPasswordScreen
