import React from 'react'
import { StyleSheet, View } from 'react-native'

interface PageNavLayoutProps {
  children: React.ReactNode
}

function PageNavLayout({ children }: PageNavLayoutProps) {
  return (
    <View style={styles.container}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 30,
    paddingHorizontal: 10
  }
})

export default PageNavLayout