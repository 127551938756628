import { useMutation, gql } from '@apollo/client';
import { USER_FRAGMENT } from '../fragments';

const setAccountUpdate = gql`  
  fragment AccountError on AccountError {
    code
    field
    message
    __typename
  }
  
  mutation AccountUpdate($input: AccountInput!) {
    accountUpdate(input: $input) {
      errors: accountErrors {
        ...AccountError
      }
      user {
        ...User
      }
    }
  } 
  ${USER_FRAGMENT}
`;

export const useAccountUpdate = () => useMutation(setAccountUpdate);
