import { gql } from '@apollo/client';
import { uploadFileClient } from "@/libs/ApolloClient";

const uploadAvatar = async (file: any) => {
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
        operationName: 'UserAvatarUpdate',
        variables: {
            image: null,
        },
        query: `mutation UserAvatarUpdate($image: Upload!) {
            userAvatarUpdate(image: $image) {
                errors {
                    ...AccountError
                    __typename
                }
                user {
                    id
                    avatar {
                        url
                        __typename
                    }
                    __typename
                }
                __typename
            }
        }
        fragment AccountError on AccountError {
            code
            field
            addressType
            message
            __typename
        }`,
    }));

    formData.append('map', JSON.stringify({ '1': ['variables.image'] }));
    formData.append('1', file, file.name);

    try {
        const response = await uploadFileClient.mutate({
            mutation: gql`
                mutation UploadAvatar($image: Upload!) {
                    userAvatarUpdate(image: $image) {
                        errors {
                            ...AccountError
                            __typename
                        }
                        user {
                            id
                            avatar {
                                url
                                __typename
                            }
                            __typename
                        }
                        __typename
                    }
                }
                fragment AccountError on AccountError {
                    code
                    field
                    addressType
                    message
                    __typename
                }
            `,
            variables: {
                image: file,
            },
        });
        console.log('上传成功', response.data);
    } catch (error) {
        console.error('上传失败', error);
    }
};

export default uploadAvatar;