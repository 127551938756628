import { useMobile } from "@/hooks/useMedia";
import { StyleSheet, View } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { LOGIN_TOKEN } from "@/constants";
import ViewPadding from "@/components/ViewPadding";
import Title from "@/components/Title";
import { useNavigation } from "@react-navigation/native";

interface PageHeaderProps {
  title: string
  withProfile?: boolean
}
const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  withProfile = true
}) => {
  const isMobile = useMobile()
  const navigation: any = useNavigation()
  return (
    <>
      {isMobile && <>
        {withProfile && <View style={styles.profileContainer}>
          <FontAwesome onPress={async () => {
            const token = await AsyncStorage.getItem(LOGIN_TOKEN);
            navigation.navigate(token ? 'profile' : 'login_options')
          }} name="user" size={24} />
        </View>}
        <ViewPadding>
          <Title label={title} style={{ marginTop: 20 }} />
        </ViewPadding>
      </>}
    </>
  )
}

const styles = StyleSheet.create({
  profileContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingHorizontal: 20
  },
})

export default PageHeader