import ArtInfo from '@/components/ArtInfo'
import Space from '@/components/Space'
import JostText from '@/components/Text/JostText'
import Title from '@/components/Title'
import ViewPadding from '@/components/ViewPadding'
import useProductDetail from '@/hooks/store/useProductDetail'
import React from 'react'
import { StyleSheet } from 'react-native'

function ArtistView() {
  const { info } = useProductDetail()
  if (!info) return null
  return (
    <ViewPadding style={styles.container}>
      <Title label="The Artist" />

      {/** avatar info */}
      <Space size={36} />
      <ArtInfo info={info.artist} />
      <Space size={30} />

      {/** description */}
      <JostText style={{ fontSize: 16, color: '#453B55' }}>
        {info?.artist?.description || ''}
      </JostText>

      <Space size={30} />

    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {},
})

export default ArtistView