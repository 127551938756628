import { useQuery, gql, OperationVariables } from '@apollo/client';
import { ARTIST_FRAGMENT, PRODUCT_PRICING_FRAGMENT } from '../fragments';

export const PRODUCT_QUERY = gql`
  query GetProductById($productId: ID!, $channel: String) {
    product(id: $productId, channel: $channel) {
      id
      name
      thumbnail {
        url
      }
      description,
      metadata {
        key
        value
      }
      media {
        id
        type
        url
      }
      pricing {
        ...ProductPricingInfo
      }
      artist {
        ...Artist
      }
      attributes {
        values {
          id
          name
          slug
          value
          plainText
        }
        attribute{
          id
          name
        }
      } 
      variants {
        id
        name
        sku
        quantityLimitPerCustomer
        pricing {
          onSale
          discount {
            currency
            gross {
              currency
              amount
            }
            net {
              currency
              amount
            }
            tax {
              currency
              amount
            }
          }
          price {
            currency
            gross {
              currency
              amount
            }
            net {
              currency
              amount
            }
            tax {
              currency
              amount
            }
          }
        }
      }
    }
  }
  ${PRODUCT_PRICING_FRAGMENT}
  ${ARTIST_FRAGMENT}
`;

export const PRODUCT_QUERY0 = gql`
  query GetProductById($productId: ID!, $channel: String) {
    product(id: $productId, channel: $channel) {
      id
      name
      thumbnail {
        url
      }
      description,
      metadata {
        key
        value
      }
      media {
        id
        type
        url
      }
      pricing {
        displayGrossPrices
        priceRange{
          start {
            gross{
              currency
              amount
            }
          }
        }
        discount{
          currency
          gross{
            currency
            amount
          }
        }
      }
      attributes {
        values {
          id
          name
          slug
          value
          plainText
        }
        attribute{
          id
          name
        }
      }
      variants {
        id
        name
        sku
        quantityLimitPerCustomer
      }
    }
  }
`;

export default function useProduct(variables: OperationVariables) {
  return useQuery(PRODUCT_QUERY, {
    variables
  });
}