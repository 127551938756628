

import { fontFamilyJomolhariRegular } from '@/utils/font'
import React from 'react'
import { Text, TextProps } from 'react-native'

function JomolhariText({ children, ...reset }: TextProps & { children: React.ReactNode }) {
  return (
    <Text {...reset} style={{
      ...(reset.style || {}) as any,
      fontFamily: fontFamilyJomolhariRegular
    }}>{children}</Text>
  )
}

export default JomolhariText