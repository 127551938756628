import { useMutation, gql } from '@apollo/client';

export const RequestPasswordResetDocument = gql`
  mutation requestPasswordReset($email: String!, $channel: String!, $redirectUrl: String!) {
    requestPasswordReset(email: $email, channel: $channel, redirectUrl: $redirectUrl) {
      errors {
        message
        field
        code
      }
    }
  }
`;

export function useRequestPasswordResetMutation() {
  return useMutation(
    RequestPasswordResetDocument
  );
}