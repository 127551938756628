
import { useMutation, gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from '../fragments';

export const GQL = gql`
  mutation CheckoutEmailUpdate($token: UUID!, $email: String!, $locale: LanguageCodeEnum!) {
    checkoutEmailUpdate(email: $email, token: $token) {
      checkout {
        id
        token
        email
        isShippingRequired
        discountName
        billingAddress {
          ...Address
        }
        shippingAddress {
          ...Address
        }
        shippingMethods{
          id
          name
          minimumDeliveryDays
          maximumDeliveryDays
          translation(languageCode: $locale) {
            id
            name
          }
          price {
            currency
            amount
          }
        }
        availablePaymentGateways{
          id
          name
          config {
            field
            value
          }
        }
        lines{
          id
          quantity
          totalPrice {
            gross {
              currency
              amount
            }
          }
          variant{
            id
            name
            product {
              id
              name
              slug
              translation(languageCode: $locale) {
                id
                name
              }
              thumbnail {
                __typename
                url
                alt
              }
            }
            pricing{
              price{
                __typename
                gross {
                  currency
                  amount
                }
              }
            }
            translation(languageCode: $locale) {
              __typename
              id
              name
            }
          }
        }
        discount{
          currency
          amount
        }
        subtotalPrice{
          __typename
          net {
            __typename
            currency
            amount
          }
          tax{
            __typename
            currency
            amount
          }
        }
        shippingPrice{
          gross{
            currency
            amount
          }
        }
        totalPrice {
          gross{
            currency
            amount
          }
        }
      }
      errors {
        field
        message
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`

export default function useCheckoutEmailUpdateMutation() {
  return useMutation(GQL)
}