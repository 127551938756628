
import { withSafeArea } from "@/utils/withSafeArea";
import { Feather } from '@expo/vector-icons';
import { responsiveHeight } from "react-native-responsive-dimensions";
import { View, Text, TouchableOpacity, StyleSheet, ImageBackground } from "react-native";
import JostText from "@/components/Text/JostText";
import FlexLayout from "@/components/Flex";
import Back from "@/components/Back";

const LoginOptionsScreen: React.FC<any> = ({ navigation }) => {
  return (
    <>
      <ImageBackground
        source={require("./login-background.jpg")}
        style={styles.background}
      />

      <View style={styles.content}>
        <View style={styles.buttonGroup}>
          <View>
            <JostText style={styles.title}>Global Art</JostText>
          </View>

          <FlexLayout direction="row" justify="start" items="start">
            <Back size={32} navigation={navigation} />
            <Text style={styles.selectorMode}>LOG IN</Text>
          </FlexLayout>
          {/*<TouchableOpacity onPress={() => {
          }}>
            <View style={styles.button} >
              <Fontisto style={styles.icons} name="google" size={14} color="white" />
              <Text style={styles.text}>Log in with Google</Text>
            </View>
          </TouchableOpacity>

          <TouchableOpacity onPress={() => {
          }}>
            <View style={styles.button}>
              <Fontisto style={styles.icons} name="apple" size={14} color="white" />
              <Text style={styles.text}>Log in with Apple</Text>
            </View>
          </TouchableOpacity>
           */}

          <TouchableOpacity onPress={() => {
            navigation.navigate("email_login")
          }}>
            <View style={styles.button}>
              <Feather style={styles.icons} name="mail" size={14} color="white" />
              <JostText style={styles.text}>Log in with Email</JostText>
            </View>
          </TouchableOpacity>

          <View style={styles.separator} />

          <TouchableOpacity onPress={() => {
            navigation.navigate("sign_up")
          }}>
            <View style={styles.button}>
              <JostText style={styles.buttonLabel}>Sign Up</JostText>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '6%',
  },
  background: {
    height: responsiveHeight(100),
    opacity: 0.5
  },
  title: {
    color: '#000',
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 29.5,
    paddingBottom: 100,
  },
  selectorMode: {
    color: '#000',
    fontSize: 24,
    fontStyle: 'normal',
    lineHeight: 36,
  },
  buttonGroup: {
    margin: 'auto',
    width: '100%',
    maxWidth: 400,
    padding: '5%',
  },
  button: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderRadius: 10,
    margin: 'auto',
    marginTop: 6,
    marginBottom: 6,
    overflow: "hidden",
    backgroundColor: "#000000",
    padding: 10,
  },
  buttonLabel: {
    color: "#fff",
    fontSize: 14,
    fontStyle: 'normal',
    textAlign: "center",
  },
  text: {
    color: "#fff",
    textAlign: "center",
    fontSize: 14,
  },
  icons: {
    position: "absolute",
    marginLeft: 10,
  },
  separator: {
    marginTop: 8,
    marginBottom: 8,
    margin: 'auto',
    width: '40%',
    borderBottomColor: '#737373',
    borderBottomWidth: StyleSheet.hairlineWidth,
  },

  fixToText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

})

export default withSafeArea(LoginOptionsScreen);