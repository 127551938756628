import Loading from '@/components/Loading/Loading'
import JostText from '@/components/Text/JostText'
import Title from '@/components/Title'
import ViewPadding from '@/components/ViewPadding'
import useProductDetail from '@/hooks/store/useProductDetail'
import { useTabletOrDesktop } from '@/hooks/useMedia'
import { useProductsByArt } from '@/saleor/api/useProductsByArt'
import { ProductItem } from '@/types'
import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { StyleSheet, ScrollView, View, TouchableOpacity, Image, Text } from 'react-native'

function MoreArtwork() {
  const navigation: any = useNavigation()
  const { info } = useProductDetail()
  const { data, loading } = useProductsByArt(info?.artist?.id)
  const { isTabletOrDesktop } = useTabletOrDesktop()
  const relateProducts: ProductItem[] = (data?.products?.edges || []).map((v: any) => ({ ...v.node })).filter((v: any) => v.id !== info?.id)

  if (loading) return <Loading />

  return (
    <View style={{
      ...styles.container,
      ...isTabletOrDesktop ? {
        width: 420
      } : {}
    }}>
      <ViewPadding>
        {isTabletOrDesktop ? <JostText style={styles.pcTitle}>
          More artwork - {info?.artist?.name}
        </JostText> : <Title label="More artwork" />}
      </ViewPadding>

      {/** art */}
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          ...styles.scrollBox,
        }}
      >
        {relateProducts?.map((product, index) => (
          <TouchableOpacity
            key={product.id + `_${index}`}
            style={{
              ...styles.artItem,
              ...(index === 0 ? { marginLeft: 20, marginRight: 12 } : { marginRight: 12 })
            }}
            onPress={() => {
              navigation.push("product_detail", {
                productId: product.id
              })
            }}
          >
            <Image
              source={{ uri: product?.thumbnail?.url }}
              resizeMode='cover'
              style={styles.artItemImage}
            />
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  scrollBox: {
    marginTop: 24
  },
  artItem: {
    width: 100,
    height: 80,
    overflow: 'hidden'
  },
  artItemImage: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  pcTitle: {
    fontSize: 22,
    color: '#877A99'
  }
})

export default MoreArtwork