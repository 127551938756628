import React from 'react'
import { ScrollView, StyleSheet } from 'react-native'
import useArtistPage from '@/hooks/store/useArtistPage'
import ArtistAvatar from './Item'
import { useTabletOrDesktop } from '@/hooks/useMedia'
import { useNavigation } from '@react-navigation/native'

interface ArtistAvatarsProps {
  style?: Record<string, any>
}
function ArtistAvatars(props: ArtistAvatarsProps) {
  const navigation: any = useNavigation()
  const { artistAvatars } = useArtistPage()
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  if (!ready) return null
  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{
        ...styles.container,
        ...(props.style || {}),
        ...(isTabletOrDesktop ? {
          paddingTop: 0
        } : {})
      }}
    >
      {artistAvatars?.map((art, index) => (
        <ArtistAvatar
          data={art}
          key={art.id}
          style={index === 0 ? { marginLeft: 20, marginRight: 16 } : { marginRight: 16 }}
          onPress={() => navigation.push('artstory', {
            artId: art.id
          })}
        />
      ))}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20
  }
})

export default ArtistAvatars