import React from 'react'
import { Text, StyleSheet, TouchableOpacity, TouchableOpacityProps } from 'react-native'
import { fontFamilyBodoniModa as fontFamily, fontFamilyBodoniModa_Bold } from '../../utils/font';
import { Entypo } from '@expo/vector-icons';
import JostText from '../Text/JostText';

export interface ICategoryItem {
  label: string
  value: string
}

interface CategoryItemProps {
  data: ICategoryItem
  style?: Record<string, any>
  active?: string
  onPress?: TouchableOpacityProps['onPress']
}

function CategoryItem(props: CategoryItemProps) {
  const isActive = props.active === props.data.value
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={{
        ...styles.container,
        ...(props.style || {}),
        ...(isActive ? styles.active : {})
      }}
    >
      {isActive && <Entypo style={{ marginRight: 3 }} name="check" size={18} color="white" />}
      <JostText style={{
        ...styles.label,
        fontWeight: isActive ? '900' : 'normal',
        color: isActive ? 'white' : 'black'
      }}>{props.data.label}</JostText>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#79747E',
    height: '100%',
    paddingHorizontal: 10,
    alignItems: 'center',
    overflow: 'hidden'
  },
  active: {
    backgroundColor: 'black'
  },
  label: {

  }
})

export default CategoryItem