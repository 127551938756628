import React from "react"
import { View } from "react-native"

interface ViewPaddingProps {
  vsize?: number
  hsize?: number
  children: React.ReactNode
  style?: any
}
const ViewPadding = ({ vsize = 20, hsize, children, style }: ViewPaddingProps) => {
  return (
    <View
      style={{
        ...(style || {}),
        paddingHorizontal: vsize, paddingVertical: hsize || 0
      }}
    >
      {children}
    </View>
  )
}

export default ViewPadding