import { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Text, View } from "react-native";
import OperaButton from "@/components/Button";
import Space from "@/components/Space";
import JostText from "@/components/Text/JostText";

interface CheckoutFormProps {
  payLabel: string
  returnUrl: string
}
export default function CheckoutForm({ returnUrl, payLabel }: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isStripeLoaded, setIsStripeLoaded] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage((error as any).message || '');
    } else {
      setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  useEffect(() => {
    stripe && setIsStripeLoaded(true);
  }, [stripe]);

  return (
    <>
      <PaymentElement onReady={() => setIsReady(true)} options={paymentElementOptions as any} />
      {isStripeLoaded && isReady && <OperaButton
        onPress={handleSubmit}
        loading={isLoading}
        containerStyle={{ marginVertical: 10 }}
        disabled={isLoading || !stripe || !elements}
        title={payLabel}
      />}
      {/* Show any error or success messages */}
      {message && <JostText style={{ color: 'red' }}>{message}</JostText>}
      <Space size={30} />
    </>
  );
}
