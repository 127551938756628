
import { SafeAreaView } from "react-native-safe-area-context";
import { View, ScrollView, StyleSheet } from "react-native";
import { useTabletOrDesktop } from "@/hooks/useMedia";
import PageHeader from "@/components/PageHeader/indext";
import PageNavLayout from "@/components/PageNavLayout";
import Back from "@/components/Back";
import ArtInfoView from "./components/ArtInfoView";
import Space from "@/components/Space";
import StoryInfo from "./components/StoryInfo";
import CollectionView from "./components/Collection";
import { LAYOUT_MAX_WIDTH } from "@/constants";
import FlexLayout from "@/components/Flex";
import Title from "@/components/Title";
import useArtist from "@/saleor/api/useArtist";
import Loading from "@/components/Loading/Loading";
import { useProductsByArt } from "@/saleor/api/useProductsByArt";
import { useLayoutEffect } from "react";

const ArtStoryScreen: React.FC<any> = ({ navigation, route }) => {
  const { isTabletOrDesktop, ready } = useTabletOrDesktop()
  const artId = (route?.params as any).artId
  const { loading, data } = useArtist(artId)
  const { data: productsData, loading: loadingProducts } = useProductsByArt(artId)
  const artInfo = data?.artist ?? {}
  const products = (productsData?.products?.edges ?? []).map((o: any) => ({ ...o.node }))

  useLayoutEffect(() => {
    artInfo?.name && navigation.setOptions({
      title: `Artist: ${artInfo?.name}`
    })
  }, [artInfo])


  if (!ready) return null

  if (loading || loadingProducts) {
    return (
      <Loading />
    )
  }

  if (isTabletOrDesktop) {
    return (
      <View style={styles.container}>
        <ScrollView>
          <SafeAreaView style={{ maxWidth: LAYOUT_MAX_WIDTH, marginHorizontal: 'auto', width: '100%', marginTop: 30 }}>
            <FlexLayout key="a" direction="row" justify="start" items="start">
              <Back navigation={navigation} />
            </FlexLayout>
            <FlexLayout
              key="b"
              direction="row"
              justify="start"
              items="start"
            >
              <FlexLayout justify="start" items="stretch" style={{ maxWidth: 490 }}>
                <Title label="Artist" />
                <Space size={20} />
                <ArtInfoView artInfo={artInfo} />
                <Space size={50} />
                {artInfo.story.map((story: any) => (
                  <StoryInfo content={story.introduce} />
                ))}
              </FlexLayout>

              <View style={{ flex: 1 }} />

              <FlexLayout justify="start" items="stretch" style={{ maxWidth: 490 }}>
                <CollectionView products={products} />
              </FlexLayout>
            </FlexLayout>
          </SafeAreaView>
          <Space direction="h" size={50} />
        </ScrollView>
      </View>
    )
  }

  return (
    <ScrollView style={styles.container}>
      <SafeAreaView>
        <PageNavLayout>
          <Back navigation={navigation} />
        </PageNavLayout>

        {/** Header */}
        <PageHeader title="Artist" withProfile={false} />
        <Space size={30} />

        {/** artInfo */}
        <ArtInfoView artInfo={artInfo} />
        <Space size={50} />

        {/** story */}
        {artInfo.story.map((story: any) => (
          <StoryInfo content={story.introduce} />
        ))}
        <Space size={50} />

        {/** collection */}
        <CollectionView products={products} />
      </SafeAreaView>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 10
  },

})

export default ArtStoryScreen
