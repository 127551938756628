import ArtInfo from "@/components/ArtInfo"
import FlexLayout from "@/components/Flex"
import Space from "@/components/Space"
import JostText from "@/components/Text/JostText"
import { Art } from "@/types"
import { useState } from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"


const MORES_LEN = 200
interface ArtInfoProps {
  artInfo: Art
}

const ArtInfoView: React.FC<ArtInfoProps> = ({
  artInfo
}) => {
  const hasMore = artInfo.description?.length > MORES_LEN
  const [expanded, setExpanded] = useState(!hasMore);

  const toggleExpand = () => {
    setExpanded(!expanded);
  }

  return (
    <View style={styles.container}>
      <FlexLayout direction="row" justify="start" items="center">
        <Space direction="h" size={20} />
        <ArtInfo info={artInfo} avatarBoxStyle={styles.avatarBoxStyle} />
      </FlexLayout>
      <View style={styles.wordBox}>
        <JostText style={styles.word}>
          “ {expanded ? artInfo.description : artInfo.description?.substring(0, MORES_LEN)}”
        </JostText>
        {!expanded && hasMore && <TouchableOpacity onPress={toggleExpand}>
          <JostText style={styles.moreBtnText}>Learn More</JostText>
        </TouchableOpacity>}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F2F0E5',
    borderBottomRightRadius: 160,
    overflow: 'hidden',
    paddingVertical: 36,
  },
  avatarBoxStyle: {
    width: 110,
    height: 110,
    marginRight: 23,
  },
  wordBox: {
    marginTop: 50,
    marginBottom: 30,
    paddingHorizontal: 35
  },
  word: {
    fontSize: 26,
    fontWeight: '600'
  },
  moreBtnText: {
    fontSize: 24,
    textDecorationLine: 'underline'
  }
})

export default ArtInfoView