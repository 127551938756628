import Title from "@/components/Title"
import ViewPadding from "@/components/ViewPadding"
import useCheckoutToken from "@/hooks/store/useCheckoutToken"
import { ButtonGroup, ButtonProps } from "@rneui/themed"
import { useEffect, useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import OperaButton from "../../../../components/Button"
import ShippingMethodDisplay from "./ShippingMethodDisplay"
import { localeToEnum, notNullable } from "@/utils"
import { DEFAULT_LOCALE } from "@/constants"
import useCheckoutShippingMethodUpdateMutation from "@/saleor/api/useCheckoutShippingMethodUpdateMutation"
import JostText from "@/components/Text/JostText"

const ShippingMethodView: React.FC<ButtonProps> = () => {
  const { checkout } = useCheckoutToken()
  const [loading, setLoading] = useState(false);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(checkout?.shippingMethod);
  const [checkoutShippingMethodUpdate] = useCheckoutShippingMethodUpdateMutation();
  const [editing, setEditing] = useState(!checkout?.shippingMethod);

  useEffect(() => {
    setEditing(!checkout?.shippingMethod)
    setSelectedDeliveryMethod(checkout?.shippingMethod)
  }, [checkout?.shippingMethod])

  const availableShippingMethods = checkout?.shippingMethods?.filter(notNullable) || [];

  const selectedIndex = typeof selectedDeliveryMethod !== 'undefined' ? availableShippingMethods.findIndex(item => item.id === selectedDeliveryMethod?.id) : null

  const handleSelect = async (index: number) => {
    try {
      if (loading) true
      setLoading(true)
      const targetMethod = availableShippingMethods[index]
      const { data } = await checkoutShippingMethodUpdate({
        variables: {
          token: checkout?.token,
          shippingMethodId: targetMethod.id,
          locale: localeToEnum(DEFAULT_LOCALE),
        },
      });
      if (data?.checkoutShippingMethodUpdate?.errors.length) {
        console.error(data?.checkoutShippingMethodUpdate?.errors);
        return;
      }
      setSelectedDeliveryMethod(targetMethod);
      setEditing(false);
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  };

  return (
    <ViewPadding style={styles.container}>
      <Title size={16} label="Shipping Method" />
      {
        editing ? (
          <View>
            {availableShippingMethods.length ? <ButtonGroup
              buttons={availableShippingMethods.map(o => o.name)}
              containerStyle={{
                width: '100%',
                marginVertical: 20,
              }}
              selectedButtonStyle={{ backgroundColor: 'black' }}
              buttonStyle={styles.buttonStyle}
              selectedIndex={selectedIndex}
              onPress={handleSelect}
            /> : <JostText>Not shipping method provided</JostText>}
          </View>
        ) : (
          <>
            {!!checkout?.shippingMethod && (
              <ShippingMethodDisplay method={checkout?.shippingMethod} />
            )}
            <OperaButton style={styles.operaBtn} containerStyle={{ marginVertical: 10 }} onPress={() => setEditing(true)} title="Change" />
          </>
        )
      }
    </ViewPadding>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  buttonStyle: {
    overflow: 'hidden'
  },
  operaBtn: {
    marginBottom: 15
  }
})

export default ShippingMethodView