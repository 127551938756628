import { useQuery, gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query {
    categories(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default function useCategories() {
  return useQuery(GET_CATEGORIES);
}