import { useState } from 'react'
import Back from "@/components/Back";
import { withSafeArea } from "@/utils/withSafeArea";
import loginUserViaRequest from "@/saleor/api/loginUserViaRequest";
import { responsiveHeight } from "react-native-responsive-dimensions";
import { View, TextInput, Text, TouchableOpacity, StyleSheet, ImageBackground } from "react-native";
import OperaButton from '@/components/Button';
import useAuth from '@/hooks/store/useAuth';
import FlexLayout from '@/components/Flex';
import JostText from '@/components/Text/JostText';
import { usePasswordResetRequest } from './usePasswordResetRequest';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function validateEmail(email: string) {
  return emailRegex.test(email);
}

const EmailLoginScreen: React.FC<any> = ({ navigation }) => {
  const { updateToken, updateRefreshToken, updateCsrfToken } = useAuth()
  const [submiting, setSubmiting] = useState(false);
  const [email, onChangeEmail] = useState('');
  const [password, onChangePassword] = useState('');
  const [errMsg, onChangeErrMsg] = useState('');

  const [tokenCreate] = loginUserViaRequest();
  const { onPasswordResetRequest, passwordResetSent } = usePasswordResetRequest({
    email,
    shouldAbort: () => {
      return !validateEmail(email)
    },
    setErrorMsg: onChangeErrMsg
  })

  const handleSubmit = () => {
    if (!validateEmail(email)) {
      onChangeErrMsg('Invalid email address');
      return;
    }

    if (password.length < 6) {
      onChangeErrMsg('Password must be at least 6 characters');
      return;
    }
    setSubmiting(true)
    tokenCreate({
      variables: { email, password }
    }).then(res => {
      const successful = res.data.tokenCreate.errors.length === 0;
      if (successful) {
        const { token, refreshToken, csrfToken } = res.data.tokenCreate
        updateToken(token)
        updateCsrfToken(csrfToken)
        updateRefreshToken(refreshToken)
        navigation.navigate('home');
      } else {
        onChangeErrMsg(res.data.tokenCreate.errors[0].message);
      }
    }).catch(error => {
      onChangeErrMsg(error.message);
    })
      .finally(() => setSubmiting(false))
  };

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require("./login-detail-background.png")}
        style={styles.background}
      />

      <View style={styles.content}>
        <View style={styles.contentBg} />
        <View style={styles.buttonGroup}>
          <View>
            <JostText style={styles.title}>Global Art</JostText>
          </View>

          <JostText style={{ color: 'red' }}>{errMsg}</JostText>

          <FlexLayout direction="row" justify="start" items="start" style={styles.back}>
            <Back size={32} navigation={navigation} />
            <JostText style={styles.selectorMode}>LOG IN</JostText>
          </FlexLayout>

          <TextInput
            style={styles.textInput}
            value={email}
            placeholder="Email"
            placeholderTextColor="#79747E"
            onChangeText={text => {
              onChangeEmail(text)
            }}
          />

          <TextInput
            style={styles.textInput}
            value={password}
            secureTextEntry={true}
            placeholder="Password"
            placeholderTextColor="#79747E"
            onChangeText={text => {
              onChangePassword(text)
            }}
          />

          <TouchableOpacity
            onPress={onPasswordResetRequest}
          >
            <JostText
              style={{
                fontSize: 14,
                color: '#000000',
                marginBottom: 20,
                textDecorationLine: 'underline',
              }}
            >
              {passwordResetSent ? 'ReSent?' : 'Forgot Passwords?'}
            </JostText>
          </TouchableOpacity>
          <OperaButton title="LOG IN" containerStyle={{ marginVertical: 10 }} loading={submiting} onPress={handleSubmit} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0
  },
  contentBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 5,
    opacity: 0.9,
    backgroundColor: '#fff',
  },
  background: {
    height: responsiveHeight(100),
    opacity: 0.5
  },
  back: {
    marginLeft: -10,
  },
  title: {
    color: '#000',
    fontFamily: 'Inria Serif',
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 29.5,
    paddingBottom: 100,
  },
  selectorMode: {
    color: '#000',
    fontSize: 24,
    fontStyle: 'normal',
    top: 4
  },
  textInput: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    padding: 10,
  },
  buttonGroup: {
    margin: 'auto',
    width: '100%',
    maxWidth: 400,
    padding: '5%',
    position: 'relative',
    zIndex: 200
  },
})

export default withSafeArea(EmailLoginScreen)
