
import { useMutation, gql } from '@apollo/client';

export const GQL = gql`
  mutation CheckoutComplete($checkoutId: ID!) {
    checkoutComplete(id: $checkoutId) {
      order {
        id
        token
        errors {
          field
          message
          code
        }
      }
      errors {
        field
        message
        code
      }
    }
  }
`;

export default function useCheckoutCompleteMutation() {
  return useMutation(GQL)
}