import { useQuery, gql } from '@apollo/client';

export const QUERY = gql`
  query Orders($before: String, $after: String) {
    me {
      orders(first: 100, before: $before, after: $after) {
        edges {
          cursor
          node {
            id
            token
            created
            number
            status
            lines {
              id
              productName
              variantName
              productSku
              productVariantId
              isShippingRequired
              quantity
              thumbnail{
                url
              }
              totalPrice {
                gross {
                  amount
                  currency
                }
                tax {
                  currency
                  amount
                }
              }
            }
            total {
              currency
              gross {
                currency
                amount
              }
              net {
                currency
                amount
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
`;

export default function useOrderListQuery(options: Record<string,any>) {
  return useQuery(QUERY, options || {});
}