import '@expo/match-media'
import React, { useEffect } from "react";
import * as Linking from "expo-linking";
import { createStackNavigator } from '@react-navigation/stack';
import {
  NavigationContainer,
} from "@react-navigation/native";
import HomeStack from './HomeStack';
import LoginOptionsScreen from '@/screens/login_options'
import SignUpScreen from '@/screens/sign_up';
import EmailLoginScreen from '@/screens/email_login';
import ProfileScreen from '@/screens/profile';
import AddressEdit from '@/screens/profile/AddressEdit';
import PersonalEdit from '@/screens/profile/PersonalEdit';
import AccountConfirm from '@/screens/account_confirm'
import ProductDetailScreen from '@/screens/product_detail';
import Checkout from '@/screens/checkout';
import Payment from '@/screens/payment';
import Order from '@/screens/order';
import ContactUs from '@/screens/profile/ContactUs';
import OrderList from '@/screens/order_list';
import ArtStory from '@/screens/art_story';
import ResetPassword from '@/screens/profile/ResetPassword';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useCheckoutByTokenQuery from '@/saleor/api/useCheckoutByTokenQuery';
import useCheckoutToken from '@/hooks/store/useCheckoutToken';
import { CHECKOUT_TOKEN, SCREEN_MAPS } from '@/constants';

const Stack = createStackNavigator();

export default function Navigation() {
  const { checkoutToken, updateCheckoutToken, updateCheckout } = useCheckoutToken()
  const [getCheckoutBytoken, { data: checkoutResult }] = useCheckoutByTokenQuery(checkoutToken)
  const prefix = Linking.createURL("/");

  useEffect(() => {
    updateCheckout(checkoutResult?.checkout)
  }, [checkoutResult])

  useEffect(() => {
    checkoutToken && getCheckoutBytoken()
  }, [checkoutToken])

  useEffect(() => {
    async function getCheckoutTokenFromStorage() {
      const token = await AsyncStorage.getItem(CHECKOUT_TOKEN)
      token && updateCheckoutToken(token)
    }
    getCheckoutTokenFromStorage()
  }, [])

  return (
    <NavigationContainer linking={{
      prefixes: [prefix],
      config: {
        screens: {
          ...SCREEN_MAPS
        },
      },
    }}>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        <Stack.Screen name="home" component={HomeStack} />
        <Stack.Screen name={SCREEN_MAPS.login_options} options={{ title: 'Login Options' }} component={LoginOptionsScreen} />
        <Stack.Screen name={SCREEN_MAPS.sign_up} options={{ title: 'Sing Up' }} component={SignUpScreen} />
        <Stack.Screen name={SCREEN_MAPS.email_login} options={{ title: 'Login' }} component={EmailLoginScreen} />
        <Stack.Screen name={SCREEN_MAPS.profile} options={{ title: 'Profile' }} component={ProfileScreen} />
        <Stack.Screen name={SCREEN_MAPS.product_detail} options={{ title: 'Artist Detail' }} component={ProductDetailScreen} />
        <Stack.Screen name={SCREEN_MAPS.checkout} options={{ title: 'Checkout' }} component={Checkout} />
        <Stack.Screen name={SCREEN_MAPS.payment} options={{ title: 'Payment' }} component={Payment} />
        <Stack.Screen name={SCREEN_MAPS.order} options={{ title: 'Order Detail' }} component={Order} />
        <Stack.Screen name={SCREEN_MAPS.order_list} options={{ title: 'Order List' }} component={OrderList} />
        <Stack.Screen name={SCREEN_MAPS.account_confirm} options={{ title: 'Account Confirm' }} component={AccountConfirm} />
        <Stack.Screen name={SCREEN_MAPS.address_edit} options={{ title: 'Modify Address' }} component={AddressEdit} />
        <Stack.Screen name={SCREEN_MAPS.personal_edit} options={{ title: 'Modify Personal Info' }} component={PersonalEdit} />
        <Stack.Screen name={SCREEN_MAPS.contact_us} options={{ title: 'Contact Us' }} component={ContactUs} />
        <Stack.Screen name={SCREEN_MAPS.artstory} options={{ title: 'Artist Story' }} component={ArtStory} />
        <Stack.Screen name={SCREEN_MAPS.reset_password} options={{ title: 'Reset Password' }} component={ResetPassword} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}