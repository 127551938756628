import { useQuery, gql } from '@apollo/client';

export const QUERY = gql`
query OrderDetailsByToken($token: UUID!) {
  orderByToken(token: $token) {
      id
      status
      user {
        id
        email
      }
      userEmail
      created
      billingAddress {
        id
        firstName
        lastName
        streetAddress1
        city
        cityArea
        postalCode
        country {
          code
          country
        }
        phone
        countryArea
      }
      shippingAddress {
        id
        firstName
        lastName
        streetAddress1
        city
        cityArea
        postalCode
        country {
          code
          country
        }
        phone
        countryArea
      }
      shippingMethodName
      shippingMethods {
        id
        name
        description
      }
      number
      original
      origin
      isPaid
      paymentStatus
      paymentStatusDisplay
      total{
        currency
        gross {
          currency
          amount
        }
        tax {
          currency
          amount
        }
      }
      invoices{
        id
        number
        url
      }
      lines {
        id
        productName
        variantName
        productSku
        productVariantId
        isShippingRequired
        quantity
        thumbnail{
          url
        }
        totalPrice {
          gross {
            amount
            currency
          }
          tax {
            currency
            amount
          }
        }
      }
    }
  }
`;

export default function useOrderDetailQuery(token: string) {
  return useQuery(QUERY, {
    variables: { token },
    skip: !token,
  });
}